import { ShakeOutlined } from '@ant-design/icons'
import { fade } from '@remotion/transitions/fade'
import { slide } from '@remotion/transitions/slide'
import { wipe } from '@remotion/transitions/wipe'
import { useGlobalContext } from '../../../../context/globalContext'
import { transitionAnimationsDropDown } from '../../../../data/transitions'
import { TagSelect } from '@src/shared/components/filters/tagSelect'
import { InputNumber, Row } from 'antd'
import { useState } from 'react'

interface TransitionRowProps {
  pageID: string
}

export const TransitionRow = ({ pageID }: TransitionRowProps) => {
  const {
    TransitionAnimation: [, setTransitionAnimation],
  } = useGlobalContext()

  const [transitionDuration, setTransitionDuration] = useState<number>(5)

  const [tarnsitionValue, setTransitionValue] = useState<string>('Fade')

  const handleTransitionValueChange = (selectedValue: string) => {
    setTransitionValue(selectedValue)
    setTransitionAnimation([{ animation: selectedValue, duration: transitionDuration }])
  }

  const handleTransitionDurationChange = (value: number | null) => {
    if (typeof value === 'number' && value >= 1) {
      setTransitionDuration(value)
      setTransitionAnimation([{ animation: tarnsitionValue, duration: value }])
    }
  }

  return (
    <>
      <Row gutter={[0, 4]} style={{ flexDirection: 'column' }}>
        <h3
          style={{
            fontFamily: 'DM Sans',
            fontSize: '14px',
            fontWeight: 'normal',
            marginRight: '10px',
            letterSpacing: 'normal',
          }}
        >
          Transition between scenes:
        </h3>
        <div style={{ marginRight: '20px' }}>
          <TagSelect
            dropdownItems={transitionAnimationsDropDown}
            placeholder={'Wipe'}
            defaultValue={tarnsitionValue}
            onChange={(value) => {
              handleTransitionValueChange(value)
            }}
            style={{
              marginTop: '5px',
            }}
          />
        </div>
        <h3
          style={{
            fontFamily: 'DM Sans',
            fontSize: '14px',
            fontWeight: 'normal',
            marginRight: '15px',
          }}
        >
          Duration (ms)
        </h3>
        <InputNumber
          size='small'
          placeholder='Borderless'
          min={5}
          step={5}
          value={transitionDuration}
          onChange={handleTransitionDurationChange}
          style={{
            textAlign: 'center',
            backgroundColor: '#f0f0f0',
            color: '#ffffff',
            borderColor: '#b3b3b3',
          }}
        />
      </Row>
    </>
  )
}
