import { formatDuration } from '@src/shared/utils/convertSecs'
import { getMusicLength } from '@src/shared/utils/getMusicLenght'

export const categorisedMusicList: any = {
  Fashion: {
    30: {
      PAS: ['https://daqrukw5ofwla.cloudfront.net/public_element/81571/element.wav'],
      AIDA: ['https://daqrukw5ofwla.cloudfront.net/public_element/68630/element.wav'],
    },
    60: {
      PAS: [
        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/79970/element.wav', name: 'Deep Dream' },
        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/83740/element.wav', name: 'Runway Groove' },
        { name: 'Dont Blink', url: 'https://www.typeframes.com/audio/dont-blink.mp3' },
        {
          name: 'Future Bass',
          url: 'https://content.typeframes.com/audio/for-future-bass.mp3',
        },
        {
          name: 'Snowfall',
          url: 'https://content.typeframes.com/audio/snowfall.mp3',
        },

        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/76330/element.wav', name: 'Dark Hold' },
      ],
    },
  },
  'E-commerce': {
    30: {
      PAS: ['https://daqrukw5ofwla.cloudfront.net/public_element/25457/element.wav'],
      FAB: ['https://daqrukw5ofwla.cloudfront.net/public_element/68630/element.wav'],
    },
    60: {
      PAS: [
        {
          name: 'Motion',
          url: 'https://www.typeframes.com/audio/motion.mp3',
        },
        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/83740/element.wav', name: 'Runway Groove' },
        {
          name: 'Electronic',
          url: 'https://content.typeframes.com/audio/beauteous-upbeat-electronic.mp3',
        },
        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/92637/element.wav', name: 'Neon Lights' },
        {
          name: 'Paris Else',
          url: 'https://content.typeframes.com/audio/paris-else.mp3',
        },
        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/62195/element.wav', name: 'Fashion dream' },
      ],
    },
  },
  'Travel & Tourism': {
    30: { PAS: ['https://daqrukw5ofwla.cloudfront.net/public_element/38308/element.wav'] },
    60: {
      PAS: [
        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/79970/element.wav', name: 'Deep Dream' },
        {
          name: 'Futuristic Beat',
          url: 'https://content.typeframes.com/audio/futuristic-beat.mp3',
        },
        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/62195/element.wav', name: 'Fashion dream' },

        {
          name: 'The Best Jazz Club',
          url: 'https://content.typeframes.com/audio/the-best-jazz-club-in-new-orleans.mp3',
        },
        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/76330/element.wav', name: 'Dark Hold' },
      ],
    },
  },
  'Real Estate': {
    30: { PAS: ['https://daqrukw5ofwla.cloudfront.net/public_element/81571/element.wav'] },
    60: {
      PAS: [
        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/32580/element.wav', name: 'Binary Beats' },

        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/84257/element.wav', name: 'Travel Light' },
        {
          name: 'I am Free',
          url: 'https://content.typeframes.com/audio/i-m-free.mp3',
        },
        {
          name: 'Science Documentary',
          url: 'https://content.typeframes.com/audio/science-documentary.mp3',
        },
        {
          name: 'Once In Paris',
          url: 'https://content.typeframes.com/audio/once-in-paris.mp3',
        },

        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/62195/element.wav', name: 'Fashion dream' },
      ],
    },
  },
  'Consumer Goods': {
    30: { PAS: ['https://daqrukw5ofwla.cloudfront.net/public_element/68630/element.wav'] },
    60: {
      PAS: [
        {
          name: 'Drops',
          url: 'https://www.typeframes.com/audio/drops.mp3',
        },
        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/76330/element.wav', name: 'Dark Hold' },

        {
          name: 'Nas',
          url: 'https://content.typeframes.com/audio/nas.mp3',
        },
        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/83740/element.wav', name: 'Runway Groove' },
        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/19311/element.wav', name: 'Lets Rock' },
      ],
    },
  },
  'Food & Restaurants': {
    30: {
      PAS: ['https://daqrukw5ofwla.cloudfront.net/public_element/23602/element.wav'],
      USP: ['https://daqrukw5ofwla.cloudfront.net/public_element/68630/element.wav'],
    },
    60: {
      PAS: [
        {
          name: 'Paris Else',
          url: 'https://content.typeframes.com/audio/paris-else.mp3',
        },
        {
          name: 'Claps',
          url: 'https://www.typeframes.com/audio/claps.mp3',
        },
        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/84257/element.wav', name: 'Travel Light' },

        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/62195/element.wav', name: 'Fashion dream' },
        {
          name: 'Constellations',
          url: 'https://content.typeframes.com/audio/constellations.mp3',
        },

        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/83740/element.wav', name: 'Runway Groove' },
      ],
    },
  },
  'Professional Services': {
    30: { PAS: ['https://daqrukw5ofwla.cloudfront.net/public_element/22666/element.wav'] },
    60: {
      PAS: [
        {
          name: 'Stomp',
          url: 'https://www.typeframes.com/audio/stomp.mp3',
        },
        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/19311/element.wav', name: 'Lets Rock' },

        {
          name: 'Abstract Future',
          url: 'https://content.typeframes.com/audio/dont-stop-me-abstract-future-bass.mp3',
        },

        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/62195/element.wav', name: 'Fashion dream' },
        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/84257/element.wav', name: 'Travel Light' },
      ],
    },
  },
  'Software & IT Industry': {
    30: { PAS: ['https://daqrukw5ofwla.cloudfront.net/public_element/49511/element.wav'] },
    60: {
      PAS: [
        {
          name: 'Snaps',
          url: 'https://www.typeframes.com/audio/snaps.mp3',
        },
        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/32580/element.wav', name: 'Binary Beats' },
        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/19311/element.wav', name: 'Lets Rock' },

        {
          name: 'Titanium',
          url: 'https://content.typeframes.com/audio/titanium.mp3',
        },
        {
          name: 'Blade Runner',
          url: 'https://content.typeframes.com/audio/bladerunner-2049.mp3',
        },
        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/62195/element.wav', name: 'Fashion dream' },
      ],
    },
  },
  'Sports': {
    30: { PAS: ['https://daqrukw5ofwla.cloudfront.net/public_element/49511/element.wav'] },
    60: {
      PAS: [
        {
          name: 'Snaps',
          url: 'https://www.typeframes.com/audio/snaps.mp3',
        },
        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/32580/element.wav', name: 'Binary Beats' },
        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/19311/element.wav', name: 'Lets Rock' },

        {
          name: 'Titanium',
          url: 'https://content.typeframes.com/audio/titanium.mp3',
        },
        {
          name: 'Blade Runner',
          url: 'https://content.typeframes.com/audio/bladerunner-2049.mp3',
        },
        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/62195/element.wav', name: 'Fashion dream' },
      ],
    },
  },
  Others: {
    30: { PAS: ['https://daqrukw5ofwla.cloudfront.net/public_element/49511/element.wav'] },
    60: {
      PAS: [
        {
          name: 'Hotline',
          url: 'https://content.typeframes.com/audio/hotline.mp3',
        },
        {
          name: 'Another Love',
          previewUrl: 'https://content.typeframes.com/audio/another-love.mp3',
        },
        { url: 'https://daqrukw5ofwla.cloudfront.net/public_element/81571/element.wav', name: 'Other' },
      ],
    },
  },
}

export const categorisedVoiceOvers: any = {
  Fashion: {
    Simple: {
      name: 'Rachel',
      voiceID: '21m00Tcm4TlvDq8ikWAM',
    },
    Burst: {
      name: 'Rachel',
      voiceID: '21m00Tcm4TlvDq8ikWAM',
    },
  },
  'E-commerce': {
    Simple: {
      name: 'Glinda',
      voiceID: 'z9fAnlkpzviPz146aGWa',
    },
    Burst: {
      name: 'Glinda',
      voiceID: 'z9fAnlkpzviPz146aGWa',
    },
  },
  'Travel & Tourism': {
    Simple: {
      name: 'Liam',
      voiceID: 'TX3LPaxmHKxFdv7VOQHJ',
    },
    Burst: {
      name: 'Liam',
      voiceID: 'TX3LPaxmHKxFdv7VOQHJ',
    },
  },
  'Real Estate': {
    Simple: {
      name: 'Domi',
      voiceID: 'AZnzlk1XvdvUeBnXmlld',
    },
    Burst: {
      name: 'Domi',
      voiceID: 'AZnzlk1XvdvUeBnXmlld',
    },
  },
  'Consumer Goods': {
    Simple: {
      name: 'Sarah',
      voiceID: 'EXAVITQu4vr4xnSDxMaL',
    },
    Burst: {
      name: 'Sarah',
      voiceID: 'EXAVITQu4vr4xnSDxMaL',
    },
  },
  'Food & Restaurants': {
    Simple: {
      name: 'Giovanni',
      voiceID: 'zcAOhNBS3c14rBihAFp1',
    },
    Burst: {
      name: 'Giovanni',
      voiceID: 'zcAOhNBS3c14rBihAFp1',
    },
  },
  'Professional Services': {
    Simple: {
      name: 'Emily',
      voiceID: 'LcfcDJNUP1GQjkzn1xUU',
    },
    Burst: {
      name: 'Emily',
      voiceID: 'LcfcDJNUP1GQjkzn1xUU',
    },
  },
  'Software & IT Industry': {
    Simple: {
      name: 'Paul',
      voiceID: '5Q0t7uMcjvnagumLfvZi',
    },
    Burst: {
      name: 'Paul',
      voiceID: '5Q0t7uMcjvnagumLfvZi',
    },
  },
  Others: {
    Simple: {
      name: 'Rachel',
      voiceID: '21m00Tcm4TlvDq8ikWAM',
    },
    Burst: {
      name: 'Rachel',
      voiceID: '21m00Tcm4TlvDq8ikWAM',
    },
  },
}

export const whisperAiVoiceList = [
  {
    label: 'Alloy',
    value: 'alloy',
    previewUrl: 'https://cdn.openai.com/API/docs/audio/alloy.wav',
  },
  {
    label: 'Echo',
    value: 'echo',
    previewUrl: 'https://cdn.openai.com/API/docs/audio/echo.wav',
  },
  {
    label: 'Fable',
    value: 'fable',
    previewUrl: 'https://cdn.openai.com/API/docs/audio/fable.wav',
  },
  {
    label: 'Onyx',
    value: 'onyx',
    previewUrl: 'https://cdn.openai.com/API/docs/audio/onyx.wav',
  },
  {
    label: 'Nova',
    value: 'nova',
    previewUrl: 'https://cdn.openai.com/API/docs/audio/nova.wav',
  },
  {
    label: 'Shimmer',
    value: 'shimmer',
    previewUrl: 'https://cdn.openai.com/API/docs/audio/shimmer.wav',
  },
]

// Function to add the duration property to each music object
export async function addDurationToMusicObjects() {
  for (const category in categorisedMusicList) {
    for (const duration in categorisedMusicList[category]) {
      for (let i = 0; i < categorisedMusicList[category][duration].PAS.length; i++) {
        const music = categorisedMusicList[category][duration].PAS[i]
        try {
          if (typeof music === 'object') {
            const musicUrl = music.url as string
            const musicDuration: any = await getMusicLength(musicUrl)
            categorisedMusicList[category][duration].PAS[i].duration = formatDuration(Math.floor(musicDuration))
          } else if (typeof music === 'string') {
            const musicDuration: any = await getMusicLength(music)
            categorisedMusicList[category][duration].PAS[i] = {
              url: music,
              duration: formatDuration(Math.floor(musicDuration)),
            }
          }
        } catch (error) {
          console.error('Error loading music:', error)
        }
      }
    }
  }
}
