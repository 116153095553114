/* eslint-disable @typescript-eslint/no-explicit-any */
import { fade } from '@remotion/transitions/fade'
import { animations } from '@src/data/constants'
import React, { useState, createContext } from 'react'

export type MenuKey = string

type TransitionAnimationType = {
  animation: any
  duration: number
}

export type GlobalContent = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  UserDetail: [any, (val: any) => void]
  UpdatedTemplate: [any, (val: any) => void]
  UpdatedHeadings: [any, (val: any) => void]
  AiVoiceForVideo: [any, (val: any) => void]
  TransitionAnimation: [TransitionAnimationType[], (val: TransitionAnimationType[]) => void]
  BackgroundMusic: [any, (val: any) => void]
  ScriptDetails: [any, (val: any) => void]
  RegeneratedScriptDetails: [any, (val: any) => void]
  IsLoading: [boolean, (val: boolean) => void]
  VideoStyle: [string, (val: string) => void]
}

export const _GlobalContext = createContext<GlobalContent>({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  UserDetail: [{}, (val: any) => val],
  UpdatedTemplate: [{}, (val: any) => val],
  UpdatedHeadings: [{ heading: '', subHeading: '', thirdHeading: '' }, (val: any) => val],
  AiVoiceForVideo: [{}, (val: any) => val],
  TransitionAnimation: [[], (val: any) => val],
  BackgroundMusic: [{ url: '' }, (val: any) => val],
  ScriptDetails: [{}, (val: any) => val],
  RegeneratedScriptDetails: [{}, (val: any) => val],
  IsLoading: [true, (val: boolean) => val],
  VideoStyle: ['', (val: string) => val],
})

export const useGlobalContext = () => {
  return React.useContext(_GlobalContext)
}

interface StateContextProps {
  children: React.ReactNode
}

const GlobalContext = (props: StateContextProps) => {
  const { children } = props
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [userDetail, setUserDetail] = useState<any>()
  const [updatedTemplate, setUpdatedTemplate] = useState<any>()
  const [updatedHeadings, setUpdatedHeadings] = useState<any>({ heading: '', subHeading: '', thirdHeading: '' })
  const [aiVoiceForVideo, setAiVoiceForVideo] = useState<any[]>([])
  const [transitionAnimation, setTransitionAnimation] = useState<TransitionAnimationType[]>([
    { animation: 'fade', duration: 30 },
  ])
  const [backgroundMusic, setBackgroundMusic] = useState<any>({
    url: 'https://www.typeframes.com/audio/dont-blink.mp3',
    duration: 52.009796,
  })
  const [scriptDetails, setScriptDetails] = useState<any>()
  const [regeneratedScriptDetails, setRegeneratedScriptDetails] = useState<any>()
  const [isLoading, setIsLoading] = useState(true)
  const [videoStyle, setVideoStyle] = useState<string>('')

  const state: GlobalContent = {
    UserDetail: [userDetail, setUserDetail],
    UpdatedTemplate: [updatedTemplate, setUpdatedTemplate],
    UpdatedHeadings: [updatedHeadings, setUpdatedHeadings],
    AiVoiceForVideo: [aiVoiceForVideo, setAiVoiceForVideo],
    TransitionAnimation: [transitionAnimation, setTransitionAnimation],
    BackgroundMusic: [backgroundMusic, setBackgroundMusic],
    ScriptDetails: [scriptDetails, setScriptDetails],
    RegeneratedScriptDetails: [regeneratedScriptDetails, setRegeneratedScriptDetails],
    IsLoading: [isLoading, setIsLoading],
    VideoStyle: [videoStyle, setVideoStyle],
  }

  return <_GlobalContext.Provider value={state}>{children}</_GlobalContext.Provider>
}

export default GlobalContext
