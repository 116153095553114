/* eslint-disable react/prop-types */
/* eslint-disable no-duplicate-imports */
import { ExclamationCircleFilled } from '@ant-design/icons'
import { getAudioDurationInSeconds } from '@remotion/media-utils'
import { IS_FREE_PLATFORM, NODE_BACKEND_URL } from '@src/config'
import { getDomainID, getToken } from '@src/config/config'
import { useGlobalContext } from '@src/context/globalContext'
import type { GetRef, InputRef } from 'antd'
import { Form, Input, Modal, Table, message } from 'antd'
import axios from 'axios'
import React, { useContext, useEffect, useRef, useState } from 'react'

type FormInstance<T> = GetRef<typeof Form<T>>

const EditableContext = React.createContext<FormInstance<any> | null>(null)

interface Item {
  key: number
  adBlockType: string
  onScreenVisual: string
  voiceOver: string
  textOverlay: string
}

interface EditableRowProps {
  index: number
}

const EditableRow = React.memo(({ index, ...props }: EditableRowProps) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} data-row-index={index} />
      </EditableContext.Provider>
    </Form>
  )
})

EditableRow.displayName = 'EditableRow'

interface EditableCellProps {
  title: React.ReactNode
  editable: boolean
  children: React.ReactNode
  dataIndex: keyof Item
  record: Item
  handleSave: (record: Item, index: number) => void
  handleTextOverlayChange: (newText: string, index: number) => void
  handleVoiceOverChange: (newVoiceOver: string, index: number) => void
  handleOnScreenVisualChange: (newVisualOverlay: string, index: number) => void
  index: number
}

const EditableCell = React.memo(
  ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    handleTextOverlayChange,
    handleVoiceOverChange,
    handleOnScreenVisualChange,
    index,
    ...restProps
  }: EditableCellProps) => {
    const [editing, setEditing] = useState(false)
    const inputRef = useRef<InputRef>(null)
    const form = useContext(EditableContext)!

    useEffect(() => {
      if (editing) {
        inputRef.current?.focus()
      }
    }, [editing])

    const toggleEdit = () => {
      setEditing(!editing)
      form.setFieldsValue({ [dataIndex]: record[dataIndex] })
    }

    const customSave = async (dataIndex: string) => {
      try {
        const values = await form.validateFields()

        toggleEdit()

        switch (dataIndex) {
          case 'onScreenVisual':
            if (record[dataIndex] === values[dataIndex]) {
              message.error('No changes detected. Please modify the value before saving.')
              form.setFieldsValue({ [dataIndex]: record[dataIndex] })
              toggleEdit()
              return
            } else {
              Modal.confirm({
                title: 'Are you sure you want to edit this?',
                content: `You are about to change the on-screen visual from "${record[dataIndex]}" to "${values[dataIndex]}". Are you sure you want to proceed?`,
                icon: <ExclamationCircleFilled />,
                onOk() {
                  handleOnScreenVisualChange(values.onScreenVisual, record.key)
                  handleSave({ ...record, ...values }, index)
                },
                onCancel() {
                  form.setFieldsValue({ [dataIndex]: record[dataIndex] })
                  toggleEdit()
                },
              })
            }
            break
          case 'voiceOver':
            if (record[dataIndex] === values[dataIndex]) {
              message.error('No changes detected. Please modify the value before saving.')
              form.setFieldsValue({ [dataIndex]: record[dataIndex] })
              toggleEdit()
              return
            } else {
              handleVoiceOverChange(values.voiceOver, record.key)
              handleSave({ ...record, ...values }, index)
            }

            break
          case 'textOverlay':
            if (record[dataIndex] === values[dataIndex]) {
              message.error('No changes detected. Please modify the value before saving.')
              form.setFieldsValue({ [dataIndex]: record[dataIndex] })
              toggleEdit()
              return
            } else {
              handleTextOverlayChange(values.textOverlay, record.key)
              handleSave({ ...record, ...values }, index)
            }

            break
          default:
            console.log('Unknown field', values)
        }

        toggleEdit()
        // console.log(record, values)
      } catch (errInfo) {
        console.log('Save failed:', errInfo)
      }
    }

    let childNode = children
    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[{ required: true, message: `${title} is required.` }]}
        >
          <Input.TextArea
            ref={inputRef}
            autoSize={{ minRows: 1, maxRows: 6 }}
            onPressEnter={() => customSave(dataIndex)}
          />
        </Form.Item>
      ) : (
        <div className='editable-cell-value-wrap' style={{ paddingRight: 24 }} onClick={toggleEdit}>
          {children}
        </div>
      )
    }

    return <td {...restProps}>{childNode}</td>
  }
)

EditableCell.displayName = 'EditableCell'

type EditableTableProps = Parameters<typeof Table>[0]

interface DataType {
  key: number
  adBlockType: string
  onScreenVisual: string
  voiceOver: string
  textOverlay: string
  index: number
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>

interface ScriptContainerProps {
  store: any
  setUpdatedScriptDetails: any
  setRegeneratedScriptDetails: any
  setConfirmLoading: any
}

export const ScriptContainer: React.FC<ScriptContainerProps> = ({
  store,
  setUpdatedScriptDetails,
  setRegeneratedScriptDetails,
  setConfirmLoading,
}) => {
  const {
    ScriptDetails: [scriptDetails],
    VideoStyle: [videoStyle],
  } = useGlobalContext()

  const initialData: DataType[] = scriptDetails.data.map(
    (script: { adBlockType: any; onScreenVisual: any; voiceOver: any; textOverlay: any }, index: number) => ({
      key: index,
      adBlockType: script.adBlockType,
      onScreenVisual: script.onScreenVisual,
      voiceOver: script.voiceOver,
      textOverlay: script.textOverlay,
      index: index,
    })
  )

  const [isEditable, setIsEditable] = useState<boolean>(videoStyle === 'Simple' ? true : false)

  const [dataSource, setDataSource] = useState<DataType[]>(initialData)

  const handleDelete = (key: React.Key) => {
    const newData = dataSource.filter((item) => item.key !== key)
    setDataSource(newData)
  }

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean
    dataIndex: string
  })[] = [
    {
      title: 'AD BLOCK TYPE',
      dataIndex: 'adBlockType',
      width: '30%',
      fixed: 'left',
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'ON-SCREEN VISUAL',
      dataIndex: 'onScreenVisual',
      width: '30%',
      fixed: 'left',
      editable: isEditable,
    },
    {
      title: 'VOICE-OVER',
      dataIndex: 'voiceOver',
      width: '30%',
      fixed: 'left',
      editable: isEditable,
    },
  ]

  if (IS_FREE_PLATFORM === 'false') {
    defaultColumns.push({
      title: 'TEXT-OVERLAY',
      dataIndex: 'textOverlay',
      width: '30%',
      fixed: 'left',
      editable: isEditable,
    })
  }

  const handleOnScreenVisualChange = async (newVisualOverlay: string, index: number) => {
    const postBody = {
      visualOverlay: newVisualOverlay,
      previousScript: scriptDetails.data[index],
    }

    const localEndPoint = axios.create({
      baseURL: `${NODE_BACKEND_URL}`,
    })

    try {
      setConfirmLoading(true)
      const response = await localEndPoint.post('/api/v1/openAi/searchQueries', postBody)
      if (response.data) {
        setRegeneratedScriptDetails((prevDetails: any) => {
          const updatedDetails = [...prevDetails]

          updatedDetails[index] = {
            ...updatedDetails[index],
            onScreenVisual: newVisualOverlay,
            searchQueries: response.data.data.searchQueries,
            isUpdated: {
              ...updatedDetails[index].isUpdated,
              isScreenVisualUpdated: true,
            },
          }

          return updatedDetails
        })

        setConfirmLoading(false)
      }
    } catch (error) {
      console.error('Failed to update on-screen visual:', error)
    }
  }

  function handleTextOverlayChange(newTextOverlay: string, index: number) {
    setRegeneratedScriptDetails((prevDetails: any) => {
      const updatedDetails = [...prevDetails]

      updatedDetails[index] = {
        ...updatedDetails[index],
        textOverlay: newTextOverlay,
        isUpdated: {
          ...updatedDetails[index].isUpdated,
          isTextOverlayUpdated: true,
        },
      }

      return updatedDetails
    })
  }

  const handleVoiceOverChange = async (newVoiceOver: string, index: number) => {
    setRegeneratedScriptDetails((prevDetails: any) => {
      const updatedDetails = [...prevDetails]

      updatedDetails[index] = {
        ...updatedDetails[index],
        voiceOver: newVoiceOver,
        isUpdated: {
          ...updatedDetails[index].isUpdated,
          isVoiceOverUpdated: true,
        },
      }

      return updatedDetails
    })
  }

  const handleSave = (row: DataType) => {
    const newData = [...dataSource]
    const index = newData.findIndex((item) => row.key === item.key)
    const item = newData[index]

    newData.splice(index, 1, {
      ...item,
      ...row,
    })
    setDataSource(newData)
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  }

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        handleTextOverlayChange,
        handleVoiceOverChange,
        handleOnScreenVisualChange,
        index: col.rowSpan,
      }),
    }
  })

  return (
    <div>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        pagination={false}
        scroll={{ x: 1000, y: 500 }}
        columns={columns as ColumnTypes}
      />
    </div>
  )
}
