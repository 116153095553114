import { DownCircleOutlined, PauseCircleOutlined, PlayCircleOutlined, UpCircleOutlined } from '@ant-design/icons'
import { getAudioDurationInSeconds } from '@remotion/media-utils'
import { useGetVoices } from '@src/api/elevenLabs/getVoices'
import { categorisedMusicList } from '@src/data/music'
import { Button, InputNumberProps, Row, Select, Slider, SliderSingleProps } from 'antd'
import React, { useRef, useState } from 'react'
import { useGlobalContext } from '../../context/globalContext'
import { AiVoiceRow } from '../video/customVideoTimeline/cardRows/aiVoiceRow'
import './audioPanel.less'
import { generateUniqueId } from '@src/shared/utils/core'
import { aiVoicesResponse } from '@src/data/aiVoiceResponse'

const { Option } = Select

interface AudioSidebarProps {
  store: any
}

interface MusicDetails {
  30: { PAS?: string[] }
}

export const AudioSidebar = ({ store }: AudioSidebarProps) => {
  const {
    BackgroundMusic: [backgroundMusic, setBackgroundMusic],
    UserDetail: [userDetails, setUserDetails],
    ScriptDetails: [scriptDetails, setScriptDetails],
  } = useGlobalContext()

  const [selectedIndustryName, setSelectedIndustryName] = useState(`${scriptDetails?.backgroundMusic?.industry}`)
  const [selectedMusicUrl, setSelectedMusicUrl] = useState(`${scriptDetails?.backgroundMusic?.url}`)
  const [selectedMusicName, setSelectedMusicName] = useState(`${scriptDetails?.backgroundMusic?.name}`)
  const [playingUrl] = useState('')
  const [showVoiceOver, setShowVoiceOver] = useState(false)
  const [backgroundVolume, setBackgroundVolume] = useState(
    (scriptDetails?.backgroundMusic?.backgroundVolume ?? 0.5) * 100
  )
  const [aiVoiceVolume, setAiVoiceVolume] = useState((scriptDetails?.aiVoiceVolume ?? 1) * 100)

  const formatter: NonNullable<SliderSingleProps['tooltip']>['formatter'] = (value: any) => `${value}%`

  // Ref to the audio player
  // const audioRef = React.createRef<HTMLAudioElement>()
  const audioRef = useRef<HTMLAudioElement>(null)

  const [currentPlayingTrack, setCurrentPlayingTrack] = useState<string | null>(null)

  const handlePlayAudio = (previewUrl: string) => {
    if (audioRef.current) {
      audioRef.current.src = previewUrl
      audioRef.current.play().catch((e) => console.error('Playback was interrupted:', e))
      setCurrentPlayingTrack(previewUrl)
    }
  }

  // Pause audio handler
  const handlePauseAudio = () => {
    if (audioRef.current && !audioRef.current.paused) {
      audioRef.current.pause()
      setCurrentPlayingTrack(null)
    }
  }

  const togglePlayPause = (value: string) => {
    const [industry, musicUrl, name] = value.split('@@@')

    if (currentPlayingTrack === musicUrl) {
      handlePauseAudio()
    } else {
      handlePlayAudio(musicUrl)
    }
  }

  const onVoiceOverVolumeChange = async (newValue: number) => {
    setAiVoiceVolume(newValue)
    await setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript }

      return { ...updatedScript, aiVoiceVolume: newValue / 100 }
    })
  }

  const onBackgroundVolumeChange = async (newValue: number) => {
    setBackgroundVolume(newValue as number)

    await setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript }

      return { ...updatedScript, backgroundMusic: { ...backgroundMusic, backgroundVolume: backgroundVolume / 100 } }
    })
  }

  const handleBackgroundMusicChange = async (value: string) => {
    const [industryName, musicUrl, name] = value.split('@@@')

    if (currentPlayingTrack === musicUrl) {
      handlePauseAudio()
    }

    setSelectedIndustryName(industryName)
    setSelectedMusicUrl(musicUrl)
    setSelectedMusicName(name)

    const backgroundAudioDuration = await getAudioDurationInSeconds(musicUrl)
    // setBackgroundMusic({ url: musicUrl, duration: backgroundAudioDuration, industry: industryName, name })

    const backgroundScript = {
      url: musicUrl,
      duration: backgroundAudioDuration,
      industry: industryName,
      name: name,
      backgroundVolume: backgroundVolume / 100,
    }

    // const backgroundAudioDuration = await getAudioDurationInSeconds(musicUrl)
    setBackgroundMusic(backgroundScript)

    await setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript }

      return { ...updatedScript, backgroundMusic: backgroundScript }
    })
  }

  // api calls
  const { data: aiVoice, isLoading, isSuccess } = useGetVoices()

  const handleShowVoiceOver = () => {
    setShowVoiceOver(!showVoiceOver)
  }

  // const { uniqueURLs, repeatedURLs } = segregatePASURLs(categorisedMusicList)

  const categories = Object.keys(categorisedMusicList)
  const options: any = {}

  categories.map((category) => {
    const musicList = categorisedMusicList[category][60]['PAS']
    musicList.map((musicUrl: any) => {
      const musicKey = `${category}@@@${musicUrl.url}@@@${musicUrl.name}`
      const option = {
        label: (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {musicUrl.name}
            <Button
              type='text'
              icon={currentPlayingTrack === musicUrl?.url ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
              onClick={(e) => {
                e.stopPropagation()
                togglePlayPause(musicKey)
              }}
            />
          </div>
        ),
        value: musicKey,
      }
      if (options[category]) {
        options[category].push(option)
      } else {
        options[category] = [option]
      }
    })
  })
  const optionValues = Object.keys(options).map((option: any) => {
    return { label: option, options: options[option] }
  })

  return (
    <>
      <Row gutter={[0, 4]} style={{ flexDirection: 'column' }}>
        <h3
          style={{
            color: 'black',
            fontSize: '14px',
            fontWeight: 500,
            fontFamily: 'DM Sans',
            letterSpacing: 'normal',
            marginBottom: '10px',
          }}
        >
          Background Music
        </h3>
        <Select
          style={{ minWidth: '200px', width: '100%' }}
          placeholder='Select Background Music'
          optionFilterProp='children'
          onChange={handleBackgroundMusicChange}
          value={selectedMusicName}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <audio ref={audioRef} src={playingUrl} style={{ display: 'none' }} />
            </div>
          )}
          options={optionValues}
        />
        <p
          style={{
            color: 'black',
            fontSize: '14px',
            fontWeight: 500,
            fontFamily: 'DM Sans',
            letterSpacing: 'normal',
            marginTop: '10px',
          }}
        >
          Background Music Volume
        </p>
        <Slider
          style={{
            width: '80%',
          }}
          min={1}
          max={100}
          onChange={onBackgroundVolumeChange}
          tooltip={{ formatter }}
          defaultValue={backgroundVolume}
          value={typeof backgroundVolume === 'number' ? backgroundVolume : 0}
        />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <h3
            style={{
              color: 'black',
              fontSize: '14px',
              fontWeight: 500,
              fontFamily: 'DM Sans',
              letterSpacing: 'normal',
              marginBottom: '5px',
            }}
          >
            Voice Overs
          </h3>
          {!showVoiceOver ? (
            <DownCircleOutlined
              style={{ fontSize: '16px', marginLeft: '5px', marginTop: '5px' }}
              onClick={handleShowVoiceOver}
            />
          ) : (
            <UpCircleOutlined
              style={{ fontSize: '16px', marginLeft: '5px', marginTop: '5px' }}
              onClick={handleShowVoiceOver}
            />
          )}
        </div>
        <div className={'voice-overs-wrapper'}>
          <div className={`voice-overs ${showVoiceOver ? 'visible' : ''}`}>
            <p
              style={{
                color: 'black',
                fontSize: '13px',
                fontWeight: 500,
                fontFamily: 'DM Sans',
                letterSpacing: 'normal',
                marginTop: '10px',
              }}
            >
              Voice Over Volume
            </p>
            <Slider
              style={{
                width: '80%',
              }}
              min={1}
              max={100}
              onChange={onVoiceOverVolumeChange}
              tooltip={{ formatter }}
              defaultValue={aiVoiceVolume}
              value={typeof aiVoiceVolume === 'number' ? aiVoiceVolume : 0}
            />
            {scriptDetails.data.map((page: any, index: any) => {
              return (
                <div key={index}>
                  <h3 style={{ fontSize: '12px', fontWeight: 'normal' }}>Scene {index + 1}</h3>
                  {isSuccess && aiVoice?.voices?.length > 0 ? (
                    <AiVoiceRow index={index} aiVoice={aiVoice} store={store} voiceOverVolume={aiVoiceVolume} />
                  ) : (
                    <AiVoiceRow
                      index={index}
                      aiVoice={aiVoicesResponse}
                      store={store}
                      voiceOverVolume={aiVoiceVolume}
                    />
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </Row>
    </>
  )
}
