/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/no-children-prop */
import { VideoCameraOutlined } from '@ant-design/icons'
import { Select } from 'antd'
import { observer } from 'mobx-react-lite'
import { SectionTab } from 'polotno/side-panel'
import { useEffect, useState } from 'react'
import './videoTimeline.less'
import { VideoTimelineCard } from './videoTimelineCard'
import { useGlobalContext } from '@src/context/globalContext'
const { Option } = Select

interface Child {
  id: string
  type: string
  text?: string
}

interface PageItem {
  id: string
  type: string
  name: string
  duration: number
  children: Child[]
}

interface JsonData {
  width: number
  height: number
  fonts: any[]
  pages: PageItem[]
}

const VideoTimelinePanel = observer(({ store }: any) => {
  const [pages, setPages] = useState<PageItem[] | undefined>(undefined)

  const {
    UserDetail: [userDetails],
    IsLoading: [isLoading],
  } = useGlobalContext()

  let jsonData: JsonData

  useEffect(() => {
    jsonData = store.toJSON()
    setPages(jsonData.pages)
  }, [userDetails])

  const moveCard = (index: number, direction: 'up' | 'down') => {
    const newIndex = direction === 'up' ? index - 1 : index + 1
    // Check if the new index is within bounds
    if (newIndex >= 0 && pages) {
      if (newIndex < pages.length) {
        const newPages = [...pages]
        const [removedPage] = newPages.splice(index, 1)
        newPages.splice(newIndex, 0, removedPage)

        const jsonData: JsonData = store.toJSON()
        const newJson = {
          ...jsonData,
          pages: [...newPages],
        }

        setPages(newJson.pages)
        store.loadJSON(newJson, true)
      }
    }
  }

  return (
    <>
      <div style={{ overflowY: 'auto', overflowX: 'auto', height: 'calc(100vh - 100px)', paddingTop: '5px' }}>
        <div>
          {!isLoading && userDetails && pages && pages.length > 0 ? (
            pages.map((item, index) => (
              <VideoTimelineCard
                key={item.id}
                pageID={item.id}
                duration={item.duration}
                store={store}
                index={index}
                setPages={setPages}
                onMoveUp={() => moveCard(index, 'up')}
                onMoveDown={() => moveCard(index, 'down')}
                isMoveUpDisabled={index === 0}
                isMoveDownDisabled={index === pages.length - 1}
              />
            ))
          ) : (
            <div style={{ display: 'flex', alignContent: 'center' }}>{/* <Spin /> */}</div>
          )}
        </div>
      </div>
    </>
  )
})

export const CustomVideoTimelineSection = {
  name: 'video-timeline',
  Tab: (props: any) => (
    <SectionTab name='Video Editor' {...props}>
      <VideoCameraOutlined />
    </SectionTab>
  ),
  Panel: VideoTimelinePanel,
}
