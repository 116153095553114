import { UploadOutlined } from '@ant-design/icons'
import { useGetAssets } from '@src/api/useGetAssets'
import { getDomainID, qaBackendApiInstance } from '../../../config/config'
import { Button, Tabs, message } from 'antd'
import Spin from 'antd/es/spin'
import TabPane from 'antd/es/tabs/TabPane'
import { AxiosError } from 'axios'
import { observer } from 'mobx-react-lite'
import { SectionTab } from 'polotno/side-panel'
import { ImagesGrid } from 'polotno/side-panel/images-grid'
import { getCrop, getImageSize } from 'polotno/utils/image'
import { getVideoSize, getVideoPreview } from 'polotno/utils/video'
import { useEffect, useState } from 'react'
import { ResultModal } from './videoSearchResult'

interface ErrorResponse {
  message: string
}

function getType(file: any) {
  const { type } = file
  if (type.indexOf('svg') >= 0) {
    return 'svg'
  }
  if (type.indexOf('image') >= 0) {
    return 'image'
  }
  if (type.indexOf('video') >= 0) {
    return 'video'
  }
  if (type.indexOf('audio') >= 0) {
    return 'audio'
  }
  return 'image'
}

export const AssetsPanel = observer(({ store }: any) => {
  const [isUploading, setUploading] = useState(false)
  const [images, setImages] = useState<any>([])
  const [videos, setVideos] = useState<any>([])
  const [imagePage, setImagePage] = useState(1)
  const [videoPage, setVideoPage] = useState(1)
  const [visible, setVisible] = useState(false)
  const [video, setVideo] = useState({})

  const {
    data: imageData,
    isLoading,
    isError,
    isSuccess,
    error,
    refetch: refetchImages,
  } = useGetAssets(imagePage, 'image')

  useEffect(() => {
    if (imageData && imageData.items && imageData.items.length > 0) {
      setImages([...images, ...imageData.items])
    }
  }, [imageData])

  const {
    data: videosData,
    isLoading: videoIsLoading,
    isError: videoIsError,
    isSuccess: videoIsSuccess,
    error: videoError,
    refetch: refetchVideos,
  } = useGetAssets(videoPage, 'video')

  useEffect(() => {
    if (videosData && videosData.items && videosData.items.length > 0) {
      setVideos([...videos, ...videosData.items])
    }
  }, [videosData])

  const handleImageLoadMore = () => {
    setImagePage(imagePage + 1)
  }

  const handleVideoLoadMore = () => {
    setVideoPage(videoPage + 1)
  }

  useEffect(() => {
    if (isError) {
      // Attempt to cast the error to an AxiosError
      const axiosError = error as AxiosError

      const errorMessage = (axiosError?.response?.data as ErrorResponse)?.message || 'An unexpected error occurred.'
      message.error(errorMessage)
    }
  }, [isError, isSuccess, isLoading, error])

  async function uploadFile(file: File): Promise<any> {
    const type = getType(file)

    const formData = new FormData()
    formData.append('file', file)
    formData.append('asset_type', type)

    try {
      setUploading(true)
      const response = await qaBackendApiInstance.post(
        `/asset/upload?asset_type=${type}&domain_id=${getDomainID()}`,
        formData
      )
      if (response.status === 200) {
        console.log('File uploaded successfully', response.data)
      } else {
        message.error('Upload failed with status: ' + response.status)
      }
    } catch (error: any) {
      if (
        error.code === 504 ||
        error.code === 'ECONNABORTED' ||
        error.code === 'ECONNRESET' ||
        error.code === 'ETIMEDOUT' ||
        error.code === 'ERR_NETWORK'
      ) {
        console.log('File Uploaded successfully.')
      }
      console.error('Error uploading file:', error)
      message.error('Upload failed.')
    } finally {
      type === 'image' && refetchImages()
      type === 'video' && refetchVideos()
      setUploading(false)
    }
  }

  const handleFileInput = async (e: any) => {
    const { target } = e
    setUploading(true)
    for (const file of target.files) {
      await uploadFile(file)
    }
    setUploading(false)
    target.value = null
  }

  return (
    <div style={{ height: '100%' }}>
      {isLoading ? (
        <div style={{ display: 'flex', alignContent: 'center' }}>
          <Spin />
        </div>
      ) : (
        <>
          <label htmlFor='input-file'>
            <Button
              icon={<UploadOutlined />}
              style={{ width: '100%', paddingBottom: 20, marginBottom: 20 }}
              loading={isUploading}
              onClick={() => {
                ;(document.querySelector('#input-file') as HTMLInputElement)?.click()
              }}
            >
              Upload Asset
            </Button>
            <input type='file' id='input-file' style={{ display: 'none' }} onChange={handleFileInput} multiple />
          </label>
          <Tabs defaultActiveKey='1'>
            <TabPane tab='Images' key='1' style={{ height: '70vh' }}>
              <div style={{ height: '100%' }}>
                <ImagesGrid
                  shadowEnabled={false}
                  images={images?.flat()}
                  getPreview={(item) => `${item?.image_url}`}
                  isLoading={isLoading}
                  onSelect={async (item, pos, element) => {
                    const image = item.image_url
                    const type = item.type

                    const getSizeFunc = type === 'video' ? getVideoSize : getImageSize

                    let { width, height } = await getSizeFunc(image)

                    if (element && element.type === 'svg' && element.contentEditable && type === 'image') {
                      element.set({ maskSrc: image })
                      return
                    }

                    if (element && element.type === 'image' && element.contentEditable && type == 'image') {
                      const crop = getCrop(element, {
                        width,
                        height,
                      })
                      element.set({ src: image, ...crop })
                      return
                    }

                    const scale = Math.min(store.width / width, store.height / height, 1)
                    width = width * scale
                    height = height * scale

                    const x = (pos?.x || store.width / 2) - width / 2
                    const y = (pos?.y || store.height / 2) - height / 2

                    store.activePage?.addElement({
                      type,
                      src: image,
                      x,
                      y,
                      width,
                      height,
                    })
                  }}
                  rowsNumber={2}
                />
                <Button
                  style={{ width: '100%', marginTop: 20 }}
                  onClick={handleImageLoadMore}
                  disabled={isLoading}
                  loading={isLoading}
                >
                  Load More
                </Button>
              </div>
            </TabPane>
            <TabPane tab='Videos' key='2' style={{ height: '70vh' }}>
              <div style={{ height: '100%' }}>
                <ImagesGrid
                  shadowEnabled={false}
                  images={videos?.flat()}
                  getPreview={(item) => `${item?.image_url}`}
                  isLoading={videoIsLoading}
                  onSelect={async (item, pos, element) => {
                    const video = item.video_url
                    const type = item.type

                    const getSizeFunc = type === 'video' ? getVideoSize : getImageSize

                    let { width, height } = await getSizeFunc(video)

                    if (element && element.type === 'svg' && element.contentEditable && type === 'image') {
                      element.set({ maskSrc: video })
                      return
                    }

                    if (element && element.type === 'image' && element.contentEditable && type == 'image') {
                      const crop = getCrop(element, {
                        width,
                        height,
                      })
                      element.set({ src: video, ...crop })
                      return
                    }

                    const scale = Math.min(store.width / width, store.height / height, 1)
                    width = width * scale
                    height = height * scale

                    const x = (pos?.x || store.width / 2) - width / 2
                    const y = (pos?.y || store.height / 2) - height / 2

                    store.activePage?.addElement({
                      type,
                      src: video,
                      x,
                      y,
                      width,
                      height,
                    })
                  }}
                  rowsNumber={2}
                />
                <Button
                  style={{ width: '100%', marginTop: 20 }}
                  onClick={handleVideoLoadMore}
                  disabled={isLoading}
                  loading={isLoading}
                >
                  Load More
                </Button>
              </div>
            </TabPane>
          </Tabs>
        </>
      )}
    </div>
  )
})

// define the new custom section
export const CustomAssetsSection = {
  name: 'user-media',
  Tab: (props: any) => {
    return (
      <SectionTab name='Assets' {...props}>
        <UploadOutlined />
      </SectionTab>
    )
  },
  Panel: AssetsPanel,
}
