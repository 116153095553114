import React from 'react'
import { AbsoluteFill, spring, useCurrentFrame, useVideoConfig } from 'remotion'

interface Word {
  word: string
  start: number
  end: number
}

interface SubtitlesProp {
  captions: Word[]
  width: number
  height: number
  isDownload: boolean
  style: any
}

export const AnimatedSubtitles: React.FC<SubtitlesProp> = ({ captions, width, height, isDownload, style }) => {
  const frame = useCurrentFrame()
  const { fps } = useVideoConfig()

  if (captions === undefined) {
    return <></>
  }
  const currentTime = frame / fps // Current time in seconds

  const lastIndex = captions.findIndex((last) => last.end * fps >= frame) ?? -1

  if (lastIndex === -1) return <></>

  const start = Math.floor(lastIndex / style.wordsPerLine)

  const visibleCaptions = captions.slice(start * style.wordsPerLine, (start + 1) * style.wordsPerLine)

  const currentFrame = frame - (visibleCaptions?.[0].start || 0) * fps

  const anim = spring({
    fps: fps,
    frame: currentFrame,
    config: {
      damping: 1,
      mass: 0.001,
      stiffness: 1,
    },
  })

  const scaleFactor = isDownload ? 5 : Math.min(width / 1 / width, height / 1 / height)

  const textShadowSize = style.outlineSize ? `${style.outlineSize}px` : '0px'
  const textShadowColor = style.outlineColor || 'transparent'
  const textShadow = `${textShadowSize} ${textShadowSize} ${textShadowColor}`

  const adjustedYPosition = style.position

  return (
    <AbsoluteFill>
      <p
        style={{
          position: 'absolute',
          left: '50%',
          transform: `translateX(-50%) ${isDownload ? '' : `scale(${scaleFactor})`}`,
          top: `${adjustedYPosition}px`,
          padding: '10px',
          backgroundColor: style.backgroundColor ?? 'black',
          color: style.fontColor ?? 'white',
          textAlign: 'center',
          fontFamily: style.font ?? 'Arial',
          fontSize: `${style.fontSize}px`,
          textShadow: textShadow,
          maxWidth: '100%',
          width: '80%',
        }}
      >
        {visibleCaptions?.map((subtitle, index) => {
          const isCurrent = currentTime >= subtitle.start && currentTime <= subtitle.end
          return (
            <span
              key={index}
              style={{
                color: isCurrent ? style.highlightTextStyleColor ?? 'yellow' : style.fontColor ?? 'white',
                marginRight: '5px',
              }}
            >
              {subtitle.word}{' '}
            </span>
          )
        })}
      </p>
    </AbsoluteFill>
  )
}

function yPositionAllignmentForVideo(value: number | undefined, playerHeight: number, jsonDataHeight: number) {
  const oldValue = value ?? 50 // Assuming this is the Y position in the original scale

  const oldMin = 0
  const oldMax = jsonDataHeight

  // Define new range
  const newMin = 0
  const newMax = playerHeight

  // Apply formula to scale oldValue from the old range to the new range
  const newValue = Math.round(((oldValue - oldMin) * (newMax - newMin)) / (oldMax - oldMin) + newMin)

  return newValue
}
