import { IS_FREE_PLATFORM } from '../../config'
import { resizeList } from '../../data/resize'
import { getImageSize, getCrop } from 'polotno/utils/image'
import { getVideoSize } from 'polotno/utils/video'

export function generateUniqueId() {
  return Math.random().toString(36).substr(2, 10)
}

export function fitText(
  containerWidth: any,
  containerHeight: any,
  textContent: any,
  fontFamily: any,
  letterSpacing: any,
  lineHeight: any,
  fontSize: any
) {
  const containerRatio = containerWidth / containerHeight
  const text = document.createElement('span')
  text.style.visibility = 'hidden'
  text.style.position = 'absolute'
  text.style.fontFamily = fontFamily
  text.style.letterSpacing = letterSpacing + 'px'
  text.style.lineHeight = lineHeight
  text.style.fontSize = fontSize + 'px'
  text.textContent = textContent
  document.body.appendChild(text)

  let textWidth = text.offsetWidth
  let textHeight = text.offsetHeight
  let currentFontSize = fontSize

  while ((textWidth > containerWidth || textHeight > containerHeight) && currentFontSize > 0) {
    currentFontSize--
    text.style.fontSize = currentFontSize + 'px'
    textWidth = text.offsetWidth
    textHeight = text.offsetHeight
  }

  document.body.removeChild(text)

  return currentFontSize
}

export function fitTextwithBreaks(
  containerWidth: any,
  containerHeight: any,
  textContent: any,
  fontFamily: any,
  letterSpacing: any,
  lineHeight: any,
  fontSize: any
) {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  if (context) {
    textContent = textContent || ''
    context.font = `${fontSize}px ${fontFamily}`

    const words = textContent.split(' ')
    const lines = []
    let line = ''
    let maxHeight = 0

    for (let i = 0; i < words.length; i++) {
      const testLine = line + words[i] + ' '
      const metrics = context.measureText(testLine)
      const testWidth = metrics.width

      if (testWidth > containerWidth && i > 0) {
        lines.push({ text: line.trim(), width: context.measureText(line.trim()).width })
        line = words[i] + ' '
        maxHeight += lineHeight * fontSize
      } else {
        line = testLine
      }
    }
    lines.push({ text: line.trim(), width: context.measureText(line.trim()).width })
    maxHeight += lineHeight * fontSize

    // Adjust scale calculation
    const maxWidth = Math.max(...lines.map((line) => line.width))
    const scaleWidth = containerWidth / maxWidth
    const scaleHeight = containerHeight / maxHeight
    let scale = Math.min(scaleWidth, scaleHeight)

    // Introduce scaling adjustments
    const scalingThreshold = 0.5 // Scale adjustment threshold
    if (scale < scalingThreshold) {
      scale = scale + (scalingThreshold - scale) * 0.5 // Adjust scale to be less aggressive
    }

    let scaledFontSize = fontSize * scale

    // Dynamic minimum font size based on container size
    const minFontSize = Math.max(12, containerWidth / 100) // Example dynamic calculation
    if (scaledFontSize < minFontSize) {
      scaledFontSize = minFontSize
    }

    return scaledFontSize - 2
  }
  return fontSize
}

export function extractPagesFromHtml(htmlString: any) {
  // Correct 'className' to 'class' for HTML parsing
  const correctedHtmlString = htmlString.replace(/className=/g, 'class=')

  // Create a new DOMParser instance
  const parser = new DOMParser()

  // Parse the corrected HTML string into a document object
  const doc = parser.parseFromString(correctedHtmlString, 'text/html')

  // Use querySelectorAll to find all elements with the class 'page'
  const pages = doc.querySelectorAll('.page')

  // Map over the NodeList of pages, and return an array of HTML strings
  const pagesHtml = Array.from(pages).map((page) => page.outerHTML)

  return pagesHtml
}

interface Animation {
  delay: number
  duration: number
  enabled: boolean
  type: string
  name: string
  data: any
}

interface Child {
  id: string
  type: string
  text?: string
  src?: string // For images
  width?: number // For SVGs
  height?: number
  x: number
  y: number
  animations: Animation[]
}

interface Page {
  id: string
  children: Child[]
}

interface JsonData {
  pages: Page[]
}

export function addIdsToHtmlFromJson(htmlStrings: string[], jsonData: JsonData): string[] {
  return htmlStrings.map((htmlString, index) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, 'text/html')

    const pageJson = jsonData.pages[index]
    if (pageJson) {
      pageJson.children.forEach((child) => {
        if (child.type === 'text' && child.text) {
          handleTextType(doc, child)
        } else if (child.type === 'image' && child.src) {
          handleImageType(doc, child)
        } else if (child.type === 'svg' && child.width && child.height) {
          handleTextType(doc, child)
        }
      })
    }

    return doc.body.innerHTML
  })
}

function setIdOnElement(element: Element, id: string): void {
  element.setAttribute('id', id)
}

function normalizeHtmlText(htmlContent: string): string {
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = htmlContent
  return tempDiv.textContent || tempDiv.innerText || ''
}

function handleTextType(doc: Document, child: Child): void {
  if (!child.text || child.x === undefined || child.y === undefined) {
    // console.warn('Text or position data undefined for child with ID:', child.id)
    return // Exit the function if essential data is missing
  }

  const normalizedText = normalizeHtmlText(child.text)
  const targetElements = doc.querySelectorAll('div')

  targetElements.forEach((element) => {
    const style = element.getAttribute('style')
    if (style && positionMatches(style, child.x, child.y) && normalizeHtmlText(element.innerHTML) === normalizedText) {
      setIdOnElement(element, child.id)
    }
  })
}

function positionMatches(style: string, x: number, y: number): boolean {
  const leftMatch = style.match(/left:(\s*\d+\.?\d*)px/)
  const topMatch = style.match(/top:(\s*\d+\.?\d*)px/)
  const left = leftMatch ? parseFloat(leftMatch[1]) : null
  const top = topMatch ? parseFloat(topMatch[1]) : null

  return left === x && top === y
}

function handleImageType(doc: Document, child: Child): void {
  const divs = doc.querySelectorAll('div[style]')
  divs.forEach((div) => {
    const style = div.getAttribute('style')!
    if (style.includes(`url(${child.src})`)) {
      setIdOnElement(div, child.id)
    }
  })
}

export function calculateYPosition(canvasHeight: number, textBoxHeight: number, paddingFromBottomPercentage: number) {
  return canvasHeight - textBoxHeight - canvasHeight * (paddingFromBottomPercentage / 100)
}

export async function resizeDesign(
  jsonData: any,
  store: any,
  userDetails: any,
  setUserDetails: (details: any) => void,
  isFirstRender: boolean,
  projectData?: any,
  sizeString?: string
) {
  // Parse JSON input
  const designData = jsonData

  const sizeObject = resizeList.find((item) => item.value === sizeString)
  const newWidth = sizeObject?.width || 500
  const newHeight = sizeObject?.height || 500

  // Calculate scaling factors for width and height
  const widthScale = newWidth / designData.width
  const heightScale = newHeight / designData.height

  // Update canvas width and height
  designData.width = newWidth
  designData.height = newHeight

  // Iterate over pages
  for (let i = 0; i < designData.pages.length; i++) {
    const page = designData.pages[i]
    // Scale individual elements on the page
    for (let j = 0; j < page.children.length; j++) {
      const element = page.children[j]
      // Update position
      element.x *= widthScale
      element.y *= heightScale

      const elementID = element.id as string
      if (!elementID.includes('quickadsLogo') && !(elementID == 'captionText' + page.id)) {
        element.cropHeight = 0.99
        element.cropWidth = 0.5

        // Update width and height
        element.width *= widthScale
        element.height *= heightScale

        // Update font size for text elements
        if (element.type === 'text') {
          // element.fontSize *= Math.min(widthScale, heightScale)

          element.fontSize = fitTextwithBreaks(
            element.width,
            element.height,
            element.text,
            element.fontFamily,
            element.letterSpacing,
            element.lineHeight,
            element.fontSize
          )
        }

        if (element.type === 'image') {
          // Fetch the image from the URL
          const img = new Image()

          // Set up a promise to resolve when the image is loaded
          const imageLoaded = new Promise<HTMLImageElement>((resolve, reject) => {
            img.onload = () => resolve(img)
            img.onerror = reject
          })

          // Set the image source URL
          img.src = element.src

          // Wait for the image to load and then get its dimensions
          const loadedImg = await imageLoaded.catch((error) => {
            console.error('Error loading image:', error)
          })

          if (loadedImg) {
            const imageWidth = loadedImg.width
            const imageHeight = loadedImg.height

            // Calculate the aspect ratio of the image
            const imageAspectRatio = imageWidth / imageHeight

            // Calculate the aspect ratio of the container
            const containerAspectRatio = element.width / element.height

            // Initialize crop values
            let cropX = 0
            let cropY = 0
            let cropWidth = 1
            let cropHeight = 1

            if (imageAspectRatio > containerAspectRatio) {
              // Image is wider than the container, so adjust cropWidth
              cropWidth = containerAspectRatio / imageAspectRatio
              cropX = (1 - cropWidth) / 2
            } else {
              // Image is taller than the container, so adjust cropHeight
              cropHeight = imageAspectRatio / containerAspectRatio
              cropY = (1 - cropHeight) / 2
            }

            // Update the crop values for the image
            // store.getElementById(element.id).set({
            //   cropX: cropX,
            //   cropY: cropY,
            //   cropWidth: cropWidth,
            //   cropHeight: cropHeight,
            // })
            element.cropX = cropX
            element.cropY = cropY
            element.cropWidth = cropWidth
            element.cropHeight = cropHeight
          }
        }
        if (element.type === 'video') {
          // Create a video element
          const video = document.createElement('video')

          // Set the video source URL
          video.src = element.src
          // Wait for user interaction
          await new Promise((resolve) => {
            video.onloadedmetadata = resolve
            video.load()
          })

          // This event is fired when the video's metadata has been loaded
          // video.addEventListener('loadedmetadata', () => {
          // Now we can access video dimensions
          const videoWidth = video.videoWidth
          const videoHeight = video.videoHeight

          // Calculate the aspect ratio of the video
          const videoAspectRatio = videoWidth / videoHeight

          // Assuming you have defined the container's aspect ratio
          const containerAspectRatio = element.width / element.height

          // Initialize crop values
          let cropX = 0
          let cropY = 0
          let cropWidth = 1
          let cropHeight = 1

          if (videoAspectRatio > containerAspectRatio) {
            // Video is wider than the container, so adjust cropWidth
            cropWidth = containerAspectRatio / videoAspectRatio
            cropX = (1 - cropWidth) / 2
          } else {
            // Video is taller than the container, so adjust cropHeight
            cropHeight = videoAspectRatio / containerAspectRatio
            cropY = (1 - cropHeight) / 2
          }

          element.cropX = cropX
          element.cropY = cropY
          element.cropWidth = cropWidth
          element.cropHeight = cropHeight
        }
      } else if (!(elementID == 'captionText' + page.id)) {
        element.x = newWidth - element.width - 25
      }

      designData.pages[i].children[j] = element
    }
  }

  // Convert the updated design data back to JSON
  const resizedJson = designData

  // setUpdatedTemplate(resizedJson)
  if (IS_FREE_PLATFORM === 'false') {
    if (isFirstRender === true) {
      setUserDetails({
        ...projectData,
        purpose: { ...projectData.purpose, size: sizeString },
      })
    } else {
      setUserDetails({
        ...userDetails,
        purpose: { ...userDetails.purpose, size: sizeString },
      })
    }
  } else {
    setUserDetails({
      purpose: { size: sizeString },
    })
  }

  await store.loadJSON(resizedJson, true)
  // setUpdatedTemplate(resizedJson)
}

export function capitalizeWords(str: any) {
  return str.replace(/\b\w/g, function (char: any) {
    return char.toUpperCase()
  })
}

export function setCookie({ name, value, days, domain }: any) {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/; domain=' + domain
}
