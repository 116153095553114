import React, { useEffect, useRef } from 'react'
import { AbsoluteFill, OffthreadVideo, Video, useCurrentFrame, useVideoConfig } from 'remotion'
import { applyAnimations } from '../../../shared/utils/remotion'

interface Animation {
  delay: number
  duration: number
  enabled: boolean
  type: string
  name: string
  data: any
}

interface Child {
  id: string
  type: string
  text?: string
  animations: Animation[]
  src?: string
  x: number
  y: number
  width: number
  height: number
  rotation: number
  fontFamily?: string
  fontSize?: number
  fontWeight?: string
  fontStyle?: string
  textAlign?: string
  fill?: string
  cornerRadius?: number
  borderSize?: number
  cropX: number
  cropY: number
  blurEnabled: boolean
  blurRadius: number
  sepiaEnabled: boolean
  grayscaleEnabled: boolean
  brightnessEnabled: boolean
  brightness: number
  shadowEnabled: boolean
  shadowBlur: number
  letterSpacing: number
  strokeWidth: number
  stroke: string
  lineHeight: number
  cropWidth: number
  cropHeight: number
}

interface PageData {
  id: string
  children: Child[]
}

// interface JsonData {
//   pages: Page[]
// }

interface SinglePageProps {
  pageHtml: any // HTML string for the page content
  width: number
  height: number
  pageData: PageData // Adjusted to pass JSON data
  isDownload: boolean
}

export const SinglePage: React.FC<SinglePageProps> = React.memo(
  ({ pageHtml, width, height, pageData, isDownload }: SinglePageProps) => {
    const frame = useCurrentFrame()
    const { fps, durationInFrames } = useVideoConfig()
    const pageRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
      applyAnimations(pageRef, pageData, frame, fps, durationInFrames)
    }, [frame, pageData])

    // Component's rendering logic remains the same
    // const scaleFactorWidth = width / 3.5 / width
    // const scaleFactorHeight = height / 3.5 / height
    // const scaleFactor = Math.min(scaleFactorWidth, scaleFactorHeight)

    const scaleFactor = isDownload ? 1 : Math.min(width / 1 / width, height / 1 / height)

    return (
      <AbsoluteFill>
        <AbsoluteFill>
          {pageData.children.map((child, index) => {
            if (child.type === 'video' && child.src) {
              const cropStyle: React.CSSProperties = {
                position: 'absolute',
                overflow: 'hidden',
                left: isDownload ? `${child.x}px` : `${child.x * scaleFactor}px`,
                top: isDownload ? `${child.y}px` : `${child.y * scaleFactor}px`,
                width: isDownload ? `${child.width}px` : `${child.width * scaleFactor}px`,
                height: isDownload ? `${child.height}px` : `${child.height * scaleFactor}px`,
                transform: `rotate(${child.rotation}deg)`,
              }
              const adjustedCropX = isDownload ? child.cropX : (child.cropX * child.cropWidth) / scaleFactor
              const adjustedCropY = isDownload ? child.cropY : (child.cropY * child.cropWidth) / scaleFactor
              console.log(`${adjustedCropX * 100}% ${adjustedCropY * 100}%`) 
              return (
                <div key={child.id} style={cropStyle}>
                  <OffthreadVideo
                    src={child.src}
                    style={{
                      position: 'absolute',
                      width: '100%', // Cover the full parent area
                      height: '100%', // Adjust for cropHeight
                      objectFit: 'cover',
                      // objectPosition: `${adjustedCropX * 100}% ${adjustedCropY * 100}%`, // Adjust for crop position
                      objectPosition :'10% 10%'
                    }}
                    muted
                  />
                </div>
              )
            }
            return null
          })}
        </AbsoluteFill>
        <AbsoluteFill>
          <div
            ref={pageRef}
            style={{
              position: 'absolute',
              transform: isDownload ? 'none' : `scale(${scaleFactor})`,
              transformOrigin: 'top left',
              width: `${width}px`,
              height: `${height}px`,
            }}
            dangerouslySetInnerHTML={{ __html: pageHtml }}
          />
        </AbsoluteFill>
      </AbsoluteFill>
    )
  }
)

SinglePage.displayName = 'SinglePage'
