/* eslint-disable react/prop-types */
import { LoadingOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { VideoPlayer } from '@src/features/video/player/videoPlayer'
import { millisecondsToFrames } from '@src/shared/utils/remotion'
import { Button, Modal } from 'antd'
import { useRef, useState } from 'react'
import Draggable from 'react-draggable'
import { useGlobalContext } from '../../../context/globalContext'
import './customActionControlContainer.less'
import { BulkCreateTab } from '@src/features/video/blukCreateTab/bulkCreateTab'
import { resizeDesign } from '@src/shared/utils/core'
import { bultCreateArray } from '@src/data/bulkCreate'
import { trackEvent } from '@src/lib/mixpanel'
import { burstImageTransitionFunctions } from '@src/data/transitions'

interface BulkCreateButtonProps {
  store: any // Ideally, replace `any` with a more specific type
  open: boolean
  setOpen: any
  bulkCreatePropsArray: any
  setBulkCreatePropsArray: any
}

async function bulkCreateFunction(
  store: any,
  userDetails: any,
  setUserDetails: any,
  scriptDetails: any,
  videoStyle: string
) {
  let bulkCreateProps: any[] = []

  for (let i = 0; i < bultCreateArray.length; i++) {
    const value = bultCreateArray[i].value
    const selectedRatio = bultCreateArray.find((item) => item.value === value)

    if (!selectedRatio) return

    const currentWidth = store.toJSON().width
    const currentHeight = store.toJSON().height

    const newWidth = currentWidth // Keep current width
    const newHeight = (newWidth / selectedRatio.width) * selectedRatio.height

    const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))

    await resizeDesign(oldJSON, store, userDetails, setUserDetails, false, undefined, value)

    store.pages.map((page: any) => {
      const elementID = 'captionText' + page.id
      if (store.getElementById(elementID)) {
        store.getElementById(elementID).set({
          opacity: 0,
        })
      }
    })
    const polotnoHtml = await store.toHTML()
    const polotnoJson = await store.toJSON()

    const burstImagesTransitionAnimationArray: any = []

    if (videoStyle === 'Burst') {
      const burstImagesTransitionAnimationArray = []

      for (let i = 0; i < scriptDetails.data.length; i++) {
        const innerArray = []

        for (let j = 0; j < scriptDetails.data[i].images.length; j++) {
          const randomFunction =
            burstImageTransitionFunctions[Math.floor(Math.random() * burstImageTransitionFunctions.length)]
          innerArray.push(randomFunction.value)
        }
        burstImagesTransitionAnimationArray.push(innerArray)
      }
    }

    bulkCreateProps = [
      ...bulkCreateProps,
      {
        id: bultCreateArray[i].id,
        htmlCode: polotnoHtml,
        width: store.width,
        height: store.height,
        jsonData: polotnoJson,
        duration: millisecondsToFrames(store.duration, 30),
        burstImagesTransitionAnimationArray: burstImagesTransitionAnimationArray,
        videoStyle: videoStyle,
      },
    ]
  }
  return bulkCreateProps
}

export const BulkCreateButton: React.FC<BulkCreateButtonProps> = ({
  store,
  open,
  setOpen,
  bulkCreatePropsArray,
  setBulkCreatePropsArray,
}) => {
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const draggleRef = useRef<HTMLDivElement>(null)
  const {
    UserDetail: [userDetails, setUserDetails],
    ScriptDetails: [scriptDetails, setScriptDetails],
    VideoStyle: [videoStyle, setVideoStyle],
  } = useGlobalContext()

  // loading for when gif is rendering
  const [isGifRendering, setIsGifRendering] = useState<boolean>(false)
  const User = JSON.parse(localStorage.getItem('userData') as string)

  function undo() {
    for (let i = 0; i < bulkCreatePropsArray.length; i++) {
      store.history.undo()
    }
  }

  const showModal = () => {
    setOpen(true)
  }

  const handleOk = () => {
    setOpen(false)
    undo()
    store.pages.map((page: any) => {
      const elementID = 'captionText' + page.id
      if (store.getElementById(elementID)) {
        store.getElementById(elementID).set({
          opacity: 1,
        })
      }
    })
  }

  const handleCancel = () => {
    setOpen(false)
    undo()
    store.pages.map((page: any) => {
      const elementID = 'captionText' + page.id
      if (store.getElementById(elementID)) {
        store.getElementById(elementID).set({
          opacity: 1,
        })
      }
    })
  }

  const [width, setWidth] = useState<number>(500)

  const modalTitle = (
    <div
      style={{ width: '100%', cursor: 'move' }}
      className='draggable-modal-title' // This class will be used as a handle
    >
      Bulk Create Preview
    </div>
  )

  return (
    <>
      <Button
        onClick={async () => {
          trackEvent('Bulk Videos Generated')
          setIsGifRendering(true)
          const bulkCreatePropsResult = await bulkCreateFunction(
            store,
            userDetails,
            setUserDetails,
            scriptDetails,
            videoStyle
          )
          setBulkCreatePropsArray(bulkCreatePropsResult ?? [])
          showModal()
          setIsGifRendering(false)
        }}
        style={{ background: 'transparent', borderColor: '#6A39EE', marginBottom: '10px', color: '#6A39EE' }}
        icon={isGifRendering ? <LoadingOutlined /> : <PlayCircleOutlined />}
      >
        Bulk Create
      </Button>
      <Modal
        title={modalTitle}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        width={width} // Set the width of the Modal to match the GifPlayer's width
        style={{
          top: '50%',
          transform: 'translateY(-50%)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        modalRender={(modal) => (
          <Draggable nodeRef={draggleRef} bounds='parent' handle='.draggable-modal-title'>
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        destroyOnClose={true}
      >
        <BulkCreateTab store={store} bulkCreateArray={bulkCreatePropsArray} />
      </Modal>
    </>
  )
}
