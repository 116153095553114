import { IS_FREE_PLATFORM } from '@src/config'
import { Table, TableProps } from 'antd'
import React, { useState } from 'react'

interface RegeneratedScriptContainerProps {
  store: any
  regeneratedScript: any
}

interface DataType {
  key: number
  adBlockType: string
  onScreenVisual: string
  voiceOver: string
  textOverlay: string
  index: number
}

export const RegneratedScriptContainer: React.FC<RegeneratedScriptContainerProps> = ({ store, regeneratedScript }) => {
  const initialData: DataType[] = regeneratedScript?.data?.map(
    (script: { adBlockType: any; onScreenVisual: any; voiceOver: any; textOverlay: any }, index: number) => ({
      key: index,
      adBlockType: script.adBlockType,
      onScreenVisual: script.onScreenVisual,
      voiceOver: script.voiceOver,
      textOverlay: script.textOverlay,
      index: index,
    })
  )

  const [dataSource, setDataSource] = useState<DataType[]>(initialData)

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'AD BLOCK TYPE',
      dataIndex: 'adBlockType',
      width: '30%',
      fixed: 'left',
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'ON-SCREEN VISUAL',
      dataIndex: 'onScreenVisual',
      width: '30%',
      fixed: 'left',
    },
    {
      title: 'VOICE-OVER',
      dataIndex: 'voiceOver',
      width: '30%',
      fixed: 'left',
    },
  ]

  if (IS_FREE_PLATFORM === 'false') {
    columns.push({
      title: 'TEXT-OVERLAY',
      dataIndex: 'textOverlay',
      width: '30%',
      fixed: 'left',
    })
  }

  return <Table columns={columns} dataSource={dataSource} pagination={false} scroll={{ x: 1000, y: 500 }} />
}
