import { useGlobalContext } from '../../../context/globalContext'
import { Row } from 'antd'
import React from 'react'
import { VisualOverlayRow } from './cardRows/visualOverlayRow'
import './videoTimelineCard.less'

interface Child {
  id: string
  type: string
  text?: string
}

interface PageItem {
  id: string
  type: string
  name: string
  duration: number
  children: Child[]
}

export interface JsonData {
  width: number
  height: number
  fonts: any[]
  pages: PageItem[]
}

interface VideoTimelineCardProps {
  pageID: string
  duration: number
  store: any
  index: number
  setPages: (val: any) => void
  onMoveUp: () => void
  onMoveDown: () => void
  isMoveUpDisabled: boolean
  isMoveDownDisabled: boolean
}

export const VideoTimelineCard: React.FC<VideoTimelineCardProps> = ({ pageID, index, store, setPages }) => {
  // Global Context
  const {
    ScriptDetails: [scriptDetails, setScriptDetails],
  } = useGlobalContext()

  // handle clicks
  const handleCardClick = () => {
    try {
      store.selectPage(store.pages[index].id)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <div
        className='video-timeline-card'
        onClick={handleCardClick}
        style={{
          backgroundColor: 'transparent', // Center the background image
          cursor: 'default',
          marginTop: '10px',
          padding: '20px',
        }}
      >
        <Row gutter={[0, 8]} style={{ flexDirection: 'column' }}>
          <VisualOverlayRow store={store} pageID={pageID} index={index} setPages={setPages} />
        </Row>
      </div>
    </>
  )
}
