/* eslint-disable react/jsx-key */
import { FONT_TEMPLATE } from '@src/data/captions'
import { calculateYPosition } from '@src/shared/utils/core'
import { addSpanTagToText } from '@src/shared/utils/formatString'
import { hexToRgb } from '@src/shared/utils/polotnoStore'
import { Button, Row, Select, Switch, message } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import './captionsPanel.less'
import FontPickerRow from './rows/fontPickerRow'
import ChooseFontTemplatesRow from './rows/fontStyleRow'
import PositionSliderRow from './rows/positionSliderRow'
import WordSlider from './rows/wordSliderRow'
import { ReloadOutlined, LoadingOutlined } from '@ant-design/icons'
import { IS_FREE_PLATFORM } from '@src/config'
import { generateCaptions } from '@src/shared/utils/VideoGeneration/audioGeneration'
import { useGlobalContext } from '@src/context/globalContext'

const { Option } = Select

interface CaptionsPanelProps {
  store: any
}

export const generateSubtitles = async (
  font?: string,
  wpl?: number,
  fontSize?: number,
  store?: any,
  wordsPerLine?: number,
  fontTemplate?: any,
  showSubtitles?: boolean,
  yAxisPosition?: number,
  setYAxisPosition?: any,
  setScriptDetails?: any
) => {
  await Promise.all(
    store.pages.map(async (page: any, index: number) => {
      const { custom } = page
      const { captions } = custom
      // if (!captions && !captions.length) return
      if (!captions || !captions.length) {
        return Promise.resolve()
      }

      const { words } = JSON.parse(JSON.stringify(captions[0]))
      const elementID = 'captionText' + page.id
      const captionString = addSpanTagToText(
        words
          .splice(0, wpl ? wpl : wordsPerLine)
          .map((word: any) => word.word)
          .join(' '),
        fontTemplate.highlightTextStyleColor
      )
      if (!page.children.filter((element: any) => element.id === elementID).length) {
        page.addElement({
          text: captionString,
          fill: hexToRgb(fontTemplate.fontColor),
          backgroundColor: fontTemplate.backgroundColor !== '' ? hexToRgb(fontTemplate.backgroundColor) : '',
          backgroundEnabled: fontTemplate.backgroundColor !== '' ? true : false,
          rotateX: fontTemplate.rotateX,
          fontFamily: font ? font : fontTemplate.font,
          fontSize: fontSize ? fontSize : fontTemplate.fontSize,
          id: elementID,
          type: 'text',
          x: 0,
          width: store.width,
          draggable: false,
          contentEditable: false,
          styleEditable: false,
          resizable: false,
          selectable: false,
          opacity: showSubtitles ? 1 : 0,
        })
        const yPosition = calculateYPosition(
          store.height,
          store.getElementById(elementID).height,
          IS_FREE_PLATFORM === 'true' ? 40 : 10
        )
        store.getElementById(elementID).set({
          y: yPosition,
        })
        setYAxisPosition(yPosition)
      } else {
        store.getElementById(elementID).set({
          text: captionString,
          fill: hexToRgb(fontTemplate.fontColor),
          backgroundColor: fontTemplate.backgroundColor !== '' ? hexToRgb(fontTemplate.backgroundColor) : '',
          backgroundEnabled: fontTemplate.backgroundColor !== '' ? true : false,
          fontFamily: font ? font : fontTemplate.font,
          fontSize: fontSize ? fontSize : fontTemplate.fontSize,
          id: elementID,
          type: 'text',
          x: 0,
          opacity: showSubtitles ? 1 : 0,
        })
      }

      let updatedTemplate = { ...page.custom.template, ...fontTemplate, position: yAxisPosition }

      if (font) updatedTemplate = { ...updatedTemplate, font: font }
      if (wpl) updatedTemplate = { ...updatedTemplate, wordsPerLine: wpl }
      if (fontSize) updatedTemplate = { ...updatedTemplate, fontSize: fontSize }

      page.set({ custom: { ...page.custom, template: updatedTemplate } })

      await setScriptDetails((prevScript: { data: any }) => {
        const updatedScript = { ...prevScript }

        return { ...updatedScript, template: updatedTemplate }
      })

      if (showSubtitles) {
        page.set({ custom: { ...page.custom, showSubtitles: true } })
        await setScriptDetails((prevScript: { data: any }) => {
          const updatedScript = { ...prevScript }

          return { ...updatedScript, showSubtitles: true }
        })
      }
    })
  )
}

export const CaptionsPanel: React.FC<CaptionsPanelProps> = React.memo(({ store }: CaptionsPanelProps) => {
  const {
    ScriptDetails: [scriptDetails, setScriptDetails],
    VideoStyle: [videoStyle, setVideoStyle],
  } = useGlobalContext()

  const [selectedFont, setSelectedFont] = useState(
    store.pages[0].custom?.template?.font ? store.pages[0].custom?.template.font : FONT_TEMPLATE[0].font
  )
  const [wordsPerLine, setWordsPerLine] = useState(
    store.pages[0].custom?.template ? store.pages[0].custom?.template.wordsPerLine : FONT_TEMPLATE[0].wordsPerLine
  )

  const [yAxisPosition, setYAxisPosition] = useState(store.height - store.height * 0.2)
  const [fontTemplate, setFontTemplate] = useState<any>(
    store.pages[0].custom?.template
      ? store.pages[0].custom?.template
      : { ...FONT_TEMPLATE[0], position: store.height - store.height * 0.2 }
  )
  const [fontSize, setFontSize] = useState(
    store.pages[0].custom?.template ? store.pages[0].custom?.template.fontSize : FONT_TEMPLATE[0].fontSize
  )
  const [showSubtitles, setShowSubtitles] = useState(true)

  const [showTryLaterMessage, setShowTryLaterMessage] = useState(false)
  const [regenrateButtonIcon, setRegenrateButtonIcon] = useState(false)

  useEffect(() => {
    store.pages.map((page: any) => {
      if (!page.custom.captions || !(page?.custom?.captions?.length > 0)) {
        setShowTryLaterMessage(true)
      } else {
        if (!page.custom) page.set({ custom: { ...page.custom, template: FONT_TEMPLATE[0] } })
        if (!page.custom.template) page.set({ custom: { ...page.custom, template: FONT_TEMPLATE[0] } })
      }
    })
    if (showSubtitles === true) {
      setScriptDetails((prevScript: { data: any }) => {
        const updatedScript = { ...prevScript }

        return { ...updatedScript, showSubtitles: true }
      })
      store.pages.map((page: any) => {
        const elementID = 'captionText' + page.id
        page.set({ custom: { ...page.custom, showSubtitles: true } })
        if (store.getElementById(elementID)) {
          store.getElementById(elementID).set({
            opacity: 1,
            backgroundEnabled: true,
          })
        }
      })
    }
  }, [])

  useEffect(() => {
    if (store.pages[0].cutom?.template?.name !== fontTemplate.name) {
      generateSubtitles(
        undefined,
        undefined,
        undefined,
        store,
        wordsPerLine,
        fontTemplate,
        showSubtitles,
        yAxisPosition,
        setYAxisPosition,
        setScriptDetails
      )
      setFontSize(fontTemplate.fontSize)
      setWordsPerLine(fontTemplate.wordsPerLine)
      setSelectedFont(fontTemplate.font)
    }
  }, [fontTemplate])

  async function regenerateSubtitles() {
    setRegenrateButtonIcon(true)
    let captionsResult
    await Promise.all(
      store.pages.map(async (page: any, index: number) => {
        const audioUrl = page?.custom?.sounds[0]?.url
        if (!audioUrl) {
          message.info('Audio not found')
          return
        }
        captionsResult = await generateCaptions(audioUrl)
        if (captionsResult) {
          store.pages[index].set({
            custom: {
              ...store.pages[index].custom,
              captions: [{ words: captionsResult?.data?.words }],
            },
          })
        }
      })
    )
    if (captionsResult) {
      generateSubtitles(
        undefined,
        undefined,
        undefined,
        store,
        wordsPerLine,
        fontTemplate,
        showSubtitles,
        yAxisPosition,
        setYAxisPosition,
        setScriptDetails
      )
      setShowTryLaterMessage(false)
    } else {
      message.error('Regeneration Failed')
      setRegenrateButtonIcon(false)
      setShowTryLaterMessage(true)
    }
  }

  const setFontTemplates = (name: any) => {
    const template = FONT_TEMPLATE.find((template) => template.name === name)
    setFontTemplate(template)
  }

  const updateSubtitleYAxis = (yAxisPosition: number) => {
    store.pages.map((page: any) => {
      const elementID = 'captionText' + page.id
      setYAxisPosition(yAxisPosition)
      store.getElementById(elementID).set({
        y: yAxisPosition,
      })
      page.set({ custom: { ...page.custom, template: { ...page.custom.template, position: yAxisPosition } } })
    })
  }

  const updateFontSize = (fontSize: number) => {
    setFontSize(fontSize)
    generateSubtitles(
      selectedFont,
      wordsPerLine,
      fontSize,
      store,
      wordsPerLine,
      fontTemplate,
      showSubtitles,
      yAxisPosition,
      setYAxisPosition,
      setScriptDetails
    )
  }

  const updateWordsPerLine = (wordsPerLine: number) => {
    setWordsPerLine(wordsPerLine)
    generateSubtitles(
      selectedFont,
      wordsPerLine,
      fontSize,
      store,
      wordsPerLine,
      fontTemplate,
      showSubtitles,
      yAxisPosition,
      setYAxisPosition,
      setScriptDetails
    )
  }

  const updateFont = (font: string) => {
    setSelectedFont(font)
    generateSubtitles(
      font,
      wordsPerLine,
      fontSize,
      store,
      wordsPerLine,
      fontTemplate,
      showSubtitles,
      yAxisPosition,
      setYAxisPosition,
      setScriptDetails
    )
  }

  const handleShowSubtitles = async () => {
    if (showSubtitles) {
      await setScriptDetails((prevScript: { data: any }) => {
        const updatedScript = { ...prevScript }

        return { ...updatedScript, showSubtitles: false }
      })
      store.pages.map((page: any) => {
        const elementID = 'captionText' + page.id
        page.set({ custom: { ...page.custom, showSubtitles: false } })
        if (store.getElementById(elementID)) {
          store.getElementById(elementID).set({
            opacity: 0,
            backgroundEnabled: false,
          })
        }
      })
    } else {
      await setScriptDetails((prevScript: { data: any }) => {
        const updatedScript = { ...prevScript }

        return { ...updatedScript, showSubtitles: true }
      })
      store.pages.map((page: any) => {
        const elementID = 'captionText' + page.id
        page.set({ custom: { ...page.custom, showSubtitles: true } })
        if (store.getElementById(elementID)) {
          store.getElementById(elementID).set({
            opacity: 1,
            backgroundEnabled: true,
          })
        }
      })
    }
    setShowSubtitles(!showSubtitles)
  }

  return (
    <>
      {showTryLaterMessage ? (
        <div style={{ padding: '20px', textAlign: 'center' }}>
          <Label>Something went wrong</Label>
          <Button
            icon={regenrateButtonIcon ? <LoadingOutlined /> : <ReloadOutlined />}
            style={{
              background: 'linear-gradient(90deg, rgba(108, 61, 240, 1) 0%, rgba(140, 103, 244, 1) 80%)',
              borderColor: 'transparent',
              color: 'white',
              marginBottom: '10px',
            }}
            onClick={regenerateSubtitles}
          >
            Generate Captions
          </Button>
        </div>
      ) : (
        <Row gutter={[0, 4]} style={{ flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <h3
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
                marginRight: '10px',
              }}
            >
              Captions
            </h3>
            <Switch defaultChecked={showSubtitles} value={showSubtitles} onChange={() => handleShowSubtitles()} />
          </div>
          <Label>Font</Label>
          <FontPickerRow
            defaultFont={selectedFont}
            onFontChange={updateFont}
            fontSize={fontSize}
            onFontSizeChange={updateFontSize}
          />
          <Label>Caption Style</Label>
          <ChooseFontTemplatesRow templates={FONT_TEMPLATE} onSelect={setFontTemplates} />
          <Label>Words Per Line</Label>
          <WordSlider wordsPerLine={wordsPerLine} onFinish={updateWordsPerLine} />
          <Label>Position</Label>
          <PositionSliderRow yAxis={yAxisPosition} setYAxis={updateSubtitleYAxis} maxYAxis={store.height} />
        </Row>
      )}
    </>
  )
})

const Label = ({ children }: { children: React.ReactNode }) => {
  return (
    <h3
      style={{
        color: 'black',
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: 'DM Sans',
        letterSpacing: 'normal',
        marginBottom: '10px',
      }}
    >
      {children}
    </h3>
  )
}

CaptionsPanel.displayName = 'CaptionsPanel'
