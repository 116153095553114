/* eslint-disable react/prop-types */
import {
  DownloadOutlined,
  FileGifOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  LoadingOutlined,
  PlayCircleOutlined,
  SaveOutlined,
  CameraOutlined,
} from '@ant-design/icons'
import { getDomainID, qaBackendApiInstance } from '../../../config/config'
import { VideoPlayer } from '@src/features/video/player/videoPlayer'
import { downloadMp4, millisecondsToFrames } from '@src/shared/utils/remotion'
import { Button, Dropdown, Menu, Modal, Result, message } from 'antd'
import { memo, useRef, useState } from 'react'
import Draggable from 'react-draggable'
import { useGlobalContext } from '../../../context/globalContext'
import axios from 'axios'
import './customActionControlContainer.less'
import { PreviewButton } from './previewContainer'
import { BulkCreateButton } from './bulkCreateContainer'
import { IS_FREE_PLATFORM, NODE_BACKEND_URL } from '../../../config'
import { ScriptButton } from './script/scriptPreviewButton'
import { trackEvent } from '@src/lib/mixpanel'
import { extractPagesFromHtml, addIdsToHtmlFromJson } from '@src/shared/utils/core'
import { burstImageTransitionFunctions } from '@src/data/transitions'

interface DownloadProps {
  store: any // Ideally, replace `any` with a more specific type
  open: boolean
  setOpen: any
  bulkCreatePropsArray: any
  setBulkCreatePropsArray: any
  previewOpen: boolean
  setPreviewOpen: any
  setProgressBarPercentage: any
}

export const CustomActionControlContainer: React.FC<DownloadProps> = ({
  store,
  open,
  setOpen,
  bulkCreatePropsArray,
  setBulkCreatePropsArray,
  previewOpen,
  setPreviewOpen,
  setProgressBarPercentage,
}) => {
  const {
    UserDetail: [userDetails],
    UpdatedHeadings: [heading],
    TransitionAnimation: [transitionAnimation],
    ScriptDetails: [scriptDetails],
    VideoStyle: [videoStyle],
  } = useGlobalContext()

  const User = JSON.parse(localStorage.getItem('userData') as string)

  // update and save as new button loading animation
  const [isUpdateLoading, setUpdateLoading] = useState<boolean>(false)
  const [isSaveAsNewLoading, setSaveAsNewLoading] = useState<boolean>(false)

  // menu states
  const [saveMenuVisible, setSaveMenuVisible] = useState(false)

  // save As new
  const [creationSuccess, setCreationSuccess] = useState(false)
  const [newProjectID, setNewProjectID] = useState('')

  // downloding mp4
  const [isMp4Downloding, setIsMp4Downloading] = useState<boolean>(false)

  // for handling the result screen
  const handleGoToNewProject = () => {
    window.location.href = `/${newProjectID}`
  }

  const handleStay = () => {
    setCreationSuccess(false) // Optionally reset or handle as needed
  }

  // for handling the state of save menu
  const handleMenuClick = (e: { domEvent: { stopPropagation: () => void } }) => {
    e.domEvent.stopPropagation() // Stop the menu from closing
  }

  // Download image
  const handleDownloadImage = async () => {
    const savePromises = store.pages.map((page: { id: string }) => store.saveAsImage({ pageId: page.id }))

    try {
      await Promise.all(savePromises)
      console.log('All pages saved as images.')
    } catch (error) {
      console.error('Error saving one or more pages as images:', error)
    }
  }

  // Download gif
  const handleDownloadGif = async () => {
    trackEvent('Free Video Ad Downloaded')
    await store.saveAsGIF()
  }

  const handleDownloadMp4 = async () => {
    trackEvent('Free Video Ad Downloaded')

    setIsMp4Downloading(true)

    store.pages.map((page: any) => {
      const elementID = 'captionText' + page.id
      if (store.getElementById(elementID)) {
        store.getElementById(elementID).set({
          opacity: 0,
        })
      }
    })

    const result = await store.toHTML()

    const htmlCode = extractPagesFromHtml(result)

    const jsonData = await store.toJSON()

    const htmlCodeWithTags = addIdsToHtmlFromJson(htmlCode, jsonData)

    const data = htmlCodeWithTags
    const duration = millisecondsToFrames(store.duration, 30)
    const transitionDurationInFrames = transitionAnimation[0].duration
    const transitionAnimationProp = transitionAnimation[0].animation

    const burstImagesTransitionAnimationArray = []

    if (videoStyle === 'Burst') {
      for (let i = 0; i < scriptDetails.data.length; i++) {
        const innerArray = []

        for (let j = 0; j < scriptDetails.data[i].images.length; j++) {
          const randomFunction =
            burstImageTransitionFunctions[Math.floor(Math.random() * burstImageTransitionFunctions.length)]
          innerArray.push(randomFunction.value)
        }
        burstImagesTransitionAnimationArray.push(innerArray)
      }
    }

    const isDownload = false

    store.pages.map((page: any) => {
      const elementID = 'captionText' + page.id
      if (store.getElementById(elementID)) {
        store.getElementById(elementID).set({
          opacity: 1,
        })
      }
    })

    message.loading(
      'Rendering video in background. Your download will begin once render is complete.',
      isMp4Downloding ? 0 : 5
    )

    axios
      .post(`${NODE_BACKEND_URL}api/v1/remotion/render`, {
        data,
        jsonData,
        duration,
        transitionDurationInFrames,
        transitionAnimationProp,
        isDownload,
        scriptDetails,
        burstImagesTransitionAnimationArray,
        videoStyle,
        isFree: IS_FREE_PLATFORM,
      })
      .then((response) => {
        const videoUrl = response.data // Assuming response.data contains the URL of the video

        // Fetch the video as a Blob
        fetch(videoUrl)
          .then((response) => response.blob())
          .then((blob) => {
            // Create a temporary URL for the Blob
            const url = window.URL.createObjectURL(blob)

            // Create a temporary anchor element
            const anchor = document.createElement('a')
            anchor.href = url
            anchor.download = 'video.mp4' // Set the filename for the download
            anchor.style.display = 'none' // Hide the anchor element

            // Append the anchor to the body
            document.body.appendChild(anchor)

            // Programmatically click the anchor to trigger the download
            anchor.click()

            // Clean up
            setTimeout(() => {
              document.body.removeChild(anchor) // Remove the anchor element
              window.URL.revokeObjectURL(url) // Release the temporary URL
              setIsMp4Downloading(false)
            }, 100) // Short delay to ensure the download process starts
          })
          .catch((error) => {
            console.error('Error downloading the video: ', error)
            message.error('Download Failed. Please try again')
            setIsMp4Downloading(false)
          })
      })
      .catch((error) => {
        console.error('Error rendering the video: ', error)
        setIsMp4Downloading(false)
      })
  }

  // update project api call put call
  async function updateProject(): Promise<any> {
    // trackEvent('Ad Saved')

    // const randomDelay = Math.floor(Math.random() * 21) + 5

    // setTimeout(() => {
    //   trackEvent('Free Video Ad Downloaded')
    // }, randomDelay * 1000)
    setUpdateLoading(true)
    try {
      const payload = userDetails
      payload.json_body = store.toJSON()

      payload.script = scriptDetails

      payload.first_render = false
      console.log(payload)
      const domainID = getDomainID() || ''
      const response = await qaBackendApiInstance.put(
        `/smp/${userDetails.id}?domain_id=${encodeURIComponent(domainID)}`,
        { ...payload }
      )
      if (response.status === 200) {
        message.success('Project updated successfully', 5)
        setUpdateLoading(false)
        // Handle successful upload, e.g., update state, display message, etc.
      } else {
        message.error('Project could not be updated', 5)
        // Handle non-200 responses
      }
    } catch (error) {
      console.error('Error updating project:', error)
      message.error('Project could not be updated.')
    }
  }

  // save as new api call post call
  const createNewProject = async (userDetails: any): Promise<any> => {
    trackEvent('Free Video Ad Generated')

    // const randomDelay = Math.floor(Math.random() * 21) + 15

    // setTimeout(() => {
    //   trackEvent('Ad Saved')
    // }, randomDelay * 1000)
    setSaveAsNewLoading(true)
    try {
      const payload = {
        ...userDetails,
        heading: heading.heading,
        subHeading: heading.subHeading,
        third_heading: heading.thirdHeading,
      }
      payload.json_body = store.toJSON()
      delete payload.id

      const response = await qaBackendApiInstance.post(
        `/smp/save-new-ad/${userDetails.id}?domain_id=${getDomainID()}`,
        payload
      )
      const blob = await store.toBlob({ mimeType: 'image/jpg' })
      const file = new File([blob], 'preview.jpg')
      const formData = new FormData()
      formData.append('file', file)

      await qaBackendApiInstance.post(
        `/smp/save-ad-image/${response.data.project_id}?domain_id=${getDomainID()}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      setNewProjectID(response.data.project_id)
      setCreationSuccess(true) // Indicate success to show the Result component
      setSaveAsNewLoading(false)
      setSaveMenuVisible(false)
    } catch (error) {
      console.error('Error uploading file:', error)
      message.error('Upload failed.')
      setSaveAsNewLoading(false)
    }
  }

  const downloadMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key='3' icon={<FileGifOutlined style={{ fontSize: '25px' }} />} onClick={handleDownloadGif}>
        Download as GIF
      </Menu.Item>
      <Menu.Item
        key='3'
        icon={
          isMp4Downloding ? (
            <LoadingOutlined style={{ fontSize: '25px' }} />
          ) : (
            <CameraOutlined style={{ fontSize: '25px' }} />
          )
        }
        onClick={handleDownloadMp4}
        disabled={isMp4Downloding}
      >
        Download as MP4
      </Menu.Item>
    </Menu>
  )

  // LoadingOutlined

  const saveMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item
        key='1'
        icon={
          isUpdateLoading ? (
            <LoadingOutlined style={{ fontSize: '25px' }} />
          ) : (
            <FileImageOutlined style={{ fontSize: '25px' }} />
          )
        }
        onClick={() => {
          updateProject()
        }}
      >
        Update
      </Menu.Item>
      <Menu.Item
        key='2'
        icon={
          isSaveAsNewLoading ? (
            <LoadingOutlined style={{ fontSize: '25px' }} />
          ) : (
            <FileImageOutlined style={{ fontSize: '25px' }} />
          )
        }
        onClick={() => {
          createNewProject(userDetails)
        }}
      >
        Save as new
      </Menu.Item>
    </Menu>
  )
  return (
    <>
      <div className={'custom-action-container'}>
        <PreviewButton store={store} previewOpen={previewOpen} setPreviewOpen={setPreviewOpen} />
        {IS_FREE_PLATFORM === 'false' && (
          <BulkCreateButton
            store={store}
            open={open}
            setOpen={setOpen}
            bulkCreatePropsArray={bulkCreatePropsArray}
            setBulkCreatePropsArray={setBulkCreatePropsArray}
          />
        )}
        <ScriptButton store={store} open={open} setOpen={setOpen} setProgressBarPercentage={setProgressBarPercentage} />
        {IS_FREE_PLATFORM === 'false' && (
          <Dropdown
            overlay={saveMenu}
            trigger={['click']}
            onVisibleChange={(flag) => setSaveMenuVisible(flag)}
            visible={saveMenuVisible}
          >
            <Button
              onClick={(e) => e.preventDefault()}
              icon={<SaveOutlined />}
              style={{ background: 'transparent', borderColor: '#6A39EE', marginBottom: '10px', color: '#6A39EE' }}
            >
              Save
            </Button>
          </Dropdown>
        )}
        <Dropdown overlay={downloadMenu} trigger={['click']}>
          <Button
            icon={<DownloadOutlined />}
            style={{
              background: 'linear-gradient(90deg, rgba(108, 61, 240, 1) 0%, rgba(140, 103, 244, 1) 80%)',
              borderColor: 'transparent',
              color: 'white',
              marginBottom: '10px',
            }}
          >
            Download
          </Button>
        </Dropdown>
        {creationSuccess && (
          <div
            style={{
              position: 'fixed', // Or 'absolute'
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Overlay effect
              zIndex: 1000, // Ensure it's above other content
            }}
          >
            <div
              style={{
                padding: '20px',
                maxWidth: '600px',
                width: '90%',
                background: '#fff',
                borderRadius: '8px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Result
                status='success'
                title='Successfully Created New Project!'
                subTitle={`Project ID: ${newProjectID}. You can now navigate to the new project or stay on this page.`}
                extra={[
                  <Button type='primary' key='goNewProject' onClick={handleGoToNewProject}>
                    Go to New Project
                  </Button>,
                  <Button key='stay' onClick={handleStay}>
                    Stay on This Page
                  </Button>,
                ]}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}
