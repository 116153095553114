import { IS_FREE_PLATFORM, REDIRECT_ON } from '@src/config'
import { FONT_TEMPLATE } from '@src/data/captions'
import { defaultJsonTemplate } from '@src/data/defaultJsonTemplate'
import { logo } from '@src/data/logo'
import { categorisedMusicList } from '@src/data/music'
import { generateSubtitles } from '@src/features/rightSidebar/captions/captionsPanel'
import { getImageSize } from 'polotno/utils/image'
import { prefetch } from 'remotion'
import { generateUniqueId, fitTextwithBreaks } from '../core'
import { generateAiAudio } from './audioGeneration'
import { getPexelsVideos, getGettyVideos, getGettyImages } from './mediaSearch'
import { animationFunc } from './animations'

export async function ScriptToCanvas(
  store: any,
  scriptData: any,
  industry: any,
  setBackgroundMusic: any,
  setScriptDetails: any,
  setProgressBarPercent: any,
  icon: string,
  selectedSize: string
) {
  // Load default Template if free platform
  if (IS_FREE_PLATFORM === 'true') {
    store.loadJSON(defaultJsonTemplate)
  }

  // Set default durations to 3.5 seconds
  store.pages.map((page: any) => {
    page.set({ duration: 3500 })
  })

  const json = await store.toJSON()
  const scriptPages = scriptData.data

  // Make sure there are enough pages
  for (let i = json.pages.length; i < scriptPages.length; i++) {
    store.pages[i % 2].clone()
  }

  const totalIterations = store.pages.length * 2.25 // Assuming each page has 1 iteration for video and 1 for audio generation
  const progressIncrement = 100 / totalIterations

  let currentProgress = 0

  setProgressBarPercent(Math.round(currentProgress))

  for (let index = 0; index < scriptData.data.length; index++) {
    const currentLength = store.pages.length
    if (!REDIRECT_ON) {
      await generateAiAudio(
        scriptPages[index],
        store,
        index,
        setScriptDetails,
        setProgressBarPercent,
        currentProgress,
        progressIncrement,
        currentLength,
        industry,
        'Simple'
      )
    }
    const children = store.pages[index].children
    let searchIndex = 0

    let searchQuery = scriptPages[index].searchQueries[0]

    let orientations = selectedSize === '9:16' ? 'vertical' : 'horizontal'

    let videoElementID = ''

    let passed = false
    const videoElements = children.filter((child: any) =>
      IS_FREE_PLATFORM === 'true' ? child.type === 'image' : child.type === 'video'
    )
    if (videoElements.length > 0) {
      const largestVideo = videoElements.reduce(
        (prev: any, current: any) => (prev.width * prev.height > current.width * current.height ? prev : current),
        videoElements[0]
      )
      videoElementID = largestVideo.id
    }

    let searchResult = undefined

    if (videoElementID !== '') {
      if (IS_FREE_PLATFORM === 'false') {
        if (scriptPages[index].videoURL && scriptPages[index].videoURL.trim() !== '') {
          store.getElementById(videoElementID).set({
            src: scriptPages[index].videoURL,
          })
        } else {
          while (!searchResult && searchIndex < scriptPages[index].searchQueries.length) {
            if (searchIndex === scriptPages[index].searchQueries.length - 1) {
              searchIndex = 0
              orientations = orientations === 'vertical' ? 'horizontal' : 'vertical'
              if (passed) {
                let pexelsVideoURL = undefined
                while (!pexelsVideoURL && searchIndex < scriptPages[index].searchQueries.length) {
                  const pexelsVideoRes = await getPexelsVideos(scriptPages[index].searchQueries[0])
                  if (pexelsVideoRes != -1) {
                    pexelsVideoURL = pexelsVideoRes
                    store.getElementById(videoElementID).set({
                      src: pexelsVideoURL,
                    })
                    break
                  }
                }
                break
              }
              passed = true
            }
            const gettyVideoURL: any = await getGettyVideos(searchQuery, orientations)
            if (gettyVideoURL === -1) {
              searchIndex++
              searchQuery = scriptPages[index].searchQueries[searchIndex]
            } else {
              searchResult = gettyVideoURL
              store.getElementById(videoElementID).set({
                src: gettyVideoURL,
              })
            }
          }
        }
      } else {
        while (!searchResult && searchIndex < scriptPages[index].searchQueries.length) {
          const gettyImageUrl: any = await getGettyImages(searchQuery, orientations)
          if (gettyImageUrl === -1) {
            searchIndex++
            searchQuery = scriptPages[index].searchQueries[searchIndex]
          } else {
            const imageId = generateUniqueId()
            searchResult = gettyImageUrl
            store.pages[index].addElement({
              type: 'image',
              src: gettyImageUrl,
              x: -25,
              y: -25,
              width: store.width + 50,
              height: store.height + 50,
              id: imageId,
            })
            store.deleteElements([videoElementID])
            store.getElementById(imageId).set({
              animations: animationFunc(store.pages[index].duration),
            })
            store.getElementById(imageId).moveBottom()
          }
        }
      }
      store.pages[index].set({
        custom: {
          ...store.pages[index].custom,
          searchQuery: searchQuery,
          searchQueries: scriptPages[index].searchQueries,
        },
      })
    }

    currentProgress += progressIncrement
    setProgressBarPercent(Math.round(currentProgress))

    const textElements = children.filter((child: any) => child.type === 'text')

    const sortedTextElementsByFont = textElements
      .filter(() => {
        return true
      })
      .sort((a: any, b: any) => b.fontSize - a.fontSize)
    if (sortedTextElementsByFont.length > 0) {
      const element = store.getElementById(sortedTextElementsByFont[0].id)
      const fontSize = fitTextwithBreaks(
        element.width,
        element.height,
        element.text,
        element.fontFamily,
        element.letterSpacing,
        element.lineHeight,
        element.fontSize
      ) // Calculate the new font size
      await element.set({
        text: scriptPages[index].textOverlay,
        fontSize: fontSize,
      })
    }

    currentProgress += progressIncrement
    setProgressBarPercent(Math.round(currentProgress))

    // const imageUrl = URL.createObjectURL(icon)

    if (IS_FREE_PLATFORM === 'false') {
      const { waitUntilDone } = prefetch(icon, {
        method: 'blob-url',
        contentType: 'image/png',
      })
      await waitUntilDone()

      if (icon) {
        let { width, height } = await getImageSize(icon)

        const scaleFactor = selectedSize === '9:16' ? 0.4 : 0.2
        let scale = Math.min(store.width / width, store.height / height, scaleFactor)

        // Limit width to 250 pixels
        if (width * scale > 100) {
          scale *= 100 / (width * scale) // Adjust scale to fit within 250 width
        }

        // Ensure height is scaled proportionally
        height *= scale
        width *= scale

        store.pages[index].addElement({
          ...logo,
          id: 'quickadsLogo' + index.toString(),
          src: `${icon}`,
          x: store.width - width - 25,
          width: width,
          height: height,
        })
        const element = store.getElementById('quickadsLogo' + index.toString())
        element.set({ x: store.width - width - 25 })
      }
    }
  }

  const duration = 60
  currentProgress += progressIncrement
  setProgressBarPercent(Math.round(currentProgress))

  let musicUrl = ''
  let musicName = ''

  if (categorisedMusicList[industry] && categorisedMusicList[industry][duration]) {
    const firstTypeKey = Object.keys(categorisedMusicList[industry][duration])[0]
    musicUrl = categorisedMusicList[industry][duration][firstTypeKey][0].url
    musicName = categorisedMusicList[industry][duration][firstTypeKey][0].name
  }

  const backgroundScript = {
    url: musicUrl,
    duration: 30,
    industry: industry,
    name: musicName,
    backgroundVolume: 0.2,
  }

  // const backgroundAudioDuration = await getAudioDurationInSeconds(musicUrl)
  setBackgroundMusic(backgroundScript)

  const randomTemplate = Math.floor(Math.random() * FONT_TEMPLATE.length)

  // Generate subtitles by default if free version
  IS_FREE_PLATFORM === 'true' &&
    generateSubtitles(
      'Bangers',
      1,
      40,
      store,
      1,
      FONT_TEMPLATE[randomTemplate],
      true,
      store.height - store.height * 0.4,
      () => {
        true
      },
      setScriptDetails
    )

  await setScriptDetails((prevScript: { data: any }) => {
    const updatedScript = { ...prevScript }

    return { ...updatedScript, backgroundMusic: backgroundScript, aiVoiceVolume: 1 }
  })

  store.selectPage(store.pages[0].id)
}
