import { CheckSquareOutlined, PlusSquareOutlined } from '@ant-design/icons'
import { orientationList } from '@src/data/orientation'
import { generateUniqueId } from '@src/shared/utils/core'
import { Button, Flex, Result, Select, message } from 'antd'
import { getImageSize } from 'polotno/utils/image'
import { getVideoSize } from 'polotno/utils/video'
import { useState } from 'react'

type videoSearchProps = {
  title: string
  transformedElementsArray: any
  isLoading: boolean
  store: any
  elementID?: string
  isGettyImage?: boolean
  dropDownValue?: string
  setSelectedValue?: any
  isNotPanel?: boolean
  index?: number
}

const { Option } = Select

// Assuming you want to use the Remotion Player for previewing the video
export const VideoSearch = ({
  transformedElementsArray,
  isLoading,
  store,
  title,
  elementID,
  isGettyImage,
  dropDownValue,
  setSelectedValue,
  isNotPanel,
  index,
}: videoSearchProps) => {
  const [visible, setVisible] = useState(false)
  const [video, setVideo] = useState({})

  const handleOrientationChange = (value: string) => {
    setSelectedValue(value)
  }

  return (
    <>
      <div style={{ overflowY: 'auto', height: elementID ? 'calc(60vh - 100px)' : 'calc(90vh - 100px)' }}>
        <Flex vertical justify='space-around' style={{ gap: '10px' }}>
          {isGettyImage && (
            <Select
              style={{ width: '50%', border: '2px solid rgb(235, 233, 254)', borderRadius: '5px' }}
              placeholder='Select Orientation'
              optionFilterProp='children'
              onChange={handleOrientationChange}
              value={dropDownValue}
              dropdownRender={(menu) => <div>{menu}</div>}
            >
              {orientationList.map((data, index) => (
                <Option key={index} value={data.value}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {data.value}
                  </div>
                </Option>
              ))}
            </Select>
          )}
          {(transformedElementsArray?.length === 0 || !transformedElementsArray) && !isLoading && (
            <p>Could not Find any Videos / Images. Please Change your search query</p>
          )}
          <h2
            style={{
              color: '#6938ef',
              fontSize: '16px',
              fontWeight: 700,
              letterSpacing: 'normal',
              marginBottom: '10px',
            }}
          >
            {title}
          </h2>
          {transformedElementsArray && transformedElementsArray.length > 0 && (
            <Flex wrap='wrap' gap='small'>
              {transformedElementsArray.map((item: any) => {
                return (
                  <div style={{ width: '45%', position: 'relative' }} key={item.id}>
                    {item.type === 'image' ? (
                      <img
                        src={item.image_url}
                        alt='video'
                        style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                        height='auto'
                        width='100%'
                      />
                    ) : (
                      <video
                        key={item.id}
                        height='auto'
                        width='100%'
                        src={item.video_url}
                        style={{ objectFit: 'contain' }}
                        controls
                      />
                    )}
                    {isNotPanel === true && (
                      <CheckSquareOutlined
                        style={{
                          color: '#6938ef',
                          background: 'white',
                          marginLeft: '8px',
                          marginRight: '5px',
                          fontSize: '20px',
                          position: 'absolute',
                          padding: '10px',
                          borderRadius: '50%',
                          top: 0,
                          right: 0,
                          margin: '8px', // Adjust margin as per your design
                          transition: 'filter 0.3s ease', // Add transition for smooth effect
                        }}
                        title='Change Video'
                        onClick={async () => {
                          setVideo(item)
                          if (elementID) {
                            setVisible(true)
                            return
                          }
                          const video = item.video_url
                          const type = item.type

                          let { width, height } = await getVideoSize(video)

                          const scale = Math.min(store.width / width, store.height / height, 1)
                          width = width * scale
                          height = height * scale
                        }}
                      />
                    )}
                    {!isNotPanel && (
                      <PlusSquareOutlined
                        style={{
                          color: '#6938ef',
                          background: 'white',
                          marginLeft: '8px',
                          marginRight: '5px',
                          fontSize: '20px',
                          position: 'absolute',
                          padding: '10px',
                          borderRadius: '50%',
                          top: 0,
                          right: 0,
                          margin: '8px', // Adjust margin as per your design
                          transition: 'filter 0.3s ease', // Add transition for smooth effect
                        }}
                        title='Change Video'
                        onClick={async () => {
                          const video = item.video_url
                          const type = item.type

                          let { width, height } = await (type === 'image' ? getImageSize(video) : getVideoSize(video))

                          const scale = Math.min(store.width / width, store.height / height, 1)
                          width = width * scale
                          height = height * scale
                          store.activePage.addElement({
                            type: 'image',
                            src: item.video_url,
                            width: width,
                            height: height,
                            id: generateUniqueId(),
                          })
                        }}
                      />
                    )}
                  </div>
                )
              })}
            </Flex>
          )}
        </Flex>
      </div>
      {visible && (
        <>
          <div
            style={{
              background: 'rgba(0, 0, 0, 0.5)',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 999,
            }}
          ></div>
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              translate: 'all 1s ease',
              zIndex: 1001,
            }}
          >
            <ResultModal visible={visible} setVisible={setVisible} video={video} store={store} elementID={elementID} />
          </div>
        </>
      )}
    </>
  )
}

export const ResultModal = ({ visible, setVisible, video, store, elementID }: any) => {
  const handleReplace = () => {
    const element = store.getElementById(elementID)
    if (video.type === 'image') {
      element.set({
        src: video.video_url,
        type: 'image',
      })
      setVisible(false)
      return
    }
    if (!video.video_url) {
      message.error('Video not found')
      setVisible(false)

      return
    }
    element.set({
      src: video.video_url,
      type: 'video',
    })
    setVisible(false)
  }

  const handleStay = () => {
    setVisible(false)
  }

  return (
    <div
      style={{
        padding: '20px',
        maxWidth: '300px',
        width: '90%',
        background: '#fff',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        zIndex: 1001,
        translate: 'enter fade 1s ease',
      }}
    >
      <Result
        status='info'
        subTitle='Are you sure you want to replace the current visual?'
        style={{ color: '#6938ef', fontSize: '16px', fontWeight: 500, letterSpacing: 'normal', marginBottom: '10px' }}
        extra={[
          <Button
            style={{ minWidth: '120px', marginBottom: '10px' }}
            type='primary'
            key='goNewProject'
            onClick={handleReplace}
          >
            Replace
          </Button>,
          <Button style={{ minWidth: '120px' }} key='stay' onClick={handleStay}>
            Exit
          </Button>,
        ]}
      />
    </div>
  )
}
