import { IS_FREE_PLATFORM } from '@src/config'
import { generateUniqueId, fitTextwithBreaks } from '../core'
import { animationFunc } from './animations'
import { generateRegeneratedScripAiAudio } from './audioGeneration'
import { getPexelsVideos, getGettyVideos, getGettyImages } from './mediaSearch'

export async function RegeneratedScriptToCanvas(
  store: any,
  regeneratedScript: any,
  indexArray: any,
  setScriptDetails: any,
  selectedSize: string,
  setProgressBarPercentage: any
) {
  const totalIterations = store.pages.length * 2.25 // Assuming each page has 1 iteration for video and 1 for audio generation
  const progressIncrement = 100 / totalIterations

  let currentProgress = 0

  setProgressBarPercentage(0)

  setProgressBarPercentage(Math.round(currentProgress))

  for (let index = 0; index < indexArray.length; index++) {
    // Only regenerate audio if the voice over is updated
    if (regeneratedScript[index].isUpdated.isVoiceOverUpdated === true) {
      await generateRegeneratedScripAiAudio(
        regeneratedScript[index],
        store,
        indexArray[index],
        setScriptDetails,
        setProgressBarPercentage,
        currentProgress,
        progressIncrement
      )
    }

    store.pages[indexArray[index]].set({ duration: 3500 })
    const children = store.pages[indexArray[index]].children

    // Only regenerate video if the screen visual is updated
    if (regeneratedScript[index].isUpdated.isScreenVisualUpdated === true) {
      let searchIndex = 0

      let searchQuery = regeneratedScript[index].searchQueries[0]

      let searchResult = undefined

      let orientations = selectedSize === '9:16' ? 'vertical' : 'horizontal'

      let videoElementID = ''

      let passed = false
      const videoElements = children.filter((child: any) =>
        IS_FREE_PLATFORM === 'true' ? child.type === 'image' : child.type === 'video'
      )
      if (videoElements.length > 0) {
        const largestVideo = videoElements.reduce(
          (prev: any, current: any) => (prev.width * prev.height > current.width * current.height ? prev : current),
          videoElements[0]
        )
        videoElementID = largestVideo.id
      }

      if (videoElementID !== '') {
        if (IS_FREE_PLATFORM === 'false') {
          while (!searchResult && searchIndex < regeneratedScript[index].searchQueries.length) {
            if (searchIndex === regeneratedScript[index].searchQueries.length - 1) {
              searchIndex = 0
              orientations = orientations === 'vertical' ? 'horizontal' : 'vertical'
              if (passed) {
                let pexelsVideoURL = undefined
                while (!pexelsVideoURL && searchIndex < regeneratedScript[index].searchQueries.length) {
                  const pexelsVideoRes = await getPexelsVideos(regeneratedScript[index].searchQueries[0])
                  if (pexelsVideoRes != -1) {
                    pexelsVideoURL = pexelsVideoRes
                    store.getElementById(videoElementID).set({
                      src: pexelsVideoURL,
                    })
                    break
                  }
                }
                break
              }
              passed = true
            }
            const gettyVideoURL: any = await getGettyVideos(searchQuery, orientations)
            if (gettyVideoURL === -1) {
              searchIndex++
              searchQuery = regeneratedScript[index].searchQueries[searchIndex]
            } else {
              searchResult = gettyVideoURL
              store.getElementById(videoElementID).set({
                src: gettyVideoURL,
              })
            }
          }
        } else {
          while (!searchResult && searchIndex < regeneratedScript[index].searchQueries.length) {
            const gettyImageUrl: any = await getGettyImages(searchQuery, orientations)
            if (gettyImageUrl === -1) {
              searchIndex++
              searchQuery = regeneratedScript[index].searchQueries[searchIndex]
            } else {
              const imageId = generateUniqueId()
              searchResult = gettyImageUrl
              store.pages[index].addElement({
                type: 'image',
                src: gettyImageUrl,
                x: -25,
                y: -25,
                width: store.width + 50,
                height: store.height + 50,
                id: imageId,
                // animations:animationFunc(),
              })
              store.deleteElements([videoElementID])
              store.getElementById(imageId).set({
                animations: animationFunc(store.pages[index].duration),
              })
              store.getElementById(imageId).moveBottom()
            }
          }
        }
        store.pages[index].set({
          custom: {
            ...store.pages[index].custom,
            searchQuery: searchQuery,
          },
        })
      }

      currentProgress += progressIncrement
      setProgressBarPercentage(Math.round(currentProgress))

      setScriptDetails((prevScript: { data: any }) => {
        const updatedScript = { ...prevScript }
        const updatedData = [...prevScript.data]

        updatedData[indexArray[index]] = {
          ...updatedData[indexArray[index]],
          onScreenVisual: regeneratedScript[index].onScreenVisual,
          searchQueries: regeneratedScript[index].searchQueries,
        }
        delete updatedData[indexArray[index]].isUpdated
        return { ...updatedScript, data: updatedData }
      })
    }

    // Only regenerate text overlay if the text overlay is updated
    if (regeneratedScript[index].isUpdated.isTextOverlayUpdated === true) {
      const textElements = children.filter((child: any) => child.type === 'text')

      const sortedTextElementsByFont = textElements
        .filter((text: any) => {
          return true
        })
        .sort((a: any, b: any) => b.fontSize - a.fontSize)

      if (sortedTextElementsByFont.length > 0) {
        const element = store.getElementById(sortedTextElementsByFont[0]?.id)
        const oldHeight = element.height
        const originalText = element.text
        const newText = regeneratedScript[index].textOverlay
        const originalTextLength = originalText.length
        const newTextLength = newText.length

        // Calculate the ratio of change in text length
        const lengthRatio = newTextLength / originalTextLength

        // Calculate the scale factor for font size
        const scaleFactor = 1 / lengthRatio

        const fontSize = fitTextwithBreaks(
          element.width,
          element.height,
          element.text,
          element.fontFamily,
          element.letterSpacing,
          element.lineHeight,
          element.fontSize
        ) // Calculate the new font size
        await element.set({
          text: regeneratedScript[index].textOverlay,
          fontSize: fontSize,
        })
      }

      currentProgress += progressIncrement
      setProgressBarPercentage(Math.round(currentProgress))

      setScriptDetails((prevScript: { data: any }) => {
        const updatedScript = { ...prevScript }
        const updatedData = [...prevScript.data]

        updatedData[indexArray[index]] = {
          ...updatedData[indexArray[index]],
          textOverlay: regeneratedScript[index].textOverlay,
        }
        delete updatedData[indexArray[index]].isUpdated
        return { ...updatedScript, data: updatedData }
      })
    }
  }
}
