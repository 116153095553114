/* eslint-disable react/prop-types */
import { LoadingOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { VideoPlayer } from '@src/features/video/player/videoPlayer'
import { millisecondsToFrames, prefetchAsset } from '@src/shared/utils/remotion'
import { Button, Modal } from 'antd'
import { useRef, useState } from 'react'
import Draggable from 'react-draggable'
import { prefetch } from 'remotion'
import { useGlobalContext } from '../../../context/globalContext'
import './customActionControlContainer.less'
import { addIdsToHtmlFromJson, extractPagesFromHtml } from '@src/shared/utils/core'
import { burstImageTransitionFunctions } from '@src/data/transitions'

interface VideoPreviewProps {
  store: any // Ideally, replace `any` with a more specific type
  previewOpen: boolean
  setPreviewOpen: (val: boolean) => void
}

export const PreviewButton: React.FC<VideoPreviewProps> = ({ store, previewOpen, setPreviewOpen }) => {
  const [open, setOpen] = useState(false)
  const draggleRef = useRef<HTMLDivElement>(null)
  const {
    BackgroundMusic: [backgroundMusic, setBackgroundMusic],
    TransitionAnimation: [transitionAnimation],
    ScriptDetails: [scriptDetails, setScriptDetails],
    IsLoading: [isLoading, setIsLoading],
    VideoStyle: [videoStyle, setVideoStyle],
  } = useGlobalContext()

  // loading for when gif is rendering
  const [isGifRendering, setIsGifRendering] = useState<boolean>(false)

  const showModal = () => {
    setOpen(true)
  }

  const handleOk = () => {
    setOpen(false)
    setPreviewOpen(false)
    store.pages.map((page: any) => {
      if (page.custom.showSubtitles === true) {
        const elementID = 'captionText' + page.id
        if (store.getElementById(elementID)) {
          store.getElementById(elementID).set({
            opacity: 1,
          })
        }
      }
    })
  }

  const handleCancel = () => {
    setOpen(false)
    setPreviewOpen(false)
    store.pages.map((page: any) => {
      if (page.custom.showSubtitles === true) {
        const elementID = 'captionText' + page.id
        if (store.getElementById(elementID)) {
          store.getElementById(elementID).set({
            opacity: 1,
          })
        }
      }
    })
  }

  const [width, setWidth] = useState<number>(500)
  const [height, setHeight] = useState<number>(600)
  const [duration, setDuration] = useState<number>(1)
  const [htmlCode, setHtmlCode] = useState([])
  const [burstImageAnimations, setBurstImageAnimations] = useState<any>([])

  const changeHtmlCode = (newHtmlCode: any) => {
    setHtmlCode(newHtmlCode)
  }

  const changeDuration = (newDuration: number) => {
    setDuration(newDuration)
  }

  const changeWidth = (newWidth: any) => {
    setWidth(newWidth)
  }

  const changeHeight = (newHeight: any) => {
    setHeight(newHeight)
  }

  const modalTitle = (
    <div
      style={{ width: '100%', cursor: 'move' }}
      className='draggable-modal-title' // This class will be used as a handle
    >
      Video Preview
    </div>
  )

  const handlePreviewClick = async () => {
    setIsGifRendering(true)

    const jsonData = store.toJSON()

    const burstImagesTransitionAnimationArray = []

    if (videoStyle === 'Burst') {
      for (let i = 0; i < scriptDetails.data.length; i++) {
        const innerArray = []

        for (let j = 0; j < scriptDetails.data[i].images.length; j++) {
          const randomFunction =
            burstImageTransitionFunctions[Math.floor(Math.random() * burstImageTransitionFunctions.length)]
          innerArray.push(randomFunction.value)
        }
        burstImagesTransitionAnimationArray.push(innerArray)
      }

      setBurstImageAnimations(burstImagesTransitionAnimationArray)
    }

    await Promise.all(
      jsonData.pages.map(async (page: any) => {
        await prefetchAsset(page?.custom?.sounds[0]?.url)

        // Prefetch for all children in parallel
        await Promise.all(
          page.children.map((child: any) =>
            child.type === 'video' && child.src ? prefetchAsset(child.src) : Promise.resolve()
          )
        )
      })
    )

    await prefetchAsset(scriptDetails?.backgroundMusic?.url)

    changeDuration(millisecondsToFrames(store.duration, 30))
    store.pages.map((page: any) => {
      const elementID = 'captionText' + page.id
      if (store.getElementById(elementID)) {
        store.getElementById(elementID).set({
          opacity: 0,
        })
      }
    })

    const result = await store.toHTML()

    const htmlCode = extractPagesFromHtml(result)

    const htmlCodeWithTags = addIdsToHtmlFromJson(htmlCode, jsonData)

    changeHtmlCode(htmlCodeWithTags)
    changeWidth(store.width)
    changeHeight(store.height)
    showModal()
    setIsGifRendering(false)
  }

  return (
    <>
      <Button
        onClick={async () => {
          handlePreviewClick()
        }}
        style={{
          background: 'transparent',
          borderColor: '#6A39EE',
          marginBottom: '10px',
          color: '#6A39EE',
        }}
        icon={isGifRendering ? <LoadingOutlined /> : <PlayCircleOutlined />}
      >
        Preview
      </Button>
      <Modal
        title={modalTitle}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        width={width} // Set the width of the Modal to match the GifPlayer's width
        style={{
          top: '50%',
          transform: 'translateY(-50%)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        modalRender={(modal) => (
          <Draggable nodeRef={draggleRef} bounds='parent' handle='.draggable-modal-title'>
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        destroyOnClose={true}
      >
        <VideoPlayer
          htmlData={htmlCode}
          duration={duration}
          scriptDetails={scriptDetails}
          jsonData={store.toJSON()}
          transitionDurationInFrames={transitionAnimation[0].duration}
          transitionAnimationProp={transitionAnimation[0].animation}
          isDownload={false}
          burstImagesTransitionAnimationArray={burstImageAnimations}
          videoStyle={videoStyle}
        />
      </Modal>
    </>
  )
}
