import { LoadingOutlined } from '@ant-design/icons';
import { backgroundCover, scrptingGif, analysingGif, ScenesGif } from '@assets';

import { useState, useEffect } from 'react';
const antIcon = (
  <LoadingOutlined style={{ fontSize: 80, color: '#6C37E3' }} spin />
);
import {motion, AnimatePresence} from 'framer-motion'
import { Progress } from 'antd';

interface IphoneCaseProps {
  percentage: number;
//   direction?: 'right' | 'left' | 'top' | 'bottom'; // Optional direction
}

const images = [
  { id: 1, src: analysingGif, alt: 'Image 1', step: 'Step 1: Analysing' },
  { id: 2, src: scrptingGif, alt: 'Image 1', step: 'Step 2: Scripting' },
  { id: 3, src: ScenesGif, alt: 'Image 1', step: 'Step 3: Creating Scenes' },
  { id: 4, src: analysingGif, alt: 'Image 1', step: 'Step 4: Searching Sounds' },
  { id: 5, src: ScenesGif, alt: 'Image 1', step: 'Step 5: Animating' },
  { id: 6, src: scrptingGif, alt: 'Image 1', step: 'Step 6: Rendering' },
  { id: 6, src: ScenesGif, alt: 'Image 1', step: 'Step 7: Finalizing' },
];
export const Loading = ({ percentage }: IphoneCaseProps) => {
  // const [currentTextIndex, setCurrentTextIndex] = useState(0);
  // const [percentage, setpercentage] = useState(0);
  const currentStepIndex = Math.floor((percentage / 100) * images.length);
  const [dots, setDots] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((prevDots) => (prevDots < 3 ? prevDots + 1 : 0));
      // setpercentage((prevPer) => (prevPer < 90 ? prevPer + 8 : 0));
    }, 400);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  const containerVariants = {
    initial: {
      x: 100, // Start position outside the container on the left
      opacity:0.5,
      rotate: -90
    },
    animate: {
      x: 0, // Move to the center of the container
      opacity: 1,
      rotate: 0,
    },
    exit: {
      x: -70, // Move to the right inside the container
      opacity: 0,
      rotate: 90,
    },
  };
 
  return (
    <div
 
    style={{
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      zIndex: 1000,
      background:'#ffff'
    }}
  >
 
    <div className='phone-container' style={{background: `url(${backgroundCover})`}}>
      <div className='iphone-case case-1'>
        <div className='child-container'>
          <AnimatePresence mode='wait'>
            <motion.img
              key={currentStepIndex} // Unique key for each image
              src={images[currentStepIndex]?.src}
              alt={images[currentStepIndex]?.alt}
              variants={containerVariants}
              animate={'animate'} // Animate to left side with full opacity
              // exit={{ opacity: 0 }}
              initial='initial'
              transition={{ duration: 1 }}
              exit='exit'
              // layout='position: relative' // Ensure animation within parent div
            />
          </AnimatePresence>
        </div>
        <div>
          <p style={{fontSize:'18px', fontWeight:'bold', textAlign:'center', color: '#6837ED'} }>{images[currentStepIndex]?.step}</p>
        </div>
        <div className='progress-bar_'>
          <div>
          <Progress
          percent={percentage}
          status='active'
          strokeColor={{ from: '#6837ED', to: '#7835BD' }}
          style={{ marginTop: '20px', width: '200px' }}
          trailColor={'#fff'}
        /> 
          </div>
        </div>
      </div>
    </div>
   </div>
  );
}
// export default Loading;
