export const bultCreateArray = [
  {
    id: 'YouTube Long Form',
    value: '16:9',
    width: 2000,
    height: 1125,
  },
  {
    id: 'Instagram Post',
    value: '1:1',
    width: 1000,
    height: 1000,
  },
  {
    id: 'Instagram Ad',
    value: '1:1',
    width: 1000,
    height: 1000,
  },
  {
    id: 'Linkdeln',
    value: '1:1',
    width: 1000,
    height: 1000,
  },
  {
    id: 'Twitter',
    value: '1:1',
    width: 1000,
    height: 1000,
  },
  {
    id: 'TikTok',
    value: '9:16',
    width: 1125,
    height: 2000,
  },
  {
    id: 'Instagram Story',
    value: '9:16',
    width: 1125,
    height: 2000,
  },
  {
    id: 'YouTube Short',
    value: '9:16',
    width: 1125,
    height: 2000,
  },
]
