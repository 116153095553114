import { getImageSize } from 'polotno/utils/image'
import { prefetch } from 'remotion'
import { IS_FREE_PLATFORM, REDIRECT_ON } from '../../../config'
import { categorisedMusicList } from '../../../data/music'
import { generateSubtitles } from '../../../features/rightSidebar/captions/captionsPanel'
import { generateUniqueId, fitTextwithBreaks } from '../core'
import { animationFunc } from './animations'
import { getPexelsVideos, getGettyVideos, getGettyImages } from './mediaSearch'
import { defaultJsonTemplate } from '@src/data/defaultJsonTemplate'
import { logo } from '@src/data/logo'
import { FONT_TEMPLATE } from '@src/data/captions'
import { generateAiAudio } from './audioGeneration'

export async function ScriptToCanvasBurst(
  store: any,
  scriptData: any,
  industry: any,
  setBackgroundMusic: any,
  setScriptDetails: any,
  setProgressBarPercent: any,
  icon: string,
  selectedSize: string,
  videoStyle: string
) {
  // Load default Template if free platform
  if (IS_FREE_PLATFORM === 'true') {
    store.loadJSON(defaultJsonTemplate)
  }

  // Set default durations to 3.5 seconds
  store.pages.map((page: any) => {
    page.set({ duration: 3500 })
  })

  const scriptPages = scriptData.data

  let totalImageLength = 0

  // Calculate the total length of images across all objects
  for (const pages of scriptPages) {
    totalImageLength += pages.images.length
  }
  // Clone the store pages based on the total image length
  for (let i = store.pages.length; i < totalImageLength; i++) {
    store.pages[i % 2].clone()
  }

  const totalIterations = store.pages.length * 2.25 // Assuming each page has 1 iteration for video and 1 for audio generation
  const progressIncrement = 100 / totalIterations

  let currentProgress = 0

  setProgressBarPercent(Math.round(currentProgress))

  let scriptIndex = 0

  // Loop all pages
  for (let index = 0; index < store.pages.length; index++) {
    // Generate All Audio
    const fullPageDuration = await generateAiAudio(
      scriptPages[scriptIndex],
      store,
      scriptIndex,
      setScriptDetails,
      setProgressBarPercent,
      currentProgress,
      progressIncrement,
      scriptPages.length,
      industry,
      videoStyle
    )

    const scriptPage = scriptPages[scriptIndex]

    const pageDuration = fullPageDuration / scriptPage.images.length

    // Loop all images in a script block
    for (let j = 0; j < scriptPage.images.length; j++) {
      // Get all children of the page
      const children = store.pages[index].children

      // Get Image object from script
      const pageImage = scriptPage.images[j]

      // Get the first search query set searchIndex for parsing later for the image
      let searchIndex = 0
      let searchQuery = pageImage.searchQueries[searchIndex]

      // Set Initial Orientation based on size input
      let orientations = selectedSize === '9:16' ? 'vertical' : 'horizontal'

      // Set Initial VideoElementID to empty string
      let mediaElementID = ''

      // Set passed to false to determine if all search queries are exhausted on getty and we need to move to Pexels
      let passed = false

      // Find the largest mediaEkement
      const videoElements = children.filter((child: any) =>
        IS_FREE_PLATFORM === 'true' ? child.type === 'image' : child.type === 'video'
      )
      if (videoElements.length > 0) {
        const largestVideo = videoElements.reduce(
          (prev: any, current: any) => (prev.width * prev.height > current.width * current.height ? prev : current),
          videoElements[0]
        )
        mediaElementID = largestVideo.id
      }

      // Initialize undefined search result for media
      let searchResult = undefined

      // Only replace media if we have an mediaElementID
      if (mediaElementID !== '') {
        // Run logic to Replace with videos if it is not the free platform
        if (IS_FREE_PLATFORM === 'false') {
          if (pageImage.images.videoURL && pageImage.videoURL.trim() !== '') {
            store.getElementById(mediaElementID).set({
              src: pageImage.videoURL,
            })
          } else {
            // While loop to search for videos on Getty and Pexels for all search queries until we find one result
            while (!searchResult && searchIndex < pageImage.searchQueries.length) {
              // If all search queries are exhausted and we still have no search Result, change the orientation and try on Getty again
              if (searchIndex === pageImage.searchQueries.length - 1) {
                searchIndex = 0
                orientations = orientations === 'vertical' ? 'horizontal' : 'vertical'
                // If Getty is exhausted, switch to Pexels
                if (passed) {
                  let pexelsVideoURL = undefined
                  // While loop to search for videos on Pexels for all search queries until we find one result
                  while (!pexelsVideoURL && searchIndex < pageImage.searchQueries.length) {
                    const pexelsVideoRes = await getPexelsVideos(pageImage.searchQueries[0])
                    if (pexelsVideoRes != -1) {
                      pexelsVideoURL = pexelsVideoRes
                      store.getElementById(mediaElementID).set({
                        src: pexelsVideoURL,
                      })
                      break
                    }
                  }
                  break
                }
                passed = true
              }
              // Search for videos on Getty
              const gettyVideoURL: any = await getGettyVideos(searchQuery, orientations)
              if (gettyVideoURL === -1) {
                searchIndex++
                searchQuery = pageImage.searchQueries[searchIndex]
              } else {
                searchResult = gettyVideoURL
                store.getElementById(mediaElementID).set({
                  src: gettyVideoURL,
                })
              }
            }
          }
        } else {
          // If free platform, replace with images with the similar logic of video replacement
          while (!searchResult && searchIndex < pageImage.searchQueries.length) {
            const gettyImageUrl: any = await getGettyImages(searchQuery, orientations)
            if (gettyImageUrl === -1) {
              searchIndex++
              searchQuery = pageImage.searchQueries[searchIndex]
            } else {
              const imageId = generateUniqueId()
              searchResult = gettyImageUrl
              // Add the image to the page
              store.pages[index].addElement({
                type: 'image',
                src: gettyImageUrl,
                x: -25,
                y: -25,
                width: store.width + 50,
                height: store.height + 50,
                id: imageId,
                // animations:animationFunc(),
              })
              // Delete the older element
              store.deleteElements([mediaElementID])
              store.getElementById(imageId).set({
                animations: animationFunc(store.pages[index].duration),
              })
              store.getElementById(imageId).moveBottom()
            }
          }
        }
        store.pages[index].set({
          duration: pageDuration,
          custom: {
            ...store.pages[index].custom,
            searchQuery: searchQuery,
            searchQueries: pageImage.searchQueries,
          },
        })
      }

      currentProgress += progressIncrement
      setProgressBarPercent(Math.round(currentProgress))

      const textElements = children.filter((child: any) => child.type === 'text')

      const sortedTextElementsByFont = textElements
        .filter((text: any) => {
          return true
        })
        .sort((a: any, b: any) => b.fontSize - a.fontSize)

      // Replace Text overlays
      if (sortedTextElementsByFont.length > 0) {
        const element = store.getElementById(sortedTextElementsByFont[0].id)

        const oldHeight = element.height
        const originalText = element.text
        const newText = scriptPages[scriptIndex].textOverlay
        const originalTextLength = originalText.length
        const newTextLength = newText.length

        // Calculate the ratio of change in text length
        const lengthRatio = newTextLength / originalTextLength

        // Calculate the scale factor for font size
        const scaleFactor = 1 / lengthRatio

        const fontSize = fitTextwithBreaks(
          element.width,
          element.height,
          element.text,
          element.fontFamily,
          element.letterSpacing,
          element.lineHeight,
          element.fontSize
        ) // Calculate the new font size
        await element.set({
          text: scriptPages[scriptIndex].textOverlay,
          fontSize: fontSize,
        })
      }

      currentProgress += progressIncrement
      setProgressBarPercent(Math.round(currentProgress))

      // Add logo if not free platform
      if (IS_FREE_PLATFORM === 'false') {
        const { waitUntilDone } = prefetch(icon, {
          method: 'blob-url',
          contentType: 'image/png',
        })
        await waitUntilDone()

        if (icon) {
          let { width, height } = await getImageSize(icon)

          const scaleFactor = selectedSize === '9:16' ? 0.4 : 0.2
          let scale = Math.min(store.width / width, store.height / height, scaleFactor)

          // Limit width to 250 pixels
          if (width * scale > 100) {
            scale *= 100 / (width * scale) // Adjust scale to fit within 250 width
          }

          // Ensure height is scaled proportionally
          height *= scale
          width *= scale

          store.pages[index].addElement({
            ...logo,
            id: 'quickadsLogo' + index.toString(),
            src: `${icon}`,
            x: store.width - width - 25,
            width: width,
            height: height,
          })
          const element = store.getElementById('quickadsLogo' + index.toString())
          element.set({ x: store.width - width - 25 })
        }
      }

      // Move onto the next page manually so we can loop through all images inside one script block
      j !== scriptPage.images.length - 1 && index++
      j === scriptPage.images.length - 1 && scriptIndex < scriptData.data.length - 1 && scriptIndex++
    }
  }

  const duration = 60
  currentProgress += progressIncrement
  setProgressBarPercent(Math.round(currentProgress))

  let musicUrl = ''
  let musicName = ''

  if (categorisedMusicList[industry] && categorisedMusicList[industry][duration]) {
    const firstTypeKey = Object.keys(categorisedMusicList[industry][duration])[0]
    musicUrl = categorisedMusicList[industry][duration][firstTypeKey][0].url
    musicName = categorisedMusicList[industry][duration][firstTypeKey][0].name
  }

  const backgroundScript = {
    url: musicUrl,
    duration: 30,
    industry: industry,
    name: musicName,
    backgroundVolume: 0.5,
  }

  // const backgroundAudioDuration = await getAudioDurationInSeconds(musicUrl)
  setBackgroundMusic(backgroundScript)

  // Generate subtitles by default if free version
  IS_FREE_PLATFORM === 'true' &&
    generateSubtitles(
      'Bangers',
      1,
      40,
      store,
      1,
      FONT_TEMPLATE[0],
      true,
      store.height - store.height * 0.4,
      () => {
        true
      },
      setScriptDetails
    )

  await setScriptDetails((prevScript: { data: any }) => {
    const updatedScript = { ...prevScript }

    return { ...updatedScript, backgroundMusic: backgroundScript }
  })

  store.selectPage(store.pages[0].id)
}
