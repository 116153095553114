import { fade } from '@remotion/transitions/fade'
import { slide } from '@remotion/transitions/slide'
import { wipe } from '@remotion/transitions/wipe'
import { flip } from '@remotion/transitions/flip'

export const transitionAnimationsDropDown = [
  {
    value: 'fade',
    label: 'Fade',
    functions: fade(),
  },
  {
    value: 'slide',
    label: 'Slide',
    functions: slide(),
  },
  {
    value: 'wipe',
    label: 'Wipe',
    functions: wipe(),
  },
  {
    value: 'flip',
    label: 'Flip',
    functions: flip(),
  },
  // {
  //   value: 'clockWipe()',
  //   label: 'Clock Wipe',
  // },
]

export const burstImageTransitionFunctions = [
  {
    value: 'fade',
    function: fade(),
  },
  {
    value: 'wipe',
    function: wipe(),
  },
  {
    value: 'flip',
    function: flip(),
  },
  {
    value: 'slide',
    function: slide(),
  },
]
