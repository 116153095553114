import { QuickAdsLogoBlack } from '@assets'
import { ArrowLeftOutlined } from '@ant-design/icons'

export const PageLayout = ({ children, heading }: { children: React.ReactNode; heading: string }) => {
  return (
    <div
      style={{
        // overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        height: '100vh',
        margin: '0px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          borderTopRightRadius: '25px',
          paddingLeft: '30px',
          paddingTop: '20px',
          paddingBottom: '20px',
          alignItems: 'center',
          height: '7vh',
        }}
      >
        <ArrowLeftOutlined style={{ fontSize: '20px' }} onClick={() => window.history.back()} />
        <img src={QuickAdsLogoBlack} width={110} alt='Quickads Logo' style={{ marginLeft: '20px' }}></img>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          overflow: 'auto',
        }}
      >
        {children}
      </div>
    </div>
  )
}
