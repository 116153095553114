import {
  FontSizeOutlined,
  ClockCircleOutlined,
  AudioOutlined,
  BulbOutlined,
  HighlightOutlined,
} from '@ant-design/icons'
import { useEffect, useMemo, useState } from 'react'
import { CustomTextPanel } from '../polotno/component/customTextPanel'
import { Row } from 'antd'
import { TransitionRow } from '../video/customVideoTimeline/cardRows/transitionRow'
import { AudioSidebar } from './audioPanel'
import './rightSidebar.less'
import { ElementsPanel } from '../polotno/component/customElementsPanel'
import { CaptionsPanel } from './captions/captionsPanel'
import { TextPanel } from '../polotno/component/polotnoTextPanel'
import { addDurationToMusicObjects } from '@src/data/music'

interface RightSidebarProps {
  store: any
}

export const RightSidebar = ({ store }: RightSidebarProps) => {
  const [activeComponent, setActiveComponent] = useState('')

  const handleToggleComponent = (componentName: string) => {
    setActiveComponent((prev) => (prev === componentName ? '' : componentName))
  }

  const renderActiveComponent = () => {
    const baseStyle: React.CSSProperties = {
      width: '300px',
      height: '100%',
      overflow: 'auto',
      backgroundColor: '#EBE9FE',
      padding: '20px',
      border: '1px solid #ddd',
      boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
      borderRadius: '20px 0 0 20px',
      position: 'absolute',
      top: 0,
      right: 100,
    }

    switch (activeComponent) {
      case 'text':
        return (
          <div style={baseStyle}>
            <TextPanel store={store} />
          </div>
        )
      case 'audio':
        return (
          <div style={baseStyle}>
            <AudioSidebar store={store} />
          </div>
        )
      case 'duration':
        return (
          <div style={baseStyle}>
            <TransitionRow pageID={store.activePage.id} />
          </div>
        )
      case 'element':
        return (
          <div style={baseStyle}>
            <ElementsPanel store={store} />
          </div>
        )
      case 'captions':
        return (
          <div style={baseStyle}>
            <CaptionsPanel store={store} />
          </div>
        )
      default:
        return null
    }
  }
  useEffect(() => {
    addDurationToMusicObjects()
  }, [])
  return (
    <>
      <Row style={{ flexDirection: 'column', alignItems: 'center', alignContent: 'center', textAlign: 'center' }}>
        <div
          onClick={() => handleToggleComponent('text')}
          className={`sidebar-panel-tab${activeComponent === 'text' ? ' active' : ''}`}
        >
          <FontSizeOutlined className='sidebar-panel-tab-icon' />
          <h3 className='sidebar-text' style={{ alignSelf: 'center' }}>
            Text
          </h3>
        </div>
        <div
          onClick={() => handleToggleComponent('element')}
          className={`sidebar-panel-tab${activeComponent === 'element' ? ' active' : ''}`}
        >
          <BulbOutlined className='sidebar-panel-tab-icon' />
          <h3 className='sidebar-text'>Element</h3>
        </div>
        <div
          onClick={() => handleToggleComponent('audio')}
          className={`sidebar-panel-tab${activeComponent === 'audio' ? ' active' : ''}`}
        >
          <AudioOutlined className='sidebar-panel-tab-icon' />
          <h3 className='sidebar-text'>Audio</h3>
        </div>
        <div
          onClick={() => handleToggleComponent('captions')}
          className={`sidebar-panel-tab${activeComponent === 'captions' ? ' active' : ''}`}
        >
          <HighlightOutlined className='sidebar-panel-tab-icon' />
          <h3 className='sidebar-text'>Captions</h3>
        </div>
        <div
          onClick={() => handleToggleComponent('duration')}
          className={`sidebar-panel-tab${activeComponent === 'duration' ? ' active' : ''}`}
        >
          <ClockCircleOutlined className='sidebar-panel-tab-icon' />
          <h3 className='sidebar-text'>Transition</h3>
        </div>
      </Row>
      {renderActiveComponent()}
    </>
  )
}
