/* eslint-disable react/prop-types */
import { LoadingOutlined, SnippetsOutlined } from '@ant-design/icons'
import { NODE_BACKEND_URL } from '@src/config'
import { RegeneratedScriptToCanvas } from '@src/shared/utils/VideoGeneration/RegenerateScriptToCanvas'
import { Button, Modal } from 'antd'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useGlobalContext } from '../../../../context/globalContext'
import { RegeneratedScriptModal } from './regeneratedScriptModal'
import { ScriptContainer } from './scriptContainer'

interface ScriptButtonProps {
  store: any
  open: boolean
  setOpen: any
  setProgressBarPercentage: any
}

export const ScriptButton: React.FC<ScriptButtonProps> = ({ store, setProgressBarPercentage }) => {
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const {
    ScriptDetails: [scriptDetails, setScriptDetails],
    UserDetail: [userDetails, setUserDetails],
    IsLoading: [isLoading, setIsLoading],
    VideoStyle: [videoStyle, setVideoStyle],
  } = useGlobalContext()

  const [open, setOpen] = useState(false)

  const [updatedScriptDetails, setUpdatedScriptDetails] = useState<any>(scriptDetails)

  const [regeneratedScriptDetails, setRegeneratedScriptDetails] = useState<any>(scriptDetails ? scriptDetails.data : '')

  const [isGifRendering, setIsGifRendering] = useState<boolean>(false)

  const [regnerateLoading, setRegenerateLoading] = useState<boolean>(false)

  const [confirmLoading, setConfirmLoading] = useState(false)

  const [isRegenerateButton, setIsRegenerateButton] = useState<boolean>(true)

  const [newScript, setNewScript] = useState()

  const [openNewScriptModal, setOpenNewScriptModal] = useState(false)

  useEffect(() => {
    if (scriptDetails && regeneratedScriptDetails) {
      const regeneratedItems = regeneratedScriptDetails?.filter((item: { isUpdated: any }) => item?.isUpdated)
      setIsRegenerateButton(regeneratedItems.length === 0)
    }
  }, [regeneratedScriptDetails])

  const showModal = () => {
    setOpen(true)
  }

  const showNewScriptModal = () => {
    setOpen(false)
    setOpenNewScriptModal(true)
  }

  const handleRegenerateScript = async () => {
    const previousScript = [...scriptDetails.data]

    const postBody = {
      previousScript: previousScript,
    }

    const localEndPoint = axios.create({
      baseURL: `${NODE_BACKEND_URL}`,
    })

    try {
      setRegenerateLoading(true)
      let response
      if (videoStyle === 'Simple') {
        response = await localEndPoint.post('/api/v1/openAi/regenerateSimpleScript', postBody)
      }
      if (videoStyle === 'Burst') {
        response = await localEndPoint.post('/api/v1/openAi/regenerateBurstScript', postBody)
      }

      if (response?.data) {
        setNewScript(response.data)
        setRegenerateLoading(false)
        setOpenNewScriptModal(true)
        setOpen(false)
      }
    } catch (error) {
      console.error('Failed to regenerate script:', error)
    }
  }

  const handleOk = async () => {
    setOpen(false)
    // setConfirmLoading(true)
    const regeneratedIndexes: number[] = []
    const regeneratedItems: { isUpdated: any }[] = []

    regeneratedScriptDetails.forEach((item: { isUpdated: any }, index: number) => {
      if (item.isUpdated) {
        regeneratedItems.push(item)
        regeneratedIndexes.push(index)
      }
    })

    await setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript }

      return { ...updatedScript, data: regeneratedScriptDetails }
    })

    setIsLoading(true)
    await RegeneratedScriptToCanvas(
      store,
      regeneratedItems,
      regeneratedIndexes,
      setScriptDetails,
      userDetails?.purpose?.size,
      setProgressBarPercentage
    )

    setIsLoading(false)
  }

  const handleCancel = async () => {
    setOpen(false)
  }

  const [width, setWidth] = useState<number>(500)
  const [height, setHeight] = useState<number>(600)

  const modalTitle = (
    <div style={{ display: 'flex', flexDirection: 'row', background: 'white' }}>
      <div
        style={{ width: '100%' }}
        className='draggable-modal-title' // This class will be used as a handle
      >
        Script
      </div>
      <Button
        disabled={regnerateLoading}
        style={{
          background: 'linear-gradient(90deg, rgba(108, 61, 240, 1) 0%, rgba(140, 103, 244, 1) 80%)',
          borderColor: 'transparent',
          color: 'white',
          marginRight: '20px',
        }}
        icon={regnerateLoading ? <LoadingOutlined /> : null}
        onClick={() => {
          handleRegenerateScript()
        }}
      >
        Regenerate Script
      </Button>
    </div>
  )

  return (
    <>
      <Button
        onClick={() => {
          showModal()
        }}
        style={{ background: 'transparent', borderColor: '#6A39EE', marginBottom: '10px', color: '#6A39EE' }}
        icon={isGifRendering ? <LoadingOutlined /> : <SnippetsOutlined />}
      >
        Script
      </Button>
      <Modal
        title={modalTitle}
        open={open}
        okText={'Save'}
        onOk={handleOk}
        okButtonProps={{
          disabled: isRegenerateButton,
          style: {
            background: 'linear-gradient(90deg, rgba(108, 61, 240, 1) 0%, rgba(140, 103, 244, 1) 80%)',
            borderColor: 'transparent',
            color: 'white',
            marginBottom: '10px',
          },
        }}
        confirmLoading={confirmLoading}
        cancelText={'Close'}
        onCancel={handleCancel}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            {videoStyle === 'Simple' ? <OkBtn /> : null}

            {!regnerateLoading && newScript && (
              <Button
                onClick={async () => {
                  showNewScriptModal()
                }}
                style={{ background: 'transparent', borderColor: '#6A39EE', marginBottom: '10px', color: '#6A39EE' }}
              >
                New Script
              </Button>
            )}
            <CancelBtn />
          </>
        )}
        width={width} // Set the width of the Modal to match the GifPlayer's width
        style={{
          top: '50%',
          transform: 'translateY(-50%)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        destroyOnClose={true}
      >
        <ScriptContainer
          store={store}
          setUpdatedScriptDetails={setUpdatedScriptDetails}
          setRegeneratedScriptDetails={setRegeneratedScriptDetails}
          setConfirmLoading={setConfirmLoading}
        />
      </Modal>
      {openNewScriptModal && (
        <RegeneratedScriptModal
          store={store}
          regeneratedScript={newScript}
          openNewScriptModal={openNewScriptModal}
          setOpenNewScriptModal={setOpenNewScriptModal}
          setProgressBarPercentage={setProgressBarPercentage}
        />
      )}
    </>
  )
}
