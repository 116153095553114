import { NODE_BACKEND_URL } from '@src/config'
import axios from 'axios'
import { useQuery } from 'react-query'

export const useGetGettyImages = (query = '', orientation: string, isFirstRequest: boolean) => {
  return useQuery(
    ['fetchGettyImageVideos', query, orientation],
    async () => {
      try {
        const res = await axios
          .get(`${NODE_BACKEND_URL}api/v1/gettyImage/imageSearch`, {
            params: {
              searchQuery: query,
              isFirstRequest: isFirstRequest,
              orientations: orientation,
            },
          })
          .then((response) => response.data)
          .catch((error) => {
            throw new Error(error)
          })
        return res
      } catch (error) {
        console.error(error)
      }
    },

    {
      enabled: !!query,
    }
  )
}
