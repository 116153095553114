import { PEXELS_KEY, NODE_BACKEND_URL } from '@src/config'
import axios from 'axios'

export async function getPexelsVideos(searchQuery: string) {
  const res = await axios
    .get('https://api.pexels.com/videos/search', {
      headers: {
        Authorization: PEXELS_KEY,
      },
      params: {
        query: searchQuery,
        orientation: 'landscape',
        size: 'medium',
      },
    })
    .then((response) => {
      const videoResult = response?.data?.videos[0]
      if (!videoResult) {
        return -1
      }
      const videos = videoResult.video_files.filter((file: { quality: string }) => file.quality === 'sd')
      const videoUrl = videos[videos.length - 1].link
      return videoUrl
    })
    .catch((error) => {
      console.log(error)
      return -1
    })

  return res
}

export async function getGettyVideos(searchQuery: string, orientations: string) {
  const res = await axios
    .get(`${NODE_BACKEND_URL}api/v1/gettyImage/videoSearch`, {
      params: {
        searchQuery: searchQuery,
        isFirstRequest: true,
        orientations: orientations,
      },
    })
    .then((response) => {
      const videoResult = response?.data?.videos[0]
      if (!videoResult) {
        return -1
      }
      const videos = videoResult.display_sizes.find((displaySize: { name: string }) => displaySize.name === 'comp')
      const videoUrl = videos.uri
      return videoUrl
    })
    .catch((error) => {
      console.log(error)
      return -1
    })
  return res
}

export async function getGettyImages(searchQuery: string, orientations: string, isFirstRequest?: boolean) {
  const res = await axios
    .get(`${NODE_BACKEND_URL}api/v1/gettyImage/imageSearch`, {
      params: {
        searchQuery: searchQuery,
        isFirstRequest: isFirstRequest ? isFirstRequest : true,
        orientations: orientations,
      },
    })
    .then((response) => {
      const imagesResult = response?.data[0]
      if (!imagesResult) {
        return -1
      }
      const images = imagesResult.display_sizes.find((displaySize: { name: string }) => displaySize.name === 'comp') // chnage
      const imageUrl = images.uri
      return imageUrl
    })
    .catch((error) => {
      console.log(error)
      return -1
    })
  return res
}
