export const FONT_TEMPLATE = [
  {
    name: 'Single-Word-Style',
    url: '/fonts/single-word-style.png',
    font: 'Inter',
    capitalizationReq: true,
    wordsPerLine: 1,
    backgroundColor: '',
    fontColor: '#FFFFFF',
    highlightTextStyleColor: '#FFE400',
    outlineColor: '#000000',
    outlineSize: 10,
    fontSize: 30,
    position: 50,
    rotateX: 0,
    shadow: 'no',
  },
  {
    name: 'Single-Word-Style-2',
    url: '/fonts/single-word-style-2.png',
    font: 'Poppins',
    captilazationReq: true,
    wordsPerLine: 1,
    backgroundColor: '',
    fontColor: '#FFFFFF',
    highlightTextStyleColor: '#FFE400',
    outlineColor: '#000000',
    outlineSize: 10,
    fontSize: 27,
    position: 50,
    rotateX: -10,
    shadow: 'no',
  },
  {
    name: 'Bold-Street-Video-Style',
    url: '/fonts/bold-street-video-style.png',
    font: 'Poppins',
    capitalizationReq: false,
    wordsPerLine: 3,
    backgroundColor: '#091300',
    fontColor: '#F8E71C',
    highlightTextStyleColor: '#B8E986',
    outlineColor: '#000000',
    outlineSize: 8,
    fontSize: 30,
    position: 65,
    rotateX: -10,
    shadow: 'no',
  },
  {
    name: 'Podcast-Attention-Grabber-Style',
    url: '/fonts/podcast-attention-grabber-style.png',
    font: 'Arial',
    capitalizationReq: false,
    wordsPerLine: 5,
    backgroundColor: '#FFFCE2',
    fontColor: '#000000',
    highlightTextStyleColor: '#BD10E0',
    outlineColor: '#000000',
    outlineSize: 0,
    fontSize: 30,
    position: 78,
    rotateX: -10,
    shadow: 'no',
  },
  {
    name: 'The-Hormozin-Style-1',
    url: '/fonts/the-hormozi-style-1.png',
    capitalizationReq: true,
    font: 'Inter',
    wordsPerLine: 3,
    backgroundColor: '',
    fontColor: '#FFFFFF',
    highlightTextStyleColor: '#FDFF05',
    outlineColor: '#000000',
    outlineSize: 10,
    fontSize: 30,
    position: 45,
    rotateX: 0,
    shadow: 'needed',
  },
  {
    name: 'The-Hormozin-Style-2',
    url: '/fonts/the-hormozi-style-2.png',
    capitalizationReq: 10,
    font: 'Inter',
    wordsPerLine: 3,
    backgroundColor: '',
    fontColor: '#FFFFFF',
    highlightTextStyleColor: '#00FF11',
    outlineColor: '#000000',
    outlineSize: 10,
    fontSize: 30,
    position: 45,
    rotateX: 0,
    shadow: 'needed',
  },
  {
    name: 'The-Hormozin-Style-3',
    url: '/fonts/the-hormozi-style-3.png',
    font: 'Inter',
    capitalizationReq: 10,
    wordsPerLine: 3,
    backgroundColor: '',
    fontColor: '#FFFFFF',
    highlightTextStyleColor: '#FDFF05',
    outlineColor: '#000000',
    outlineSize: 10,
    fontSize: 30,
    position: 45,
    rotateX: -10,
    shadow: 'needed',
  },
  {
    name: 'The-Vee-Friends-Style-1',
    url: '/fonts/the-vee-friends-style-1.png',
    font: 'Poppins',
    capitalizationReq: true,
    wordsPerLine: 7,
    backgroundColor: '',
    fontColor: '#FFFFFF',
    highlightTextStyleColor: '#FFE400',
    outlineColor: '#000000',
    outlineSize: 8,
    fontSize: 30,
    position: 45,
    rotateX: 0,
  },
  {
    name: 'Viral-Finance-Tiktok-Style',
    url: '/fonts/viral-finance-tiktok-style.png',
    font: 'Montserrat',
    capitalizationReq: false,
    wordsPerLine: 3,
    backgroundColor: '',
    fontColor: '#FFFFFF',
    highlightTextStyleColor: '#92FC27',
    outlineColor: '#000000',
    outlineSize: 10,
    fontSize: 30,
    position: 50,
    rotateX: -10,
    shadow: 'no',
  },
]
