export const defaultJsonTemplate = {
  width: 400,
  height: 704,
  fonts: [],
  pages: [
    {
      id: 'jCn7QzYZU-',
      children: [
        {
          id: 'pyphsez31m',
          type: 'image',
          name: '',
          opacity: 1,
          visible: true,
          selectable: true,
          removable: true,
          alwaysOnTop: false,
          showInExport: true,
          x: 0,
          y: 0,
          width: 400,
          height: 704.0000000000001,
          rotation: 0,
          animations: [],
          blurEnabled: false,
          blurRadius: 10,
          brightnessEnabled: false,
          brightness: 0,
          sepiaEnabled: false,
          grayscaleEnabled: false,
          shadowEnabled: false,
          shadowBlur: 5,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowColor: 'black',
          shadowOpacity: 1,
          draggable: true,
          resizable: true,
          contentEditable: true,
          styleEditable: true,
          src: 'https://media.gettyimages.com/id/1352666059/photo/active-young-asian-man-exercising-at-home-using-fitness-tracker-app-on-smartwatch-to-monitor.jpg?b=1&s=612x612&w=0&k=20&c=A3sPwSEhyoBT1VXg-xchdUEk0kFrX-p_5gbhQWu12ok=',
          cropX: 0.31060606060606066,
          cropY: 0,
          cropWidth: 0.37878787878787873,
          cropHeight: 1,
          cornerRadius: 0,
          flipX: false,
          flipY: false,
          clipSrc: '',
          borderColor: 'black',
          borderSize: 0,
          keepRatio: false,
        },
        {
          id: 'qZ1v7_9uSt',
          type: 'figure',
          name: '',
          opacity: 1,
          visible: true,
          selectable: true,
          removable: true,
          alwaysOnTop: false,
          showInExport: true,
          x: -6.170091457667508e-14,
          y: -1.5042934203949732e-12,
          width: 399.56634854056995,
          height: 707.784762153206,
          rotation: 0,
          animations: [],
          blurEnabled: false,
          blurRadius: 10,
          brightnessEnabled: false,
          brightness: 0,
          sepiaEnabled: false,
          grayscaleEnabled: false,
          shadowEnabled: false,
          shadowBlur: 5,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowColor: 'black',
          shadowOpacity: 1,
          draggable: true,
          resizable: true,
          contentEditable: true,
          styleEditable: true,
          subType: 'rect',
          fill: 'rgba(0,0,0,0.22)',
          dash: [],
          strokeWidth: 0,
          stroke: '#0c0c0c',
          cornerRadius: 0,
        },
        {
          id: '0488e8ca-17b1-43e6-a6b2-fa8e776a13f2',
          type: 'svg',
          name: 'shape',
          opacity: 1,
          custom: {
            editable: true,
            custom: true,
            edited: true,
          },
          visible: true,
          selectable: true,
          removable: true,
          alwaysOnTop: false,
          showInExport: true,
          x: 9.799387755086563,
          y: 356.71871093707745,
          width: 681.0269826536764,
          height: 691.1063664321524,
          rotation: 0,
          animations: [
            {
              delay: 0,
              duration: 236,
              enabled: true,
              type: 'enter',
              name: 'fade',
              data: {},
            },
            {
              delay: 0,
              duration: 236,
              enabled: false,
              type: 'exit',
              name: 'fade',
              data: {},
            },
          ],
          blurEnabled: false,
          blurRadius: 10,
          brightnessEnabled: false,
          brightness: 0,
          sepiaEnabled: false,
          grayscaleEnabled: false,
          shadowEnabled: false,
          shadowBlur: 5,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowColor: 'black',
          shadowOpacity: 1,
          draggable: true,
          resizable: true,
          contentEditable: true,
          styleEditable: true,
          src: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ3NyIgaGVpZ2h0PSIxNTE0IiB2aWV3Qm94PSIwIDAgMTQ3NyAxNTE0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBmaWx0ZXI9InVybCgjZmlsdGVyMF9mXzIxOV82MTApIj4KPGVsbGlwc2UgY3g9IjczOC41IiBjeT0iNzU3IiByeD0iMzM4LjUiIHJ5PSIzNTciIGZpbGw9IiNENTJCNUIiLz4KPHBhdGggZD0iTTEwNzYuNSA3NTdDMTA3Ni41IDk1My45MTUgOTI1LjE0OCAxMTEzLjUgNzM4LjUgMTExMy41QzU1MS44NTIgMTExMy41IDQwMC41IDk1My45MTUgNDAwLjUgNzU3QzQwMC41IDU2MC4wODUgNTUxLjg1MiA0MDAuNSA3MzguNSA0MDAuNUM5MjUuMTQ4IDQwMC41IDEwNzYuNSA1NjAuMDg1IDEwNzYuNSA3NTdaIiBzdHJva2U9IiNENTJCNUIiLz4KPC9nPgo8ZGVmcz4KPGZpbHRlciBpZD0iZmlsdGVyMF9mXzIxOV82MTAiIHg9IjAiIHk9IjAiIHdpZHRoPSIxNDc3IiBoZWlnaHQ9IjE1MTQiIGZpbHRlclVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY29sb3ItaW50ZXJwb2xhdGlvbi1maWx0ZXJzPSJzUkdCIj4KPGZlRmxvb2QgZmxvb2Qtb3BhY2l0eT0iMCIgcmVzdWx0PSJCYWNrZ3JvdW5kSW1hZ2VGaXgiLz4KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iU291cmNlR3JhcGhpYyIgaW4yPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHJlc3VsdD0ic2hhcGUiLz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iMjAwIiByZXN1bHQ9ImVmZmVjdDFfZm9yZWdyb3VuZEJsdXJfMjE5XzYxMCIvPgo8L2ZpbHRlcj4KPC9kZWZzPgo8L3N2Zz4K',
          maskSrc: '',
          cropX: 0,
          cropY: 0,
          cropWidth: 0.5,
          cropHeight: 0.99,
          keepRatio: true,
          flipX: false,
          flipY: false,
          borderColor: 'black',
          borderSize: 0,
          cornerRadius: 0,
          colorsReplace: {
            '#D52B5B': 'rgb(185, 163, 245)',
          },
        },
        {
          id: 'c3cc41cc-a060-4473-beb8-bffc8cf30bbf',
          type: 'svg',
          name: 'shape',
          opacity: 1,
          custom: {
            editable: true,
            custom: true,
            edited: true,
          },
          visible: true,
          selectable: true,
          removable: true,
          alwaysOnTop: false,
          showInExport: true,
          x: -153.88456118290503,
          y: 352.00000000000006,
          width: 553.450909723475,
          height: 561.642133072531,
          rotation: 0,
          animations: [
            {
              delay: 0,
              duration: 236,
              enabled: true,
              type: 'enter',
              name: 'fade',
              data: {},
            },
            {
              delay: 0,
              duration: 236,
              enabled: false,
              type: 'exit',
              name: 'fade',
              data: {},
            },
          ],
          blurEnabled: false,
          blurRadius: 10,
          brightnessEnabled: false,
          brightness: 0,
          sepiaEnabled: false,
          grayscaleEnabled: false,
          shadowEnabled: false,
          shadowBlur: 5,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowColor: 'black',
          shadowOpacity: 1,
          draggable: true,
          resizable: true,
          contentEditable: true,
          styleEditable: true,
          src: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ3NyIgaGVpZ2h0PSIxNTE0IiB2aWV3Qm94PSIwIDAgMTQ3NyAxNTE0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBmaWx0ZXI9InVybCgjZmlsdGVyMF9mXzIxOV82MTApIj4KPGVsbGlwc2UgY3g9IjczOC41IiBjeT0iNzU3IiByeD0iMzM4LjUiIHJ5PSIzNTciIGZpbGw9IiNENTJCNUIiLz4KPHBhdGggZD0iTTEwNzYuNSA3NTdDMTA3Ni41IDk1My45MTUgOTI1LjE0OCAxMTEzLjUgNzM4LjUgMTExMy41QzU1MS44NTIgMTExMy41IDQwMC41IDk1My45MTUgNDAwLjUgNzU3QzQwMC41IDU2MC4wODUgNTUxLjg1MiA0MDAuNSA3MzguNSA0MDAuNUM5MjUuMTQ4IDQwMC41IDEwNzYuNSA1NjAuMDg1IDEwNzYuNSA3NTdaIiBzdHJva2U9IiNENTJCNUIiLz4KPC9nPgo8ZGVmcz4KPGZpbHRlciBpZD0iZmlsdGVyMF9mXzIxOV82MTAiIHg9IjAiIHk9IjAiIHdpZHRoPSIxNDc3IiBoZWlnaHQ9IjE1MTQiIGZpbHRlclVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY29sb3ItaW50ZXJwb2xhdGlvbi1maWx0ZXJzPSJzUkdCIj4KPGZlRmxvb2QgZmxvb2Qtb3BhY2l0eT0iMCIgcmVzdWx0PSJCYWNrZ3JvdW5kSW1hZ2VGaXgiLz4KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iU291cmNlR3JhcGhpYyIgaW4yPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHJlc3VsdD0ic2hhcGUiLz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iMjAwIiByZXN1bHQ9ImVmZmVjdDFfZm9yZWdyb3VuZEJsdXJfMjE5XzYxMCIvPgo8L2ZpbHRlcj4KPC9kZWZzPgo8L3N2Zz4K',
          maskSrc: '',
          cropX: 0,
          cropY: 0,
          cropWidth: 0.5,
          cropHeight: 0.99,
          keepRatio: true,
          flipX: false,
          flipY: false,
          borderColor: 'black',
          borderSize: 0,
          cornerRadius: 0,
          colorsReplace: {
            '#D52B5B': 'rgba(99,94,105,1)',
          },
        },
      ],
      width: 'auto',
      height: 'auto',
      background: 'rgb(0, 0, 0, 0)',
      bleed: 0,
      custom: {
        duration: 10.160150000000002,
        sounds: [
          {
            url: 'https://daqrukw5ofwla.cloudfront.net/public_element/77714/a942eca8-b920-4633-84a4-d33ac057d41as6ygm4fvyk.mp3',
            audioDuration: 2.951813,
          },
        ],
        captions: [
          {
            words: [
              {
                word: 'Meet',
                start: 0,
                end: 0.4399999976158142,
              },
              {
                word: 'the',
                start: 0.4399999976158142,
                end: 0.6000000238418579,
              },
              {
                word: 'Whoop',
                start: 0.6000000238418579,
                end: 0.7799999713897705,
              },
              {
                word: 'Tracker',
                start: 0.7799999713897705,
                end: 1.1200000047683716,
              },
              {
                word: 'designed',
                start: 1.600000023841858,
                end: 1.7999999523162842,
              },
              {
                word: 'for',
                start: 1.7999999523162842,
                end: 2.0399999618530273,
              },
              {
                word: 'the',
                start: 2.0399999618530273,
                end: 2.200000047683716,
              },
              {
                word: 'dedicated',
                start: 2.200000047683716,
                end: 2.5399999618530273,
              },
            ],
          },
        ],
        aiVoiceVolume: 1,
      },
      duration: 4000,
    },
    {
      id: 'J7y6Nmf2ts',
      children: [
        {
          id: '9i8c3tdtdr',
          type: 'image',
          name: '',
          opacity: 1,
          visible: true,
          selectable: true,
          removable: true,
          alwaysOnTop: false,
          showInExport: true,
          x: 0,
          y: 0,
          width: 400,
          height: 704.0000000000001,
          rotation: 0,
          animations: [],
          blurEnabled: false,
          blurRadius: 10,
          brightnessEnabled: false,
          brightness: 0,
          sepiaEnabled: false,
          grayscaleEnabled: false,
          shadowEnabled: false,
          shadowBlur: 5,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowColor: 'black',
          shadowOpacity: 1,
          draggable: true,
          resizable: true,
          contentEditable: true,
          styleEditable: true,
          src: 'https://media.gettyimages.com/id/1480239219/photo/an-analyst-uses-a-computer-and-dashboard-for-data-business-analysis-and-data-management.jpg?b=1&s=612x612&w=0&k=20&c=IhX4d3fjMGeXkj8yjlIThWx8txEHUiSrBtRNUfT23D4=',
          cropX: 0.31060606060606066,
          cropY: 0,
          cropWidth: 0.37878787878787873,
          cropHeight: 1,
          cornerRadius: 0,
          flipX: false,
          flipY: false,
          clipSrc: '',
          borderColor: 'black',
          borderSize: 0,
          keepRatio: false,
        },
        {
          id: 'FkMZX6QlcA',
          type: 'figure',
          name: '',
          opacity: 1,
          visible: true,
          selectable: true,
          removable: true,
          alwaysOnTop: false,
          showInExport: true,
          x: -6.170091457667508e-14,
          y: -1.5042934203949732e-12,
          width: 399.56634854056995,
          height: 707.784762153206,
          rotation: 0,
          animations: [],
          blurEnabled: false,
          blurRadius: 10,
          brightnessEnabled: false,
          brightness: 0,
          sepiaEnabled: false,
          grayscaleEnabled: false,
          shadowEnabled: false,
          shadowBlur: 5,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowColor: 'black',
          shadowOpacity: 1,
          draggable: true,
          resizable: true,
          contentEditable: true,
          styleEditable: true,
          subType: 'rect',
          fill: 'rgba(0,0,0,0.22)',
          dash: [],
          strokeWidth: 0,
          stroke: '#0c0c0c',
          cornerRadius: 0,
        },
        {
          id: '1jTn2JvBbw',
          type: 'svg',
          name: 'shape',
          opacity: 1,
          custom: {
            editable: true,
            custom: true,
            edited: true,
          },
          visible: true,
          selectable: true,
          removable: true,
          alwaysOnTop: false,
          showInExport: true,
          x: 9.799387755086563,
          y: 356.71871093707745,
          width: 681.0269826536764,
          height: 691.1063664321524,
          rotation: 0,
          animations: [
            {
              delay: 0,
              duration: 236,
              enabled: true,
              type: 'enter',
              name: 'fade',
              data: {},
            },
            {
              delay: 0,
              duration: 236,
              enabled: false,
              type: 'exit',
              name: 'fade',
              data: {},
            },
          ],
          blurEnabled: false,
          blurRadius: 10,
          brightnessEnabled: false,
          brightness: 0,
          sepiaEnabled: false,
          grayscaleEnabled: false,
          shadowEnabled: false,
          shadowBlur: 5,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowColor: 'black',
          shadowOpacity: 1,
          draggable: true,
          resizable: true,
          contentEditable: true,
          styleEditable: true,
          src: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ3NyIgaGVpZ2h0PSIxNTE0IiB2aWV3Qm94PSIwIDAgMTQ3NyAxNTE0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBmaWx0ZXI9InVybCgjZmlsdGVyMF9mXzIxOV82MTApIj4KPGVsbGlwc2UgY3g9IjczOC41IiBjeT0iNzU3IiByeD0iMzM4LjUiIHJ5PSIzNTciIGZpbGw9IiNENTJCNUIiLz4KPHBhdGggZD0iTTEwNzYuNSA3NTdDMTA3Ni41IDk1My45MTUgOTI1LjE0OCAxMTEzLjUgNzM4LjUgMTExMy41QzU1MS44NTIgMTExMy41IDQwMC41IDk1My45MTUgNDAwLjUgNzU3QzQwMC41IDU2MC4wODUgNTUxLjg1MiA0MDAuNSA3MzguNSA0MDAuNUM5MjUuMTQ4IDQwMC41IDEwNzYuNSA1NjAuMDg1IDEwNzYuNSA3NTdaIiBzdHJva2U9IiNENTJCNUIiLz4KPC9nPgo8ZGVmcz4KPGZpbHRlciBpZD0iZmlsdGVyMF9mXzIxOV82MTAiIHg9IjAiIHk9IjAiIHdpZHRoPSIxNDc3IiBoZWlnaHQ9IjE1MTQiIGZpbHRlclVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY29sb3ItaW50ZXJwb2xhdGlvbi1maWx0ZXJzPSJzUkdCIj4KPGZlRmxvb2QgZmxvb2Qtb3BhY2l0eT0iMCIgcmVzdWx0PSJCYWNrZ3JvdW5kSW1hZ2VGaXgiLz4KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iU291cmNlR3JhcGhpYyIgaW4yPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHJlc3VsdD0ic2hhcGUiLz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iMjAwIiByZXN1bHQ9ImVmZmVjdDFfZm9yZWdyb3VuZEJsdXJfMjE5XzYxMCIvPgo8L2ZpbHRlcj4KPC9kZWZzPgo8L3N2Zz4K',
          maskSrc: '',
          cropX: 0,
          cropY: 0,
          cropWidth: 0.5,
          cropHeight: 0.99,
          keepRatio: true,
          flipX: false,
          flipY: false,
          borderColor: 'black',
          borderSize: 0,
          cornerRadius: 0,
          colorsReplace: {
            '#D52B5B': 'rgb(185, 163, 245)',
          },
        },
        {
          id: 'fXZ1BO_tAc',
          type: 'svg',
          name: 'shape',
          opacity: 1,
          custom: {
            editable: true,
            custom: true,
            edited: true,
          },
          visible: true,
          selectable: true,
          removable: true,
          alwaysOnTop: false,
          showInExport: true,
          x: -153.88456118290503,
          y: 352.00000000000006,
          width: 553.450909723475,
          height: 561.642133072531,
          rotation: 0,
          animations: [
            {
              delay: 0,
              duration: 236,
              enabled: true,
              type: 'enter',
              name: 'fade',
              data: {},
            },
            {
              delay: 0,
              duration: 236,
              enabled: false,
              type: 'exit',
              name: 'fade',
              data: {},
            },
          ],
          blurEnabled: false,
          blurRadius: 10,
          brightnessEnabled: false,
          brightness: 0,
          sepiaEnabled: false,
          grayscaleEnabled: false,
          shadowEnabled: false,
          shadowBlur: 5,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowColor: 'black',
          shadowOpacity: 1,
          draggable: true,
          resizable: true,
          contentEditable: true,
          styleEditable: true,
          src: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ3NyIgaGVpZ2h0PSIxNTE0IiB2aWV3Qm94PSIwIDAgMTQ3NyAxNTE0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBmaWx0ZXI9InVybCgjZmlsdGVyMF9mXzIxOV82MTApIj4KPGVsbGlwc2UgY3g9IjczOC41IiBjeT0iNzU3IiByeD0iMzM4LjUiIHJ5PSIzNTciIGZpbGw9IiNENTJCNUIiLz4KPHBhdGggZD0iTTEwNzYuNSA3NTdDMTA3Ni41IDk1My45MTUgOTI1LjE0OCAxMTEzLjUgNzM4LjUgMTExMy41QzU1MS44NTIgMTExMy41IDQwMC41IDk1My45MTUgNDAwLjUgNzU3QzQwMC41IDU2MC4wODUgNTUxLjg1MiA0MDAuNSA3MzguNSA0MDAuNUM5MjUuMTQ4IDQwMC41IDEwNzYuNSA1NjAuMDg1IDEwNzYuNSA3NTdaIiBzdHJva2U9IiNENTJCNUIiLz4KPC9nPgo8ZGVmcz4KPGZpbHRlciBpZD0iZmlsdGVyMF9mXzIxOV82MTAiIHg9IjAiIHk9IjAiIHdpZHRoPSIxNDc3IiBoZWlnaHQ9IjE1MTQiIGZpbHRlclVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY29sb3ItaW50ZXJwb2xhdGlvbi1maWx0ZXJzPSJzUkdCIj4KPGZlRmxvb2QgZmxvb2Qtb3BhY2l0eT0iMCIgcmVzdWx0PSJCYWNrZ3JvdW5kSW1hZ2VGaXgiLz4KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iU291cmNlR3JhcGhpYyIgaW4yPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHJlc3VsdD0ic2hhcGUiLz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iMjAwIiByZXN1bHQ9ImVmZmVjdDFfZm9yZWdyb3VuZEJsdXJfMjE5XzYxMCIvPgo8L2ZpbHRlcj4KPC9kZWZzPgo8L3N2Zz4K',
          maskSrc: '',
          cropX: 0,
          cropY: 0,
          cropWidth: 0.5,
          cropHeight: 0.99,
          keepRatio: true,
          flipX: false,
          flipY: false,
          borderColor: 'black',
          borderSize: 0,
          cornerRadius: 0,
          colorsReplace: {
            '#D52B5B': 'rgba(99,94,105,1)',
          },
        },
      ],
      width: 'auto',
      height: 'auto',
      background: 'rgb(0, 0, 0, 0)',
      bleed: 0,
      custom: {
        duration: 10.160150000000002,
        sounds: [
          {
            url: 'https://daqrukw5ofwla.cloudfront.net/public_element/31421/a942eca8-b920-4633-84a4-d33ac057d41a7r1u0uwxmd.mp3',
            audioDuration: 3.160813,
          },
        ],
        captions: [
          {
            words: [
              {
                word: 'Access',
                start: 0.03999999910593033,
                end: 0.6000000238418579,
              },
              {
                word: 'In',
                start: 0.6000000238418579,
                end: 0.8199999928474426,
              },
              {
                word: 'Depth',
                start: 0.8199999928474426,
                end: 1,
              },
              {
                word: 'Health',
                start: 1,
                end: 1.2200000286102295,
              },
              {
                word: 'Insights',
                start: 1.2200000286102295,
                end: 1.7000000476837158,
              },
              {
                word: 'right',
                start: 1.7000000476837158,
                end: 2.0399999618530273,
              },
              {
                word: 'at',
                start: 2.0399999618530273,
                end: 2.1600000858306885,
              },
              {
                word: 'your',
                start: 2.1600000858306885,
                end: 2.299999952316284,
              },
              {
                word: 'fingertips',
                start: 2.299999952316284,
                end: 2.7200000286102295,
              },
            ],
          },
        ],
        aiVoiceVolume: 1,
      },
      duration: 4000,
    },
    {
      id: 'dIbPmXj1_g',
      children: [
        {
          id: '0wd6gfb7w7',
          type: 'image',
          name: '',
          opacity: 1,
          visible: true,
          selectable: true,
          removable: true,
          alwaysOnTop: false,
          showInExport: true,
          x: 0,
          y: 0,
          width: 400,
          height: 704.0000000000001,
          rotation: 0,
          animations: [],
          blurEnabled: false,
          blurRadius: 10,
          brightnessEnabled: false,
          brightness: 0,
          sepiaEnabled: false,
          grayscaleEnabled: false,
          shadowEnabled: false,
          shadowBlur: 5,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowColor: 'black',
          shadowOpacity: 1,
          draggable: true,
          resizable: true,
          contentEditable: true,
          styleEditable: true,
          src: 'https://media.gettyimages.com/id/506349640/photo/coach-teaching-about-the-way-of-setting-goals.jpg?b=1&s=612x612&w=0&k=20&c=OjGSajPGogMCMonpUc7Fqm4sJUa03VcQrxxHW-2EEUM=',
          cropX: 0.2985368389780154,
          cropY: 0,
          cropWidth: 0.4029263220439691,
          cropHeight: 1,
          cornerRadius: 0,
          flipX: false,
          flipY: false,
          clipSrc: '',
          borderColor: 'black',
          borderSize: 0,
          keepRatio: false,
        },
        {
          id: '1F9LtVTSjf',
          type: 'figure',
          name: '',
          opacity: 1,
          visible: true,
          selectable: true,
          removable: true,
          alwaysOnTop: false,
          showInExport: true,
          x: -6.170091457667508e-14,
          y: -1.5042934203949732e-12,
          width: 399.56634854056995,
          height: 707.784762153206,
          rotation: 0,
          animations: [],
          blurEnabled: false,
          blurRadius: 10,
          brightnessEnabled: false,
          brightness: 0,
          sepiaEnabled: false,
          grayscaleEnabled: false,
          shadowEnabled: false,
          shadowBlur: 5,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowColor: 'black',
          shadowOpacity: 1,
          draggable: true,
          resizable: true,
          contentEditable: true,
          styleEditable: true,
          subType: 'rect',
          fill: 'rgba(0,0,0,0.22)',
          dash: [],
          strokeWidth: 0,
          stroke: '#0c0c0c',
          cornerRadius: 0,
        },
        {
          id: 'F2DbDrUc-Y',
          type: 'svg',
          name: 'shape',
          opacity: 1,
          custom: {
            editable: true,
            custom: true,
            edited: true,
          },
          visible: true,
          selectable: true,
          removable: true,
          alwaysOnTop: false,
          showInExport: true,
          x: 9.799387755086563,
          y: 356.71871093707745,
          width: 681.0269826536764,
          height: 691.1063664321524,
          rotation: 0,
          animations: [
            {
              delay: 0,
              duration: 236,
              enabled: true,
              type: 'enter',
              name: 'fade',
              data: {},
            },
            {
              delay: 0,
              duration: 236,
              enabled: false,
              type: 'exit',
              name: 'fade',
              data: {},
            },
          ],
          blurEnabled: false,
          blurRadius: 10,
          brightnessEnabled: false,
          brightness: 0,
          sepiaEnabled: false,
          grayscaleEnabled: false,
          shadowEnabled: false,
          shadowBlur: 5,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowColor: 'black',
          shadowOpacity: 1,
          draggable: true,
          resizable: true,
          contentEditable: true,
          styleEditable: true,
          src: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ3NyIgaGVpZ2h0PSIxNTE0IiB2aWV3Qm94PSIwIDAgMTQ3NyAxNTE0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBmaWx0ZXI9InVybCgjZmlsdGVyMF9mXzIxOV82MTApIj4KPGVsbGlwc2UgY3g9IjczOC41IiBjeT0iNzU3IiByeD0iMzM4LjUiIHJ5PSIzNTciIGZpbGw9IiNENTJCNUIiLz4KPHBhdGggZD0iTTEwNzYuNSA3NTdDMTA3Ni41IDk1My45MTUgOTI1LjE0OCAxMTEzLjUgNzM4LjUgMTExMy41QzU1MS44NTIgMTExMy41IDQwMC41IDk1My45MTUgNDAwLjUgNzU3QzQwMC41IDU2MC4wODUgNTUxLjg1MiA0MDAuNSA3MzguNSA0MDAuNUM5MjUuMTQ4IDQwMC41IDEwNzYuNSA1NjAuMDg1IDEwNzYuNSA3NTdaIiBzdHJva2U9IiNENTJCNUIiLz4KPC9nPgo8ZGVmcz4KPGZpbHRlciBpZD0iZmlsdGVyMF9mXzIxOV82MTAiIHg9IjAiIHk9IjAiIHdpZHRoPSIxNDc3IiBoZWlnaHQ9IjE1MTQiIGZpbHRlclVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY29sb3ItaW50ZXJwb2xhdGlvbi1maWx0ZXJzPSJzUkdCIj4KPGZlRmxvb2QgZmxvb2Qtb3BhY2l0eT0iMCIgcmVzdWx0PSJCYWNrZ3JvdW5kSW1hZ2VGaXgiLz4KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iU291cmNlR3JhcGhpYyIgaW4yPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHJlc3VsdD0ic2hhcGUiLz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iMjAwIiByZXN1bHQ9ImVmZmVjdDFfZm9yZWdyb3VuZEJsdXJfMjE5XzYxMCIvPgo8L2ZpbHRlcj4KPC9kZWZzPgo8L3N2Zz4K',
          maskSrc: '',
          cropX: 0,
          cropY: 0,
          cropWidth: 0.5,
          cropHeight: 0.99,
          keepRatio: true,
          flipX: false,
          flipY: false,
          borderColor: 'black',
          borderSize: 0,
          cornerRadius: 0,
          colorsReplace: {
            '#D52B5B': 'rgb(185, 163, 245)',
          },
        },
        {
          id: 'Ky60Ja_0ek',
          type: 'svg',
          name: 'shape',
          opacity: 1,
          custom: {
            editable: true,
            custom: true,
            edited: true,
          },
          visible: true,
          selectable: true,
          removable: true,
          alwaysOnTop: false,
          showInExport: true,
          x: -153.88456118290503,
          y: 352.00000000000006,
          width: 553.450909723475,
          height: 561.642133072531,
          rotation: 0,
          animations: [
            {
              delay: 0,
              duration: 236,
              enabled: true,
              type: 'enter',
              name: 'fade',
              data: {},
            },
            {
              delay: 0,
              duration: 236,
              enabled: false,
              type: 'exit',
              name: 'fade',
              data: {},
            },
          ],
          blurEnabled: false,
          blurRadius: 10,
          brightnessEnabled: false,
          brightness: 0,
          sepiaEnabled: false,
          grayscaleEnabled: false,
          shadowEnabled: false,
          shadowBlur: 5,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowColor: 'black',
          shadowOpacity: 1,
          draggable: true,
          resizable: true,
          contentEditable: true,
          styleEditable: true,
          src: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ3NyIgaGVpZ2h0PSIxNTE0IiB2aWV3Qm94PSIwIDAgMTQ3NyAxNTE0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBmaWx0ZXI9InVybCgjZmlsdGVyMF9mXzIxOV82MTApIj4KPGVsbGlwc2UgY3g9IjczOC41IiBjeT0iNzU3IiByeD0iMzM4LjUiIHJ5PSIzNTciIGZpbGw9IiNENTJCNUIiLz4KPHBhdGggZD0iTTEwNzYuNSA3NTdDMTA3Ni41IDk1My45MTUgOTI1LjE0OCAxMTEzLjUgNzM4LjUgMTExMy41QzU1MS44NTIgMTExMy41IDQwMC41IDk1My45MTUgNDAwLjUgNzU3QzQwMC41IDU2MC4wODUgNTUxLjg1MiA0MDAuNSA3MzguNSA0MDAuNUM5MjUuMTQ4IDQwMC41IDEwNzYuNSA1NjAuMDg1IDEwNzYuNSA3NTdaIiBzdHJva2U9IiNENTJCNUIiLz4KPC9nPgo8ZGVmcz4KPGZpbHRlciBpZD0iZmlsdGVyMF9mXzIxOV82MTAiIHg9IjAiIHk9IjAiIHdpZHRoPSIxNDc3IiBoZWlnaHQ9IjE1MTQiIGZpbHRlclVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY29sb3ItaW50ZXJwb2xhdGlvbi1maWx0ZXJzPSJzUkdCIj4KPGZlRmxvb2QgZmxvb2Qtb3BhY2l0eT0iMCIgcmVzdWx0PSJCYWNrZ3JvdW5kSW1hZ2VGaXgiLz4KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iU291cmNlR3JhcGhpYyIgaW4yPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHJlc3VsdD0ic2hhcGUiLz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iMjAwIiByZXN1bHQ9ImVmZmVjdDFfZm9yZWdyb3VuZEJsdXJfMjE5XzYxMCIvPgo8L2ZpbHRlcj4KPC9kZWZzPgo8L3N2Zz4K',
          maskSrc: '',
          cropX: 0,
          cropY: 0,
          cropWidth: 0.5,
          cropHeight: 0.99,
          keepRatio: true,
          flipX: false,
          flipY: false,
          borderColor: 'black',
          borderSize: 0,
          cornerRadius: 0,
          colorsReplace: {
            '#D52B5B': 'rgba(99,94,105,1)',
          },
        },
      ],
      width: 'auto',
      height: 'auto',
      background: 'rgb(0, 0, 0, 0)',
      bleed: 0,
      custom: {
        duration: 10.160150000000002,
        sounds: [
          {
            url: 'https://daqrukw5ofwla.cloudfront.net/public_element/44795/a942eca8-b920-4633-84a4-d33ac057d41a8p7d39ixas.mp3',
            audioDuration: 3.160813,
          },
        ],
        captions: [
          {
            words: [
              {
                word: 'Empower',
                start: 0,
                end: 0.6399999856948853,
              },
              {
                word: 'teams',
                start: 0.6399999856948853,
                end: 0.9399999976158142,
              },
              {
                word: 'with',
                start: 0.9399999976158142,
                end: 1.1799999475479126,
              },
              {
                word: 'real',
                start: 1.1799999475479126,
                end: 1.3600000143051147,
              },
              {
                word: 'time',
                start: 1.3600000143051147,
                end: 1.5800000429153442,
              },
              {
                word: 'performance',
                start: 1.5800000429153442,
                end: 2.059999942779541,
              },
              {
                word: 'optimization',
                start: 2.059999942779541,
                end: 2.6600000858306885,
              },
            ],
          },
        ],
        aiVoiceVolume: 1,
      },
      duration: 4000,
    },
  ],
  unit: 'px',
  dpi: 72,
}
