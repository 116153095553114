/* eslint-disable react/prop-types */
import { Tabs } from 'antd'
import TabPane from 'antd/es/tabs/TabPane'
import { BulkCreateTabPanel } from './bulkCreateTabPane/bulkCreateTabPanel'

interface BulkCreateProps {
  store: any
  bulkCreateArray: any
}

export const BulkCreateTab: React.FC<BulkCreateProps> = ({ store, bulkCreateArray }) => {
  return (
    <Tabs defaultActiveKey='1'>
      {bulkCreateArray &&
        bulkCreateArray.map((item: any, index: number) => (
          <TabPane tab={`${item.id}`} key={index + 1}>
            <BulkCreateTabPanel
              store={store}
              width={item.width}
              height={item.height}
              htmlCode={item.htmlCode}
              duration={item.duration}
              jsonData={item.jsonData}
              burstImagesTransitionAnimationArray={item.burstImagesTransitionAnimationArray}
              videoStyle={item.videoStyle}
            />
          </TabPane>
        ))}
    </Tabs>
  )
}
