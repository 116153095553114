import { animations } from '@src/data/constants'
import { fitTextwithBreaks } from './core'
import { StoreType } from 'polotno/model/store'

export const updateTextElements = (
  elements: any,
  heading: string,
  subHeading: string,
  thirdHeading: string,
  font: string
) => {
  const textElements = elements.reduce((acc: any[], el: any) => {
    if (el.type === 'text') {
      acc.push(el)
    }
    return acc
  }, [])

  textElements.sort((a: any, b: any) => b.fontSize - a.fontSize)

  for (let i = 0; i < 3; i++) {
    if (textElements[0]) {
      textElements[0].text = heading
      const fontSize = fitTextwithBreaks(
        textElements[0].width,
        textElements[0].height,
        heading,
        textElements[0].fontFamily,
        textElements[0].letterSpacing,
        textElements[0].lineHeight,
        textElements[0].fontSize
      )
      textElements[0].fontSize = fontSize
    }
    if (textElements[1]) {
      textElements[1].text = subHeading
      const fontSize = fitTextwithBreaks(
        textElements[1].width,
        textElements[1].height,
        subHeading,
        textElements[1].fontFamily,
        textElements[1].letterSpacing,
        textElements[1].lineHeight,
        textElements[1].fontSize
      )
      textElements[1].fontSize = fontSize
    }
    if (textElements[2]) {
      textElements[2].text = thirdHeading
      const fontSize = fitTextwithBreaks(
        textElements[2].width,
        textElements[2].height,
        thirdHeading,
        textElements[2].fontFamily,
        textElements[2].letterSpacing,
        textElements[2].lineHeight,
        textElements[2].fontSize
      )
      textElements[2].fontSize = fontSize
    }
  }

  // Update the main elements array with the modified text elements
  const updatedElements = elements.map((el: any) => {
    if (el.type === 'text') {
      return textElements.shift()
    }
    return el
  })

  return updatedElements
}

export const updateImageElement = async (elements: any, url: string) => {
  const imageElements = elements.filter((el: any) => el.type === 'image')
  if (imageElements.length > 0 && imageElements[0].src !== url) {
    imageElements[0].src = url

    const img = new Image()
    const imageLoaded = new Promise<HTMLImageElement>((resolve, reject) => {
      img.onload = () => resolve(img)
      img.onerror = reject
    })
    img.src = url
    const loadedImg = await imageLoaded.catch((error) => {
      console.error('Error loading image:', error)
    })

    if (loadedImg) {
      const imageWidth = loadedImg.width
      const imageHeight = loadedImg.height

      // Calculate the aspect ratio of the image
      const imageAspectRatio = imageWidth / imageHeight

      // Calculate the aspect ratio of the container
      const containerAspectRatio = imageElements[0].width / imageElements[0].height

      // Initialize crop values
      let cropX = 0
      let cropY = 0
      let cropWidth = 1
      let cropHeight = 1

      if (imageAspectRatio > containerAspectRatio) {
        // Image is wider than the container, so adjust cropWidth
        cropWidth = containerAspectRatio / imageAspectRatio
        cropX = (1 - cropWidth) / 2
      } else {
        // Image is taller than the container, so adjust cropHeight
        cropHeight = imageAspectRatio / containerAspectRatio
        cropY = (1 - cropHeight) / 2
      }

      // Update the crop values for the image
      imageElements[0].cropX = cropX
      imageElements[0].cropY = cropY
      imageElements[0].cropWidth = cropWidth
      imageElements[0].cropHeight = cropHeight
    }

    return elements
  }
  return elements
}

export const updateSvgElements = (elements: any, colors: any) => {
  const svgElements = elements.filter((el: any) => el.type === 'svg')
  const colorsArr = [colors.secondary, colors.tertiary]
  svgElements.map((el: any, index: number) => {
    // get random number 1 or 2

    const key = Object.keys(el.colorsReplace)[0]
    const color = colorsArr[0]
    el.colorsReplace = { [key]: hexToRgb(color) }
    el.fill = color
    return el
  })

  return elements
}

export const hexToRgb = (hex: string) => {
  // eslint-disable-next-line no-magic-numbers
  const bigint = parseInt(hex.slice(1), 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255
  return `rgba(${r}, ${g}, ${b}, 1.0)`
}

export const updateTextElementsInTextPanel = (
  elements: any,
  heading: string,
  subHeading: string,
  thirdHeading: string
) => {
  let textElements = elements.filter((el: any) => el.type === 'text')

  textElements = textElements.sort((a: any, b: any) => b.fontSize - a.fontSize)

  // Create new objects with the updated text, instead of modifying the existing ones
  const updatedTextElements = textElements.map((el: any, index: number) => {
    if (index === 0) return { ...el, text: heading }
    if (index === 1) return { ...el, text: subHeading }
    if (index === 2) return { ...el, text: thirdHeading }
    return el
  })

  // Create a new array for the updated elements, replacing the original text elements with the updated ones
  const updatedElements = elements.map((el: any) => {
    const foundIndex = updatedTextElements.findIndex((textEl: any) => textEl.id === el.id)
    if (foundIndex !== -1) {
      return updatedTextElements[foundIndex]
    }
    return el
  })

  return updatedElements
}

//   return textElements
// }

function getImageDimensions(url: any) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function (this: any) {
      resolve({ width: this.naturalWidth, height: this.naturalHeight })
    }
    img.onerror = function () {
      reject(new Error('Failed to load image'))
    }
    img.src = url
  })
}

export const getImageElement = (url: string) => {
  const imageUrl = url
  let width, height
  getImageDimensions(imageUrl)
    .then((dimensions: any) => {
      width = dimensions.width
      height = dimensions.height
    })
    .catch((error) => {
      console.error('Error:', error.message)
    })

  return {
    type: 'image',
    x: 0,
    y: 0,
    rotation: 0,
    locked: false, // deprecated
    blurEnabled: false,
    blurRadius: 10,
    brightnessEnabled: false,
    brightness: 0,
    shadowEnabled: false,
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'black',
    shadowOpacity: 1,
    name: '', // name of element, can be used to find element in the store
    // url path to image
    src: url,
    keepRatio: false, // can we change aspect ratio of image

    // url path to svg or image that will be used to clip image
    // cab be used for image framing
    clipSrc: '',
    width: 500,
    height: 500,
    cropX: 0, // 0-1 : % from original image width
    cropY: 0, // 0-1 : % from original image height
    cropWidth: 1, // 0-1 : % from original image width
    cropHeight: 1, // 0-1 : % from original image height
    cornerRadius: 0,
    borderColor: 'black',
    borderSize: 0,
    flipX: false,
    flipY: false,

    // can user select element?
    // if false, element will be "invisible" for user clicks
    selectable: true,
    // use for absolute positing of element
    alwaysOnTop: false,
    // also we can hide some elements from the export
    showInExport: true,
    // can element be moved and rotated
    draggable: true,
    // can we change content of element?
    contentEditable: true,

    // can we remove element from UI with button or keyboard?
    removable: true,
    // can we resize element?
    resizable: true,
  }
}

export const loadJsonFromUrl = async (url: string): Promise<any> => {
  try {
    const response = await fetch(`${url}`, {
      method: 'GET',
    })
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Could not load JSON from URL', error)
    throw error
  }
}

export const addNewJsonElementsIntoActivePage = (activePageJson: any, newElementsJson: any) => {
  const updatedNewElementsJson = newElementsJson.map((element: any) => ({
    ...element, // Spread existing properties
    name: '', // Add name property
    opacity: 1, // Add opacity property
    visible: true, // Add visible property
  }))
  const newPageJson = {
    width: 1080,
    height: 1080,
    fonts: [],
    pages: [...activePageJson.children, ...updatedNewElementsJson],
  }
  return newPageJson
}

export const addDefaultAnimations = (store: StoreType) => {
  store.pages.map((page: any) => {
    page.children
      .filter((el: any) => el.type === 'text')
      .sort((a: any, b: any) => b.fontSize - a.fontSize)
      .map((el: any, index: number) => {
        if (index === 0) store.getElementById(el.id).animations = animations.heading
        if (index === 1) store.getElementById(el.id).animations = animations.subHeading
        if (index === 2) store.getElementById(el.id).animations = animations.thirdHeading
      })

    page.children.map((element: any) => {
      if (element.type === 'svg') {
        store.getElementById(element.id).animations = animations.svg
      }
    })
  })
}
