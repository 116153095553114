import { IS_FREE_PLATFORM, NODE_BACKEND_URL } from '../../config'
import axios from 'axios'
import { interpolate, prefetch, Easing } from 'remotion'

// This function will take the duration of the gif and the frames we want the gif to play at and will return the duration for the remotion player
export function millisecondsToFrames(milliseconds: number, fps: number): number {
  const seconds = milliseconds / 1000 // Convert milliseconds to seconds
  return Math.round(seconds * fps) // Convert seconds to frames and round to nearest whole number
}

export async function downloadMp4(
  data: any,
  duration: number,
  width: number,
  height: number,
  jsonData: any,
  rotation: number,
  translateY: number,
  translateX: number,
  transitionDurationInFrames: number,
  transitionAnimationProp: any,
  backgroundMusicUrl: string,
  backgroundMusicDuration: number,
  isDownload: boolean,
  setIsMp4Downloading: (value: boolean) => void
) {
  axios
    .post(`${NODE_BACKEND_URL}/api/v1/render`, {
      data,
      jsonData,
      width,
      height,
      rotation,
      translateY,
      translateX,
      transitionDurationInFrames,
      transitionAnimationProp,
      backgroundMusicUrl,
      backgroundMusicDuration,
      isDownload,
    })
    .then((response) => {
      const videoUrl = response.data // Assuming response.data contains the URL of the video
      // Fetch the video from the URL
      axios
        .get(videoUrl, {
          responseType: 'blob', // This tells Axios to handle the response as a Blob
          headers: {
            Origin: 'https://rayn.group', // This header might not be necessary in client-side requests
          },
        })
        .then((response) => {
          // The blob is now directly available as response.data
          const blob = response.data

          // Create a local URL for the blob
          const localUrl = window.URL.createObjectURL(blob)

          // Create a temporary link element
          const link = document.createElement('a')
          link.href = localUrl
          link.setAttribute('download', 'video.mp4') // Set the filename for the download

          // Append the link to the document
          document.body.appendChild(link)

          // Programmatically click the link to trigger the download
          link.click()

          setIsMp4Downloading(false)

          // Optionally, remove the link after triggering the download
          // This cleanup step might be delayed or managed differently depending on your app's needs
          setTimeout(() => {
            window.URL.revokeObjectURL(localUrl) // Release the created object URL
            // link.parentNode.removeChild(link);
          }, 100) // Short delay to ensure the download process starts
        })
        .catch((error) => {
          console.error('Error downloading the video: ', error)
          setIsMp4Downloading(false)
        })
    })
    .catch((error) => {
      console.log(error)
      setIsMp4Downloading(false)
    })
}

interface Animation {
  delay: number
  duration: number
  enabled: boolean
  type: string
  name: string
  data: any
}

interface Child {
  id: string
  type: string
  text?: string
  animations: Animation[]
  src?: string
  x: number
  y: number
  width: number
  height: number
  rotation: number
  fontFamily?: string
  fontSize?: number
  fontWeight?: string
  fontStyle?: string
  textAlign?: string
  fill?: string
  cornerRadius?: number
  borderSize?: number
  cropX: number
  cropY: number
  blurEnabled: boolean
  blurRadius: number
  sepiaEnabled: boolean
  grayscaleEnabled: boolean
  brightnessEnabled: boolean
  brightness: number
  shadowEnabled: boolean
  shadowBlur: number
  letterSpacing: number
  strokeWidth: number
  stroke: string
  lineHeight: number
  cropWidth: number
  cropHeight: number
}

interface PageData {
  id: string
  children: Child[]
}

// interface JsonData {
//   pages: Page[]
// }

interface SinglePageProps {
  pageHtml: any // HTML string for the page content
  width: number
  height: number
  pageData: PageData // Adjusted to pass JSON data
}

export const applyAnimations = (
  pageRef: any,
  pageData: { children: { id: string; animations: any[] }[] },
  frame: number,
  fps: number,
  durationInFrames: number
) => {
  if (pageRef.current) {
    pageData.children.forEach((child: { id: string; animations: any[] }) => {
      const cssSafeId = CSS.escape(child.id)

      const element = pageRef.current?.querySelector(`#${cssSafeId}`)

      if (element) {
        let isCompleted = false

        const enterAnimation = child.animations.find((a) => a.type === 'enter')
        // const exitAnimation = child.animations.find((a) => a.type === 'exit')

        const enterAnimationFalse = child.animations.find((a) => a.type === 'enter' && a.enabled === false)
        const exitAnimationFalse = child.animations.find((a) => a.type === 'exit' && a.enabled === false)

        const startFrame = ((enterAnimation?.delay === 0 ? 0.01 : enterAnimation?.delay) / 1000) * fps
        const endFrameEnter =
          startFrame + ((enterAnimation?.duration === 0 ? 0.01 : enterAnimation?.duration) / 1000) * fps
        const easingFunction = IS_FREE_PLATFORM === 'true' ? Easing.bezier(.12,.53,.26,.9) : undefined;
        if (frame > endFrameEnter) {
          isCompleted = true
        }

        child.animations.forEach((animation) => {
          if (animation.enabled && element instanceof HTMLElement) {
            let elementAnimationForFadeAndZoom = 150
            let elementAnimationPositvieX = 1
            let elementAnimationNegativeX = 1
            let elementAnimationDiagonal = 1

            if (animation.enabled === true && animation.type === 'enter' && exitAnimationFalse) {
              const initialPositiveOffsetX = IS_FREE_PLATFORM === 'false' ? -220 : -25
              const initialNegativeOffsetX = IS_FREE_PLATFORM === 'false' ? 220 : 25
              const initialDiagonalOffsetY = IS_FREE_PLATFORM === 'false' ? 220 : 25

              const startFrame = ((animation?.delay === 0 ? 0.01 : animation?.delay) / 1000) * fps
              const endFrame = startFrame + ((animation.duration === 0 ? 0.01 : animation.duration) / 1000) * fps

              elementAnimationForFadeAndZoom = interpolate(frame, [startFrame, endFrame], [1, 1.4], {
                extrapolateLeft: 'clamp',
                extrapolateRight: 'clamp',
                // easing: easingFunction,
               
              })
              elementAnimationPositvieX = interpolate(frame, [startFrame, endFrame], [initialPositiveOffsetX, 0], {
                extrapolateLeft: 'clamp',
                extrapolateRight: 'clamp',
                // easing: easingFunction,
              })

              elementAnimationNegativeX = interpolate(frame, [startFrame, endFrame], [initialNegativeOffsetX, 0], {
                extrapolateLeft: 'clamp',
                extrapolateRight: 'clamp',
                // easing: easingFunction,
              })

              elementAnimationDiagonal = interpolate(frame, [startFrame, endFrame], [initialDiagonalOffsetY, 0], {
                extrapolateLeft: 'clamp',
                extrapolateRight: 'clamp',
                // easing: easingFunction,
              })
            }

            if (animation.enabled === true && animation.type === 'exit' && enterAnimationFalse) {
              if (isCompleted === true) {
                const initialPositiveOffsetX = IS_FREE_PLATFORM === 'false' ? 220 : 25
                const initialNegativeOffsetX = IS_FREE_PLATFORM === 'false' ? -220 : -25
                const initialDiagonalOffsetY = IS_FREE_PLATFORM === 'false' ? -220 : -25

                const startFrame = durationInFrames - endFrameEnter
                const endFrame = durationInFrames

                elementAnimationForFadeAndZoom = interpolate(frame, [startFrame, endFrame], [1, 0], {
                  extrapolateLeft: 'clamp',
                  extrapolateRight: 'clamp',
                  // easing: easingFunction,
                })

                elementAnimationPositvieX = interpolate(frame, [startFrame, endFrame], [1, initialPositiveOffsetX], {
                  extrapolateLeft: 'clamp',
                  extrapolateRight: 'clamp',
                  // easing: easingFunction,
                })

                elementAnimationNegativeX = interpolate(frame, [startFrame, endFrame], [1, initialNegativeOffsetX], {
                  extrapolateLeft: 'clamp',
                  extrapolateRight: 'clamp',
                  // easing: easingFunction,
                })

                elementAnimationDiagonal = interpolate(frame, [startFrame, endFrame], [1, initialDiagonalOffsetY], {
                  extrapolateLeft: 'clamp',
                  extrapolateRight: 'clamp',
                  // easing: easingFunction,
                })
              }
            }

            if (!enterAnimationFalse && !exitAnimationFalse) {
              let isCompleted = false

              // const initialPositiveOffsetX = IS_FREE_PLATFORM === 'false' ? -220 : -20
              // const initialNegativeOffsetX = IS_FREE_PLATFORM === 'false' ? 220 : 20
              // const initialDiagonalOffsetY = IS_FREE_PLATFORM === 'false' ? 220 : 20
              const initialPositiveOffsetX = IS_FREE_PLATFORM === 'false' ? -220 : -25
              const initialNegativeOffsetX = IS_FREE_PLATFORM === 'false' ? 220 : 25
              const initialDiagonalOffsetY = IS_FREE_PLATFORM === 'false' ? 220 : 25
              const startFrameEnter = ((animation?.delay === 0 ? 0.01 : animation?.delay) / 1000) * fps
              const endFrameEnter =
                startFrameEnter + ((animation?.duration === 0 ? 0.01 : animation?.duration) / 1000) * fps

              const startFrameExit = durationInFrames - endFrameEnter
              const endFrame = durationInFrames

              if (frame > endFrameEnter) {
                isCompleted = true
              }

              elementAnimationForFadeAndZoom = interpolate(frame, [startFrameEnter, endFrameEnter], [0, 1], {
                extrapolateLeft: 'clamp',
                extrapolateRight: 'clamp',
              })

              elementAnimationPositvieX = interpolate(
                frame,
                [startFrameEnter, endFrameEnter],
                [initialPositiveOffsetX, 0],
                {
                  extrapolateLeft: 'clamp',
                  extrapolateRight: 'clamp',
                  // easing: easingFunction,
                }
              )

              elementAnimationNegativeX = interpolate(
                frame,
                [startFrameEnter, endFrameEnter],
                [initialNegativeOffsetX, 0],
                {
                  extrapolateLeft: 'clamp',
                  extrapolateRight: 'clamp',
                  // easing: easingFunction,
                }
              )

              elementAnimationDiagonal = interpolate(
                frame,
                [startFrameEnter, endFrameEnter],
                [initialDiagonalOffsetY, 0],
                {
                  extrapolateLeft: 'clamp',
                  extrapolateRight: 'clamp',
                  // easing: easingFunction,
                }
              )

              if (isCompleted === true) {
                const initialPositiveOffsetX = IS_FREE_PLATFORM === 'false' ? 500 : 25
                const initialNegativeOffsetX = IS_FREE_PLATFORM === 'false' ? 500 : -25
                const initialDiagonalOffsetY = IS_FREE_PLATFORM === 'false' ? 500 : -25

                elementAnimationForFadeAndZoom = interpolate(frame, [startFrameExit, endFrame], [1, 0], {
                  extrapolateLeft: 'clamp',
                  extrapolateRight: 'clamp',
                  // easing: easingFunction,
                })

                elementAnimationPositvieX = interpolate(
                  frame,
                  [startFrameExit, endFrame],
                  [1, initialPositiveOffsetX],
                  {
                    extrapolateLeft: 'clamp',
                    extrapolateRight: 'clamp',
                    // easing: easingFunction,
                  }
                )

                elementAnimationNegativeX = interpolate(
                  frame,
                  [startFrameExit, endFrame],
                  [1, initialNegativeOffsetX],
                  {
                    extrapolateLeft: 'clamp',
                    extrapolateRight: 'clamp',
                    // easing: easingFunction,
                  }
                )

                elementAnimationDiagonal = interpolate(frame, [startFrameExit, endFrame], [1, initialDiagonalOffsetY], {
                  extrapolateLeft: 'clamp',
                  extrapolateRight: 'clamp',
                  // easing: easingFunction,
                })
              }
            }

            if (animation.name === 'fade') {
              element.style.opacity = `${elementAnimationForFadeAndZoom}`
            } else if (animation.name === 'zoom') {
              element.style.scale = `${elementAnimationForFadeAndZoom}`
            } else if (animation.name === 'move') {
              if (animation.data.direction === 'right') {
                // element.style.opacity = `${elementAnimationForFadeAndZoom}`
                element.style.transform = `translateX(${elementAnimationPositvieX}px)`
              }
              if (animation.data.direction === 'left') {
                // element.style.opacity = `${elementAnimationForFadeAndZoom}`
                element.style.transform = `translateX(${elementAnimationNegativeX}px)`
              }
              if (animation.data.direction === 'up') {
                // element.style.opacity = `${elementAnimationForFadeAndZoom}`
                element.style.transform = `translateY(${-elementAnimationPositvieX}px)`
              }
              if (animation.data.direction === 'down') {
                // element.style.opacity = `${elementAnimationForFadeAndZoom}`
                element.style.transform = `translateY(${-elementAnimationNegativeX}px)`
              }
              if (animation.data.direction === 'bottom-right') {
                // element.style.opacity = `${elementAnimationForFadeAndZoom}`
                element.style.transform = `translate(${elementAnimationPositvieX}px, ${-elementAnimationNegativeX}px)`
              }
              if (animation.data.direction === 'bottom-left') {
                // element.style.opacity = `${elementAnimationForFadeAndZoom}`
                element.style.transform = `translate(${-elementAnimationPositvieX}px, ${-elementAnimationNegativeX}px)`
              }
              if (animation.data.direction === 'top-right') {
                // element.style.opacity = `${elementAnimationForFadeAndZoom}`
                element.style.transform = `translate(${-elementAnimationDiagonal}px, ${elementAnimationNegativeX}px)`
              }
              if (animation.data.direction === 'top-left') {
                // element.style.opacity = `${elementAnimationForFadeAndZoom}`
                element.style.transform = `translate(${-elementAnimationPositvieX}px, ${elementAnimationDiagonal}px )`
              }
              // Handle move animations
            }
          }
        })
      }
    })
  }
}

export const prefetchAsset = async (url: string, contentType = 'audio/mpeg') => {
  if (url) {
    const { waitUntilDone } = prefetch(url, {
      method: 'blob-url',
      contentType,
    })
    await waitUntilDone()
  }
}
