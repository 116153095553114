import { Player } from '@remotion/player'
import React from 'react'
import { MyCompositionHtml } from './myCompositionHtml'
// import { MyComp } from '@src/remotionRoot'

type gifProps = {
  htmlData: any
  duration: number
  jsonData: any
  transitionDurationInFrames: number
  transitionAnimationProp: any
  isDownload: boolean
  scriptDetails: any
  burstImagesTransitionAnimationArray: any
  videoStyle: string
}

// Assuming you want to use the Remotion Player for previewing the video
export const VideoPlayer = ({
  htmlData,
  duration,
  jsonData,
  transitionDurationInFrames,
  transitionAnimationProp,
  isDownload,
  scriptDetails,
  burstImagesTransitionAnimationArray,
  videoStyle,
}: gifProps) => {
  const videoPlayerWidth = isDownload ? jsonData.width : Math.round(jsonData.width / 1)
  const videoPlayerHeight = isDownload ? jsonData.height : Math.round(jsonData.height / 1)

  const memoizedInputProps = React.useMemo(
    () => ({
      htmlData,
      jsonData: jsonData,
      duration,
      width: videoPlayerWidth, // Use the scaled values but keep the names as expected
      height: videoPlayerHeight,
      videoPlayerWidth,
      videoPlayerHeight,
      transitionDurationInFrames,
      transitionAnimationProp,
      isDownload,
      scriptDetails,
      burstImagesTransitionAnimationArray,
      videoStyle,
    }),
    [
      htmlData,
      jsonData,
      scriptDetails,
      duration,
      videoPlayerWidth,
      videoPlayerHeight,
      transitionDurationInFrames,
      transitionAnimationProp,
      isDownload,
      burstImagesTransitionAnimationArray,
      videoStyle,
    ]
  )

  return (
    <Player
      fps={30}
      durationInFrames={duration}
      component={MyCompositionHtml}
      autoPlay={false}
      inputProps={memoizedInputProps}
      controls={true} // Show player controls
      compositionWidth={videoPlayerWidth} // Add composition width here
      compositionHeight={videoPlayerHeight} // Add composition height here
    />
  )
}
