import { useGlobalContext } from '@src/context/globalContext'
import { fitTextwithBreaks } from '@src/shared/utils/core'
import { Input, Row } from 'antd'
import { useState } from 'react'

interface TextOverlayProps {
  index: number
  store: any
  pages: any
}

export const TextOverlay = ({ index, store, pages }: TextOverlayProps) => {
  const {
    ScriptDetails: [scriptDetails, setScriptDetails],
  } = useGlobalContext()

  const pageId = pages.id

  const textElements = pages.children.filter((child: { type: string; id: string }) => {
    return child.type === 'text' && child.id !== `captionText${pageId}`
  })

  const sortedTextElementsByFont = textElements
    .filter(() => {
      return true
    })
    .sort((a: { fontSize: number }, b: { fontSize: number }) => b.fontSize - a.fontSize)

  const extractText = (html: string) => {
    const doc = new DOMParser().parseFromString(html, 'text/html')
    return doc.body.textContent || ''
  }

  const [sortedTextElements, setSortedTextElements] = useState<any>(sortedTextElementsByFont)

  const [textValues, setTextValues] = useState<{ [key: string]: string }>(
    sortedTextElements.reduce(
      (acc: any, curr: { id: any; text: any }) => ({ ...acc, [curr.id]: extractText(curr.text) }),
      {}
    )
  )

  const handleTextChange = (id: string, newText: string) => {
    setTextValues((prev) => ({ ...prev, [id]: newText }))
  }
  const handleTextOverlayChange = async (newText: string, index: number, textId: string) => {
    const element = sortedTextElements.find((el: { id: any }) => el.id === textId)

    if (!element) return

    const oldHeight = element.height
    const originalText = element.text
    // const newText = scriptPages[index].textOverlay
    const originalTextLength = originalText.length
    const newTextLength = newText.length

    // Calculate the ratio of change in text length
    const lengthRatio = newTextLength / originalTextLength

    // Calculate the scale factor for font size
    const scaleFactor = 1 / lengthRatio

    const fontSize = fitTextwithBreaks(
      element.width,
      element.height,
      element.text,
      element.fontFamily,
      element.letterSpacing,
      element.lineHeight,
      element.fontSize
    ) // Calculate the new font size
    await element.set({
      text: newText,
      fontSize: fontSize,
    })

    // await setScriptDetails((prevScript: { data: any }) => {
    //   const updatedScript = { ...prevScript }
    //   const updatedData = [...prevScript.data]

    //   updatedData[index] = {
    //     ...updatedData[index],
    //     textOverlay: newText,
    //   }
    //   return { ...updatedScript, data: updatedData }
    // })
  }

  return (
    <Row gutter={[0, 0]} style={{ flexDirection: 'column' }}>
      {sortedTextElements.map((text: any) => {
        return (
          <div key={text.id}>
            <Input
              placeholder='Type your Text overlay'
              style={{
                width: '100%',
                marginRight: '10px',
                marginTop: '5px',
              }}
              value={textValues[text.id]}
              onChange={(e) => handleTextChange(text.id, e.target.value)}
              onBlur={() => handleTextOverlayChange(textValues[text.id], index, text.id)}
              className='hoverable-text-box' // Apply the CSS class here
            />
          </div>
        )
      })}
    </Row>
  )
}
