export function animationFunc(duration: any) {
  interface Animation {
    delay: number
    duration: number
    enabled: boolean
    name: string
    type: 'enter' | 'exit' | string
    data: Record<string, any>
  }

  const randomNumber = Math.random()
  const randomBinary = Math.round(randomNumber) // 0 or 1
  const nameArr = ['zoom', 'move'] // Move
  const randomNumberDirection = Math.floor(Math.random() * 6)
  const directionArr = ['up', 'down', 'top-right', 'top-left', 'bottom-right', 'bottom-left']
  const animations: Animation[] = [
    {
      delay: 0.01,
      duration: duration,
      enabled: true,
      name: nameArr[randomBinary],
      type: 'enter',
      data: {
        direction: nameArr[randomBinary] === 'move' ? directionArr[randomNumberDirection] : '',
      },
    },
    {
      delay: 0,
      duration: duration,
      enabled: false,
      name: nameArr[randomBinary],
      type: 'exit',
      data: {},
    },
  ]
  return animations
}
