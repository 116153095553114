import { getAudioDurationInSeconds } from '@remotion/media-utils'
import { NODE_BACKEND_URL, IS_FREE_PLATFORM } from '@src/config'
import { getDomainID, getToken, qaBackendApiInstance } from '@src/config/config'
import axios from 'axios'
import { message } from 'antd'
import { generateUniqueId } from '../core'
import { categorisedVoiceOvers } from '../../../data/music'

export async function generateAiAudio(
  scriptData: any,
  store: any,
  index: any,
  setScriptDetails: any,
  setProgressBarPercent: any,
  currentProgress: number,
  progressIncrement: number,
  pagesLength: number,
  industry: string,
  style: string
) {
  try {
    const aiVoiceID = categorisedVoiceOvers[industry][style].voiceID
    const aiVoiceName = categorisedVoiceOvers[industry][style].name

    let domainId
    let token

    if (IS_FREE_PLATFORM === 'false') {
      domainId = getDomainID()
      token = getToken()
    }
    const audioUrl = await aiTextToSpeech(scriptData.voiceOver, aiVoiceID, domainId, '', true, token)
    const duration = await getAudioDurationInSeconds(audioUrl) // Assuming `getAudioDurationInSeconds` is a function you've defined to get the audio duration

    currentProgress += progressIncrement
    setProgressBarPercent(Math.round(currentProgress))

    let newDuration =
      duration * 1000 > store.pages[index].duration ? duration * 1000 + 500 : store.pages[index].duration + 500

    if (index === pagesLength - 1) {
      newDuration = newDuration + 1000
    }

    let captionsResult: any
    try {
      captionsResult = await generateCaptions(audioUrl)
    } catch (error) {
      console.error('Caption generation failed, proceeding without captions.', error)
    }

    currentProgress += progressIncrement
    setProgressBarPercent(Math.round(currentProgress))

    await setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript }

      const updatedData = [...prevScript.data]

      updatedData[index] = {
        ...updatedData[index],
        name: aiVoiceName,
        elevenLabsAiVoiceId: aiVoiceID,
        url: audioUrl,
        isElevenApi: true,
        captions: captionsResult ? [{ words: captionsResult.data.words, text: scriptData.voiceOver }] : [],
        aiVoiceVolume: 1,
        sounds: [{ url: audioUrl, audioDuration: duration }],
      }

      return { ...updatedScript, data: updatedData }
    })

    if (captionsResult) {
      store.pages[index].set({
        duration: style === 'Burst' ? store.pages[index].duration : newDuration,
        custom: {
          ...store.pages[index].custom,
          aiVoiceVolume: 1,
          sounds: [{ url: audioUrl, audioDuration: duration }],
          captions: [{ text: scriptData.voiceOver, words: captionsResult.data.words }],
        },
      })
    } else {
      store.pages[index].set({
        duration: style === 'Burst' ? store.pages[index].duration : newDuration,
        aiVoiceVolume: 1,
        custom: {
          ...store.pages[index].custom,
          sounds: [{ url: audioUrl, audioDuration: duration }],
        },
      })
    }
    return newDuration
  } catch (error) {
    console.error('Error making text-to-speech request:', error)
  }
}

export async function uploadAudioBlob(blob: Blob): Promise<any> {
  const type = 'audio'
  const file = new File([blob], getDomainID() + generateUniqueId() + '.mp3')
  const formData = new FormData()
  formData.append('file', file)
  formData.append('asset_type', type)

  try {
    // setUploading(true)
    const response = await qaBackendApiInstance.post(
      `/asset/upload?asset_type=${type}&domain_id=${getDomainID()}`,
      formData
    )
    if (response.status === 200) {
      console.log('Audio uploaded successfully')
      return response.data
    } else {
      message.error('Audio Upload failed with status: ' + response.status)
    }
  } catch (error) {
    console.error('Error uploading file:', error)
    message.error('Upload failed.')
  }
}

export async function generateCaptions(audioUrl: any): Promise<any> {
  try {
    const localEndPoint = axios.create({
      baseURL: `${NODE_BACKEND_URL}`,
    })

    const formData = {
      audioFileUrl: audioUrl,
    }
    const transcribeResponse = await localEndPoint.post('/api/v1/whisper/transcribe', formData)
    return transcribeResponse
  } catch (error) {
    console.error('Error transcribing audio:', error)
  }
}

export async function aiTextToSpeech(
  text: string,
  aiVoiceID: string | undefined,
  domainId: string | undefined,
  aiVoiceName: string | undefined,
  isElevenLabs: boolean,
  token: string
) {
  try {
    const localEndPoint = axios.create({
      baseURL: `${NODE_BACKEND_URL}`,
    })

    const aiVoiceGenerationBody = {
      text: text,
      aiVoiceId: aiVoiceID,
      domainId: domainId,
      aiVoiceName: aiVoiceName,
      isElevenLabs: isElevenLabs,
      token: token,
      isFree: IS_FREE_PLATFORM,
    }

    const aiTextToSpeechResponse = await localEndPoint.post('/api/v1/ai/audio', aiVoiceGenerationBody)

    return aiTextToSpeechResponse.data
  } catch (error) {
    console.error('Error getting eleven labs audio:', error)
  }
}

export async function generateRegeneratedScripAiAudio(
  regeneratedScriptData: any,
  store: any,
  index: any,
  setScriptDetails: any,
  setProgressBarPercent: any,
  currentProgress: number,
  progressIncrement: number
) {
  try {
    let domainId
    let token

    if (IS_FREE_PLATFORM === 'false') {
      domainId = getDomainID()
      token = getToken()
    }

    let audioUrl: any

    if (regeneratedScriptData.isElevenApi === true) {
      audioUrl = await aiTextToSpeech(
        regeneratedScriptData.voiceOver,
        regeneratedScriptData.elevenLabsAiVoiceId,
        domainId,
        '',
        true,
        token
      )
    } else {
      audioUrl = await aiTextToSpeech(
        regeneratedScriptData.voiceOver,
        '',
        domainId,
        regeneratedScriptData.name,
        false,
        token
      )
      // aiVoiceName = regeneratedScriptData.name
    }

    currentProgress += progressIncrement
    setProgressBarPercent(Math.round(currentProgress))

    const duration = await getAudioDurationInSeconds(audioUrl)
    let newDuration =
      duration * 1000 > store.pages[index].duration ? duration * 1000 + 500 : store.pages[index].duration + 500
    if (index === store.pages.length - 1) {
      newDuration = newDuration + 1000
    }

    let captionsResult: any
    try {
      captionsResult = await generateCaptions(audioUrl)
    } catch (error) {
      console.error('Caption generation failed, proceeding without captions.', error)
    }

    currentProgress += progressIncrement
    setProgressBarPercent(Math.round(currentProgress))

    await setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript }

      const updatedData = [...prevScript.data]

      updatedData[index] = {
        ...updatedData[index],
        name: regeneratedScriptData.name,
        elevenLabsAiVoiceId: regeneratedScriptData.elevenLabsAiVoiceId,
        url: audioUrl,
        isElevenApi: regeneratedScriptData.isElevenApi,
        sounds: [{ url: audioUrl, audioDuration: duration }],
        captions: captionsResult ? [{ text: regeneratedScriptData.voiceOver, words: captionsResult?.data?.words }] : [],
        aiVoiceVolume: 1,
      }

      delete updatedData[index].isUpdated

      return { ...updatedScript, data: updatedData }
    })

    if (captionsResult) {
      store.pages[index].set({
        duration: newDuration,
        custom: {
          ...store.pages[index].custom,
          sounds: [{ url: audioUrl, audioDuration: duration }],
          captions: [{ text: regeneratedScriptData.voiceOver, words: captionsResult.data.words }],
        },
      })
    } else {
      store.pages[index].set({
        duration: newDuration,
        custom: {
          ...store.pages[index].custom,
          sounds: [{ url: audioUrl, audioDuration: duration }],
        },
      })
    }
  } catch (error) {
    console.error('Error making text-to-speech request:', error)
  }
}
