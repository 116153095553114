import '@blueprintjs/core/lib/css/blueprint.css'
import { message } from 'antd'
import axios, { AxiosError } from 'axios'
import { getCookie } from 'cookies-next'
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno'
import { Workspace } from 'polotno/canvas/workspace'
import {
  unstable_setAnimationsEnabled,
  unstable_setTextVerticalResizeEnabled,
  unstable_useHtmlTextRender,
} from 'polotno/config'
import createStore from 'polotno/model/store'
import { DEFAULT_SECTIONS, SidePanel } from 'polotno/side-panel'
import { Toolbar } from 'polotno/toolbar/toolbar'
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons'
import { useEffect, useState } from 'react'
import './App.less'
import { useGetProject } from './api/useGetProject'
import { IS_FREE_PLATFORM, POLOTNO_KEY, QUICKADS_REDIRECT_URL, REDIRECT_ON } from './config'
import { getDomainID, qaBackendApiInstance } from './config/config'
import { useGlobalContext } from './context/globalContext'
import { CustomActionControlContainer } from './features/polotno/component/customActionControlContainer'
import { CustomAssetsSection } from './features/polotno/component/customAssetsSidePanel'
import { CustomVideoSearch } from './features/polotno/component/customVideoSearchPanel'
import { ResizeDropdown } from './features/resizeDropdown/resizeDropdown'
import { RightSidebar } from './features/rightSidebar/rightSidebar'
import { CustomVideoTimelineSection } from './features/video/customVideoTimeline/videoTimeline'
import { Loading } from './shared/components/loading'
import { PageLayout } from './shared/components/pageLayout'
import { ScriptToCanvasBurst } from './shared/utils/VideoGeneration/BurstScriptToCanvas'
import { ScriptToCanvas } from './shared/utils/VideoGeneration/ScriptToCanvas'
import { resizeDesign } from './shared/utils/core'
import { trackEvent } from '@src/lib/mixpanel'
import { isBrowser, isMobile } from 'react-device-detect';
import IsMobileModal from './shared/components/isMobileModal'
unstable_setAnimationsEnabled(true)

unstable_setTextVerticalResizeEnabled(true)

unstable_useHtmlTextRender(true)

const store = createStore({
  key: `${POLOTNO_KEY}`,
  showCredit: false,
})

store.addPage()
store.setScale(0.5)
store.selectPage(store.pages[0].id)

// Remove sections that are not needed
const sectionsToRemove = ['text', 'templates', 'size', 'elements', 'background']
const sections = [CustomVideoTimelineSection as any, CustomVideoSearch as any]
if (IS_FREE_PLATFORM === 'false') {
  sectionsToRemove.push('upload')
  sections.push(CustomAssetsSection as any)
}
sections.push(...DEFAULT_SECTIONS.filter((section) => !sectionsToRemove.includes(section.name)))

const handlePutImage = async (projectID: any) => {
  const blob = (await store.toBlob({ mimeType: 'image/jpeg' })) as any
  const file = new File([blob], 'preview.jpg')
  const formData = new FormData()
  formData.append('file', file)
  const imageUpload = await qaBackendApiInstance.post(`/smp/save-ad-image/${projectID}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

const ScriptToCanvasFunc = async (
  setPageLoading: any,
  scriptDetails: any,
  firstRender: boolean,
  industry: string,
  setBackgroundMusic: any,
  setScriptDetails: any,
  setProgressBarPercent: any,
  icon: string,
  selectedSize: string,
  videoStyle: string
) => {
  if (firstRender === true) {
    if (videoStyle === 'Simple') {
      await ScriptToCanvas(
        store,
        scriptDetails,
        industry,
        setBackgroundMusic,
        setScriptDetails,
        setProgressBarPercent,
        icon,
        selectedSize
      )
    } else {
      await ScriptToCanvasBurst(
        store,
        scriptDetails,
        industry,
        setBackgroundMusic,
        setScriptDetails,
        setProgressBarPercent,
        icon,
        selectedSize,
        videoStyle
      )
    }

    setPageLoading(false)
  }
}

const LoadInitialJson = async (store: any, json: any) => {
  if (json) {
    await store.loadJSON(json, true)
  }
}

type DecodedData = {
  token: string
  email: string
  name: string
  status: string
  createdAt: string
  updatedAt: string
}

export const decodeAndParseCookie = (cookieValue: any): DecodedData => {
  const decodedString = decodeURIComponent(cookieValue)
  const data: DecodedData = JSON.parse(decodedString)
  return data
}

export const App = () => {

  const projectID = location.pathname.split('/').pop()
  const [pageLoading, setPageLoading] = useState(true)
  const [firstRender, setFirstRender] = useState(false)
  const [open, setOpen] = useState(false)
  const [previewOpen, setPreviewOpen] = useState(true)
  const [bulkCreatePropsArray, setBulkCreatePropsArray] = useState<any[]>([])
  const [progressBarPercent, setProgressBarPercent] = useState<number>(0)

  const {
    UserDetail: [userDetails, setUserDetails],
    ScriptDetails: [scriptDetails, setScriptDetails],
    BackgroundMusic: [backgroundMusic, setBackgroundMusic],
    IsLoading: [isLoading, setIsLoading],
    RegeneratedScriptDetails: [regeneratedScriptDetails, setRegeneratedScriptDetails],
    VideoStyle: [, setVideoStyle],
  } = useGlobalContext()

  const User = JSON.parse(localStorage.getItem('userData') as string)

  const { data: projectData, error: projectError } = useGetProject(projectID)

  async function resizeOnFirstLoad(projectDataProp: any, userDetails: any, sizeString?: string) {
    const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))
    await resizeDesign(oldJSON, store, userDetails, setUserDetails, true, projectDataProp, sizeString)
  }

  useEffect(() => {
    if (IS_FREE_PLATFORM === 'false') {
      const token = getCookie('crossToken')
      if (token) {
        const data = decodeAndParseCookie(token)
        localStorage.setItem('userData', JSON.stringify(data))
      }
    } else {
      const script = getCookie('scriptData')
      const size = getCookie('projectSize')
      const industry = getCookie('projectIndustry')
      const style = getCookie('projectStyle')
      if (script) {
        const data = decodeAndParseCookie(script)
        const scriptData = { data: JSON.parse(script) }
        localStorage.setItem('scriptData', JSON.stringify(data))
        setScriptDetails(scriptData)
        setRegeneratedScriptDetails(scriptData)
        setVideoStyle(style ? style.toString() : 'Simple')
        ScriptToCanvasFunc(
          setPageLoading,
          scriptData,
          true,
          industry ? industry.toString() : 'Fashion',
          setBackgroundMusic,
          setScriptDetails,
          setProgressBarPercent,
          '',
          size ? size.toString() : '9:16',
          style ? style.toString() : 'Simple'
        ).then(() => {
          resizeOnFirstLoad(projectData, userDetails, size ? size.toString() : '9:16').then(() => {
            setIsLoading(false)
          })
        })
      }
    }
  }, [])

  useEffect(() => {
    if (IS_FREE_PLATFORM === 'false') {
      if (projectError && axios.isAxiosError(projectError)) {
        const axiosError = projectError as AxiosError
        console.log(axiosError)
        message.error('Error fetching project data: ' + axiosError.message)

        const statusCode = axiosError.response ? axiosError.response.status : null

        if (statusCode === 400) {
          // document.cookie = 'crossToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
          // localStorage.removeItem('accessToken')
          // window.location.href = QUICKADS_REDIRECT_URL
        }

        if (statusCode === 403) {
          document.cookie = 'crossToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
          localStorage.removeItem('accessToken')
          if (!REDIRECT_ON || IS_FREE_PLATFORM === 'false') window.location.href = QUICKADS_REDIRECT_URL
        }

        if (statusCode === 406) {
          document.cookie = 'crossToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
          localStorage.removeItem('accessToken')
          if (!REDIRECT_ON || IS_FREE_PLATFORM === 'false') window.location.href = QUICKADS_REDIRECT_URL
        }
      }
    }
  }, [projectError])

  useEffect(() => {
    if (projectData && IS_FREE_PLATFORM === 'false') {
      const jsonDataRes = projectData?.json_body
      LoadInitialJson(store, jsonDataRes)
      setUserDetails(projectData)
      setScriptDetails(projectData?.script)
      setRegeneratedScriptDetails(projectData?.script?.data)
      setFirstRender(projectData?.first_render)
      setVideoStyle(projectData?.purpose.style || 'Simple')
      if (projectData?.first_render) {
        ScriptToCanvasFunc(
          setPageLoading,
          projectData?.script,
          projectData?.first_render,
          projectData?.purpose?.industry,
          setBackgroundMusic,
          setScriptDetails,
          setProgressBarPercent,
          projectData?.logo,
          projectData?.purpose?.size,
          projectData?.purpose?.style ? projectData?.purpose?.style : 'Simple'
        ).then(() => {
          resizeOnFirstLoad(projectData, userDetails, projectData?.purpose?.size).then(() => {
            setIsLoading(false)
          })
        })
      } else {
        setIsLoading(false)
      }
    }
  }, [projectData])

  // Call handlePutImage once 10 seconds after the page loads
  useEffect(() => {
    if (IS_FREE_PLATFORM === 'false') {
      const timer = setTimeout(() => {
        handlePutImage(projectID)
      }, 10000)
      return () => clearTimeout(timer)
    }
  }, [isLoading])

  useEffect(() => {
    const timer = setTimeout(() => {
      trackEvent('Free Video Ad Generated')
    }, 5000)
    return () => clearTimeout(timer)
  }, [])

  async function updateProject(): Promise<any> {
    try {
      const payload = userDetails
      payload.json_body = store.toJSON()

      payload.script = scriptDetails
      payload.first_render = false
      const domainID = getDomainID() || ''
      const response = await qaBackendApiInstance.put(
        `/smp/${userDetails.id}?domain_id=${encodeURIComponent(domainID)}`,
        { ...payload }
      )
    } catch (error) {
      console.error('Error updating project:', error)
      // message.error('Project could not be updated.')
    }
  }

  // auto update project and handlePutImage every 20 seconds
  useEffect(() => {
    if (IS_FREE_PLATFORM === 'false') {
      const interval = setInterval(() => {
        handlePutImage(projectID)
        updateProject()
      }, 100000)
      return () => clearInterval(interval)
    }
  }, [])

  return (
    <>
  {isMobile && (
    <IsMobileModal/>
  )}
      {isLoading && <Loading percentage={progressBarPercent}/>}
      <PageLayout heading={'Social Media Post Generator'}>
        <div>
          <PolotnoContainer style={{ width: '100%', height: '93vh' }}>
            <SidePanelWrap>
              <SidePanel store={store} sections={sections} defaultSection='video-timeline' />
            </SidePanelWrap>
            <WorkspaceWrap>
              <Toolbar
                store={store}
                components={{
                  ActionControls: () => (
                    <>
                      {/* <PreviewButton store={store} /> */}
                      {/* <SaveTemplateModal store={store} /> */}
                      <CustomActionControlContainer
                        store={store}
                        open={open}
                        setOpen={setOpen}
                        bulkCreatePropsArray={bulkCreatePropsArray}
                        setBulkCreatePropsArray={setBulkCreatePropsArray}
                        previewOpen={previewOpen}
                        setPreviewOpen={setPreviewOpen}
                        setProgressBarPercentage={setProgressBarPercent}
                      />
                    </>
                  ),
                }}
              />
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  width: '100%',
                  flexDirection: 'row',
                  background: 'transparent',
                }}
              >
                <div style={{ display: 'flex', width: '94%', flexDirection: 'column', background: 'white' }}>
                  <ResizeDropdown store={store} />
                  <Workspace
                    store={store}
                    pageBorderColor='white'
                    activePageBorderColor='#7735BD'
                    backgroundColor='white'
                  />
                  <ZoomButtons store={store} />
                </div>
                <div className='right-side-bar'>
                  <RightSidebar store={store} />
                </div>
              </div>
            </WorkspaceWrap>
          </PolotnoContainer>
        </div>
      </PageLayout>
    </>
  )
}
