import React from 'react'
import { Card, Col } from 'antd'
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons'
import { JsonData } from '../videoTimelineCard'
import { useGlobalContext } from '@src/context/globalContext'

interface PageOperationsRowProps {
  store: any
  setPages: any
  index: any
}

export const PageOperationsRow = ({ store, setPages, index }: PageOperationsRowProps) => {
  const {
    ScriptDetails: [scriptDetails, setScriptDetails],
  } = useGlobalContext()

  const handleDeletePage = () => {
    store.selectPage(store.pages[index].id)
    store.deletePages([store.activePage.id])
    const jsonData: JsonData = store.toJSON()
    setPages(jsonData.pages)

    if (scriptDetails && scriptDetails.data && scriptDetails.data.length > index) {
      const updatedScriptDetails = { ...scriptDetails }
      updatedScriptDetails.data.splice(index, 1)

      setScriptDetails(updatedScriptDetails)
    }
  }

  const handleCopyPage = () => {
    store.selectPage(store.pages[index].id)
    store.activePage?.clone()
    const jsonData: JsonData = store.toJSON()
    setPages(jsonData.pages)

    const newScriptDetails = { ...scriptDetails }
    const clonedData = { ...newScriptDetails.data[index] }
    newScriptDetails.data.splice(index + 1, 0, clonedData)

    setScriptDetails(newScriptDetails)
  }

  return (
    <Col
      span={24}
      style={{
        display: 'flex',
        justifyContent: 'right',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '4px 10px',
          background: '#7F70CF',
          borderRadius: '20px',
        }}
      >
        <CopyOutlined
          onClick={handleCopyPage}
          style={{ color: 'white', fontSize: '17px' }}
          className='icon-hover-effect'
        />
        <DeleteOutlined
          onClick={handleDeletePage}
          style={{ color: 'white', fontSize: '17px', marginLeft: '12px' }}
          className='icon-hover-effect'
        />
      </div>
    </Col>
  )
}
