import { TransitionSeries, springTiming } from '@remotion/transitions'
import React from 'react'
import { Audio, Sequence, interpolate } from 'remotion'
import { burstImageTransitionFunctions, transitionAnimationsDropDown } from '../../../data/transitions'
import { generateUniqueId } from '../../../shared/utils/core'
import './fonts.css'
import { SinglePage } from './singlePage'
import { AnimatedSubtitles } from './subtitles'
import { fade } from '@remotion/transitions/fade'

interface HtmlProp {
  htmlData: string
  jsonData: any
  scriptDetails: any
  width: number
  height: number
  transitionDurationInFrames: number
  transitionAnimationProp: any
  isDownload: boolean
  burstImagesTransitionAnimationArray: any
  videoStyle: string
}

const fps = 30
const defaultDurationInSeconds = 5
const delayInSeconds = 1

export const MyCompositionHtmlComponent: React.FC<HtmlProp> = ({
  htmlData,
  jsonData,
  scriptDetails,
  width,
  height,
  transitionDurationInFrames,
  transitionAnimationProp,
  isDownload,
  burstImagesTransitionAnimationArray,
  videoStyle,
}) => {
  const transitionFunction: any =
    transitionAnimationsDropDown.find((item) => item.value === transitionAnimationProp)?.functions ||
    transitionAnimationsDropDown[0].functions

  const captionsTextStyle = scriptDetails.template
  const showSubtitles = scriptDetails.showSubtitles

  const backgroundMusicUrl = scriptDetails?.backgroundMusic.url
  const backgroundMusicVolume = scriptDetails?.backgroundMusic.backgroundVolume
  const voiceOverVolume = scriptDetails?.aiVoiceVolume ? scriptDetails?.aiVoiceVolume : 0.7
  const lastScreen = scriptDetails?.data.length - 1

  let accumulatedFrames = 0

  let pageIndex = 0

  return (
    <>
      <TransitionSeries>
        {scriptDetails.data.map((page: any, index: number) => {
          const jsonPage = jsonData.pages[index]
          const pageHtml = htmlData[index]

          const jsonPages: any = []
          const htmlPages: any = []
          let durationInFrames: any = 0
          let accumulatedPageFrames = 0

          if (videoStyle === 'Burst') {
            for (let i = pageIndex; i < pageIndex + page.images.length; i++) {
              jsonPages.push(jsonData.pages[i])
              htmlPages.push(htmlData[i])
              durationInFrames += (jsonData.pages[i].duration / 1000) * fps
            }
            pageIndex += page.images.length
          } else if (videoStyle === 'Simple') {
            jsonPages.push(jsonData.pages[pageIndex])
            htmlPages.push(htmlData[pageIndex])
            durationInFrames += (jsonData.pages[pageIndex].duration / 1000) * fps
            pageIndex++
          }

          const sequenceKey = generateUniqueId()
          const transitionKey = generateUniqueId()
          const captions = page?.captions ? page?.captions[0]?.words : undefined
          const voiceOver = page?.sounds ? page?.sounds[0]?.url : undefined
          const voiceOverDuration = page?.sounds ? page?.sounds[0]?.audioDuration : undefined

          const audioDelay = fps * delayInSeconds

          const voiceOverDurationFrame = Math.round((voiceOverDuration + delayInSeconds) * fps)

          // Page start and end frames
          const pageStartFrame = accumulatedFrames
          const pageEndFrame = accumulatedFrames + durationInFrames + transitionDurationInFrames / 10

          accumulatedFrames += durationInFrames

          let volume = backgroundMusicVolume ? backgroundMusicVolume : 0.5

          const transitionBusrtAnimations = burstImagesTransitionAnimationArray[index]

          return (
            <>
              <TransitionSeries.Sequence
                key={`sequence-${index}-${sequenceKey}`}
                durationInFrames={durationInFrames + transitionDurationInFrames}
              >
                <Audio
                  src={backgroundMusicUrl}
                  startFrom={pageStartFrame}
                  endAt={pageEndFrame}
                  volume={(f) => {
                    if (f <= audioDelay) {
                      volume = interpolate(f, [0, audioDelay], [backgroundMusicVolume, 0.1], {
                        extrapolateLeft: 'clamp',
                        extrapolateRight: 'clamp',
                      })
                    } else if (f >= voiceOverDurationFrame) {
                      if (index === lastScreen) {
                        volume = interpolate(
                          f,
                          [voiceOverDurationFrame, durationInFrames + transitionDurationInFrames],
                          [volume, 0.05],
                          {
                            extrapolateLeft: 'clamp',
                            extrapolateRight: 'clamp',
                          }
                        )
                      } else {
                        volume = interpolate(
                          f,
                          [voiceOverDurationFrame, durationInFrames + transitionDurationInFrames],
                          [volume, backgroundMusicVolume],
                          {
                            extrapolateLeft: 'clamp',
                            extrapolateRight: 'clamp',
                          }
                        )
                      }
                    } else if (f >= audioDelay && f <= voiceOverDurationFrame) {
                      volume = 0.1
                    }
                    return volume
                  }}
                />

                {videoStyle === 'Burst' ? (
                  <TransitionSeries>
                    {jsonPages.map((jsonPage: any, index: number) => {
                      const transitionAnimationFunction: any =
                        burstImageTransitionFunctions.find((item) => item.value === transitionBusrtAnimations[index])
                          ?.function || burstImageTransitionFunctions[0].function

                      const pageFrames = (jsonPage.duration / 1000) * fps
                      accumulatedPageFrames += pageFrames
                      return (
                        <>
                          <TransitionSeries.Sequence
                            key={`transitionSeriesSequence-${index}`}
                            durationInFrames={pageFrames + transitionDurationInFrames}
                          >
                            <SinglePage
                              key={index}
                              pageHtml={htmlPages[index]}
                              width={width}
                              height={height}
                              pageData={jsonPage}
                              isDownload={isDownload}
                            />
                          </TransitionSeries.Sequence>
                          <TransitionSeries.Transition
                            key={`transitionSeriesTransition-${index}`}
                            presentation={transitionAnimationFunction}
                            timing={springTiming({
                              config: { damping: 200 },
                              durationInFrames: transitionDurationInFrames,
                              durationRestThreshold: 0.001,
                            })}
                          />
                        </>
                      )
                    })}
                  </TransitionSeries>
                ) : (
                  <SinglePage
                    pageHtml={pageHtml}
                    width={width}
                    height={height}
                    pageData={jsonPage}
                    isDownload={isDownload}
                  />
                )}

                <Sequence from={audioDelay} durationInFrames={durationInFrames + transitionDurationInFrames}>
                  {voiceOver && <Audio src={voiceOver} startFrom={0} volume={voiceOverVolume} />}
                  {captions && captionsTextStyle && showSubtitles && (
                    <AnimatedSubtitles
                      captions={captions}
                      width={width}
                      height={height}
                      isDownload={isDownload}
                      style={captionsTextStyle}
                    />
                  )}
                </Sequence>
              </TransitionSeries.Sequence>
              {index < jsonData.pages.length - 1 && (
                <TransitionSeries.Transition
                  key={`sequence-${index}-${transitionKey}`}
                  presentation={transitionFunction}
                  timing={springTiming({
                    config: { damping: 200 },
                    durationInFrames: transitionDurationInFrames,
                    durationRestThreshold: 0.001,
                  })}
                />
              )}
            </>
          )
        })}
      </TransitionSeries>
    </>
  )
}

export const MyCompositionHtml = React.memo(MyCompositionHtmlComponent)
