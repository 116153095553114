/* eslint-disable react/prop-types */
import { Button, Modal } from 'antd'
import { useRef, useState } from 'react'
import { useGlobalContext } from '@src/context/globalContext'
import { resizeDesign } from '@src/shared/utils/core'
import { RegneratedScriptContainer } from './regeneratedScriptContainer'
import { ScriptToCanvas } from '@src/shared/utils/VideoGeneration/ScriptToCanvas'
import { IS_FREE_PLATFORM } from '@src/config'
import { getCookie } from 'cookies-next'
import { decodeAndParseCookie } from '@src/App'
import { ScriptToCanvasBurst } from '@src/shared/utils/VideoGeneration/BurstScriptToCanvas'

interface RegeneratedScriptContainerProps {
  store: any
  regeneratedScript: any
  setProgressBarPercentage: any
  openNewScriptModal: any
  setOpenNewScriptModal: any
}

export const RegeneratedScriptModal: React.FC<RegeneratedScriptContainerProps> = ({
  store,
  regeneratedScript,
  setProgressBarPercentage,
  openNewScriptModal,
  setOpenNewScriptModal,
}) => {
  const {
    UserDetail: [userDetails, setUserDetails],
    ScriptDetails: [, setScriptDetails],
    BackgroundMusic: [, setBackgroundMusic],
    IsLoading: [, setIsLoading],
    RegeneratedScriptDetails: [, setRegeneratedScriptDetails],
    VideoStyle: [videoStyle, setVideoStyle],
  } = useGlobalContext()

  const handleOk = async () => {
    setIsLoading(true)
    setScriptDetails(regeneratedScript)
    if (IS_FREE_PLATFORM === 'true') {
      const script = getCookie('scriptData')
      const size = getCookie('projectSize')
      const industry = getCookie('projectIndustry')

      const data = decodeAndParseCookie(script)

      localStorage.setItem('scriptData', JSON.stringify(data))

      if (videoStyle === 'Simple') {
        await ScriptToCanvas(
          store,
          regeneratedScript,
          industry ? industry.toString() : 'Fashion',
          setBackgroundMusic,
          setScriptDetails,
          setProgressBarPercentage,
          '',
          userDetails.purpose.size
        ).then(() => {
          const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))

          resizeDesign(oldJSON, store, userDetails, setUserDetails, false, undefined, size ? size.toString() : '9:16')
        })
        setIsLoading(false)
      }
      if (videoStyle === 'Burst') {
        await ScriptToCanvasBurst(
          store,
          regeneratedScript,
          industry ? industry.toString() : 'Fashion',
          setBackgroundMusic,
          setScriptDetails,
          setProgressBarPercentage,
          '',
          userDetails.purpose.size,
          videoStyle
        ).then(() => {
          const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))

          resizeDesign(oldJSON, store, userDetails, setUserDetails, false, undefined, size ? size.toString() : '9:16')
        })
        setIsLoading(false)
      }
    } else {
      // have to handle burstImage
      await ScriptToCanvas(
        store,
        regeneratedScript,
        userDetails.purpose.industry,
        setBackgroundMusic,
        setScriptDetails,
        setProgressBarPercentage,
        userDetails.logo,
        userDetails.purpose.size
      ).then(() => {
        const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))
        resizeDesign(oldJSON, store, userDetails, setUserDetails, false, undefined, userDetails.purpose.size)
      })

      setIsLoading(false)
    }
  }

  const handleCancel = () => {
    setOpenNewScriptModal(false)
  }

  const [width, setWidth] = useState<number>(500)

  const modalTitle = (
    <div
      style={{ width: '100%' }}
      className='draggable-modal-title' // This class will be used as a handle
    >
      New Script
    </div>
  )

  return (
    <>
      <Modal
        title={modalTitle}
        open={openNewScriptModal}
        onOk={handleOk}
        okText={'Regenerate Video'}
        onCancel={handleCancel}
        width={width} // Set the width of the Modal to match the GifPlayer's width
        style={{
          top: '50%',
          transform: 'translateY(-50%)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        destroyOnClose={true}
      >
        <RegneratedScriptContainer store={store} regeneratedScript={regeneratedScript} />
      </Modal>
    </>
  )
}
